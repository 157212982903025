import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginScreen from './screens/LoginScreen'
import ProductScreen from './screens/ProductScreen'
import DashboardScreen from './screens/DashboardScreen'
import WishlistScreen from './screens/WishlistScreen'
import HomeScreen from './screens/HomeScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ReturnPolicyScreen from './screens/ReturnPolicyScreen'
import TermAndConditionsScreen from './screens/TermAndConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import OrderCompletedScreen from './screens/OrderCompletedScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import CartScreen from './screens/CartScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import ContactUs from './screens/ContactUs'
import ProductSearchScreen from './screens/ProductSearchScreen'
import { onError } from "@apollo/client/link/error";
import OrderScreen from './screens/OrderScreen'
import OrderPlacedScreen from './screens/OrderPlacedScreen'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    HttpLink,
    from,
  } from "@apollo/client";
import OfflineOrder from './screens/OfflineOrder'
import OrderDetailScreen from './screens/OrderDetailScreen'
import OrdersScreen from './screens/OrdersScreen'
import AddressScreen from './screens/AddressScreen'
import AccountDetail from './screens/AccountDetail'
import ChangePasswordScreen from './screens/ChangePassowrdScreen'
import SetPasswordScreen from './screens/SetPasswordScreen'
// import Quickview from './screens/HomeQuickview'
import { Helmet } from 'react-helmet'
import 'react-lazy-load-image-component/src/effects/blur.css';
import BrandSearchScreen from "./screens/BrandSearchScreen"
import ShippingDeliveryPolicy from './screens/ShippingDeliveryPolicy'


const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: "https://www.hegmakart.com/graphql/" }),
]);
//https://www.hegmakart.com/graphql/  https://beta.hegmakart.com/graphql/
// http://52.66.147.119:8000/graphql/
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

const App = () => {
  return (
    <>
<Helmet>
<title>Hegmakart</title>
<meta name="keywords" content="Hegmakart"/>
<meta name="description" content="Hegmakart"/>
</Helmet>

<BrowserRouter>
<ApolloProvider client={client}>
    <Routes>
    <Route path='/change-password' element={<ChangePasswordScreen/>} />
    <Route path='/set-password' element={<SetPasswordScreen/>} />
    <Route path='/account-detail' element={<AccountDetail/>} />
    <Route path='/address' element={<AddressScreen/>} />
    <Route path='/order' element={<OrdersScreen/>} />
          <Route path='/orderDetails/:id' element={<OrderDetailScreen/>} />
       
          <Route path='/orderPlace' element={<OrderCompletedScreen/>} />
          <Route path='/:slug' element={<HomeScreen/>} />
          <Route path='/' element={<HomeScreen/>} />
          <Route path='/category' element={<ProductSearchScreen/>} />
          <Route path='/category/:id' element={<ProductSearchScreen/>} />
          <Route path='/category/:id/:id' element={<ProductSearchScreen/>} />
          <Route path='/login' element={<LoginScreen/>} />
          <Route path='/product/:slug' element={<ProductScreen/>} />
          <Route path='/Dashboard' element={<DashboardScreen/>} />
          <Route path='/wishlist' element={<WishlistScreen/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicyScreen/>} />
          <Route path='/forgotpassword' element={<ForgotPasswordScreen/>} />
          <Route path='/ReturnPolicy' element={<ReturnPolicyScreen/>} />
          <Route path='/TermAndConditions' element={<TermAndConditionsScreen/>} />
          <Route path="ShippingDeliveryPolicy" element={<ShippingDeliveryPolicy/>}/>
          <Route path='/Order-Completed' element={<OrderCompletedScreen/>} />
          <Route path='/about' element={<AboutUsScreen/>} />
          <Route path='/cart' element={<CartScreen/>} />
          <Route path='/offline-order' element={<OfflineOrder/>} />
          <Route path='/checkout' element={<CheckoutScreen/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/search/:psearchData' element={<ProductSearchScreen/>} />
          <Route path='/collection/:cosearchData' element={<ProductSearchScreen/>} />
          <Route path='/productType/:productType' element={<ProductSearchScreen/>} />
          <Route path='/brand/:attsearchData' element={<BrandSearchScreen/>} />
    </Routes>
    </ApolloProvider>
</BrowserRouter>
   
    </>
  )
}

export default App
