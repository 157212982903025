import React from 'react'

const LowerFooter = () => {
  const currentYear= new Date().getFullYear();

  return (
    <>
      <div class="footerbottomm">
					
					<div class="footertt">
					<div class="footerlogoo">
                            <img src={window.location.protocol + "//"+window.location.hostname + ":" + window.location.port + "/assets/images/apps.png"} alt="apps"  width="270px"/>
                        </div>
					<div class="footer-copyright">
                        
                            <p>© HegmaKart.{currentYear} All Rights Reserved</p>
                        </div>

                        <div class="payfooter">
                            <img src={window.location.protocol + "//"+window.location.hostname + ":" + window.location.port + "/assets/images/demoes/demo21/payment-icon.png"} alt="payment"/>
                        </div>
						
						</div>
                    </div>
    </>
  )
}

export default LowerFooter
