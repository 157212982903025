import React, { useState, useEffect } from 'react'
import "./Quickview.css"
import "./ProductScreen.css"
import { useParams, useNavigate } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PRODUCT_DETAILS } from '../GraphQL/Queries';
import { CREATE_CHECKOUT_MUTATION, CHECKOUT_ADD_PRODUCT_LINE_MUTATION, UPDATE_WISHLIST_MUTATION } from '../GraphQL/Mutation';
import { Link } from "react-router-dom";
import Ratings from './Ratings';
import { Helmet } from 'react-helmet';
import { totalValue } from './decimalFunction';

const HomeQuickview = ({ setQuickviewOpenModal }) => {
	const navigate = useNavigate()
	const [currentImage, setCurrentImage] = useState('assets/images/products/zoom/Woodpecker Dpex III Apex Locator 001.jpg')
	const [product, setProducts] = useState({})
	const [description, setDescription] = useState({})
	const [setattributes, setattributeList] = useState([]);
	const [variantId, setVariantId] = useState("");
	const [addwishlist, setAddWishlist] = useState([])
	let { slug } = useParams()
	const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
	const [updateWishlist] = useMutation(UPDATE_WISHLIST_MUTATION, {
		context: {
			headers: {
				Authorization: "JWT " + localStorage.getItem("token"),
			},
		},
	});
	const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
	const [loader, setLoader] = useState("add to cart")
	const [purchaseNote, setPurchaseNote] = useState('')
	const [wishHeart, setWishHeart] = useState()


	useEffect(() => {
		if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
		  setAddWishlist([])
		  let wishToken = localStorage.getItem("wishlistToken").replaceAll("[","").replaceAll("]","").replaceAll("'","").replaceAll(" ","")
		  for (let index = 0; index < wishToken.split(",").length; index++) {
			setAddWishlist((addwishlist) => [...addwishlist, wishToken.split(",")[index]])
		  }
		}
	  }, [])

	const setImage = (e) => {
		setCurrentImage(e.target.src)
	}

	const { loading, refetch } = useQuery(GET_PRODUCT_DETAILS, {
		fetchPolicy: "no-cache",
		variables: {
			slug: slug,
			channel: "default-channel"
		},
		onCompleted: data => {
			console.log("GET_PRODUCT_DETAILS",data)
			setProducts(data.product)
			setattributeList(data.product.attributes)
			setCurrentImage(data.product.media?.[0].url)
			setDescription(data.product.description)
			setVariantId(data.product.variants[0].id)
			setPurchaseNote(data.product.purchaseNote)
		}
	})

	const onAddToCart = async e => {
		setLoader(<div className='loaderr'></div>)



		e.preventDefault();
		if (localStorage.getItem("cartToken") === null) {
			CreateCheckout({
				variables: {
					channel: 'default-channel',
					email: '',
					lines: [
						{
							quantity: 1,
							variantId: variantId
						}
					]
				},
				onCompleted: data => {
					// console.log(data);
					if (data.checkoutCreate.checkout !== null) {
						localStorage.setItem('cartToken', data.checkoutCreate.checkout.token);
					}
					//   window.location.reload(false)
					setLoader('add to cart')
				}
			});
		} else {
			addLineCheckout({
				variables: {
					token: localStorage.getItem("cartToken"),
					lines: [
						{
							quantity: 1,
							variantId: variantId
						}
					]
				},
				onCompleted: data => {
					//  console.log(data);
					//   window.location.reload(false)
					refetch()
					setLoader('add to cart')
				}
			});
		}
	};

	const onAddToWishlist = async e => {
		e.preventDefault();
		const { id } = e.target;
		setWishHeart(id === variantId ? "added-wishlist" : "")
		if (!addwishlist.includes(id)) {
			setAddWishlist([...addwishlist, id])
			if(localStorage.getItem("wishlistToken")===null){
				localStorage.setItem("wishlistToken", JSON.stringify([id]));
			  }else{
				if(!localStorage.getItem("wishlistToken").includes(id)){
			  localStorage.setItem("wishlistToken",  JSON.stringify([
				...JSON.parse(localStorage.getItem("wishlistToken")),
				id,
			  ]));}}
			updateWishlist({
			  variables: {
				input: {
				  wishlistToken: [...addwishlist, id],
				},
			  },
			  onCompleted: (data) => {
				console.log(data);
			  },
			});
		  }
	};

	if (loading) {
		return <h1>please wait loadingg...</h1>
	}

	const obj = JSON.parse(description);
	return (
		<>
			<Helmet>
				<title>Hegmakart: {product.name}</title>
				<meta name="description" content={product.name} />
			</Helmet>
			<div className='modalBackground'>
				<div className='modalContainer'>
					<div className="titleCloseBtn">
						<Link to="/"> <button
							onClick={() => {
								setQuickviewOpenModal(false);
							}}
						>
							X
						</button>
						</Link>

					</div>
					<div class="col-lg-5 col-md-4 product-single-gallery">
						<div class="product-slider-container">
							{/* <div class="label-group">
									<div class="product-label label-hot">HOT</div>

									<div class="product-label label-sale">
										-16%
									</div>
								</div> */}

							<div class="">
								<div class="product-item">
									<img class="product-single-image"
										src={currentImage}
									/>
								</div>


							</div>
							{/* <!-- End .product-single-carousel --> */}
							<span class="prod-full-screen">
								<i class="icon-plus"></i>
							</span>
						</div>

						<div class="prod-thumbnail owl-dots">
							{product.media.map((media) => (
								<div class="owl-dot">
									<img onClick={setImage} src={media.url} width="110" height="110"
										alt="product-thumbnail" />
								</div>
							))}
						</div>
					</div>



					<div class="col-lg-7 col-md-4 product-single-details">

						<h1 class="product-title" style={{ fontSize: '20px' }}>{product.name} </h1>



						<div class="ratings-container">
							<div className="">
								<span className="" style={{ width: '100%' }}><Ratings value={product.rating} /></span>
								{/* <!-- End .ratings --> */}
								<span className="tooltiptext tooltip-top" >{product.id}</span>
							</div>
							{/* <!-- End .product-ratings --> */}

							{!(product.rating == null || product.rating == 0) ?<a className="rating-link">{product.rating.toString().length > 1?product.rating.toFixed(1):product.rating} </a>:null}
						</div>
						{/* <!-- End .ratings-container --> */}
						{console.log('setattributes',setattributes)}
						{setattributes.map((attributes) => (
							<>
								{attributes.attribute.name === "Manufacturer" ? (
									<ul class="single-info-list">
										<li>
											MANUFACTURER:{" "}
											<strong>{attributes.values.length > 0 ? attributes.values[0].name : null}</strong>
										</li>
									</ul>
								) : null}
							</>
						))}
						<hr class="short-divider" />

						{/* <div class="price-box">
	{product.pricing.priceRangeUndiscounted.start.net.amount !== product.pricing.priceRange.start.net.amount?
	<span class="old-price">₹{product.pricing.priceRangeUndiscounted.start.net.amount}</span>:null}
	<span class="new-price">₹{product.pricing.priceRange.start.net.amount}</span>
</div> */}
						{!product.productType.hasVariants ? (
							<div class="price-box">
								<>
									{product.variants[0].originalPrice
										!==
										product.pricing.priceRange.start.net.amount ? (
										<>

											<span class="old-price">
												₹
												{
													totalValue(product.variants[0].weightedShipping !== null || product.variants[0].weightedShipping !== 0 ? product.variants[0].originalPrice - product.variants[0].weightedShipping : product.variants[0].originalPrice)
												}
											</span>
											<span class="new-price">
												₹{totalValue(product.pricing.priceRange.start.net.amount - product.variants[0].weightedShipping)}
											</span>
											{!(product.variants[0].weightedShipping === null || product.variants[0].weightedShipping === '0.00') ?
												<span style={{ color: "orangered", marginLeft: 10 }}>+ ₹ {totalValue(parseInt(product.variants[0].weightedShipping))} Weighted Delivery Charges</span> : null}

										</>
									) : (
										<>
											<span class="new-price">
												₹{totalValue(product.pricing.priceRange.start.net.amount)}
											</span>
											{!(product.variants[0].weightedShipping === null || product.variants[0].weightedShipping === '0.00') ?
												<span style={{ color: "blue", marginLeft: 10 }}> + ₹ {totalValue(parseInt(product.variants[0].weightedShipping))} Weighted Delivery Charges</span> : null}
										</>

									)}
								</>
							</div>
						) : (
							<div class="price-box">
								<span style={{ color: "grey", fontWeight: 500, marginLeft: 0 }} class="new-price">
									Starts from: <span style={{ fontFamily: "sans-serif", color: "black" }}> ₹{totalValue(product.pricing.priceRange.start.net.amount)}</span>
								</span>
							</div>
						)}
						<p className='quickDescription'>{obj.blocks[0].data.text}</p>

						{/* <div class="cartcontainr">
								<a onClick={onAddToCart} class="btn btn-dark " title="Add to Cart">
									
									{loader}
									</a>
									
									</div> */}

						<div style={{
							display: 'flex',
							flexDirection: "column"
						}}>
							{!product.productType.hasVariants ?
								<><div style={{ paddingTop: 0, display: "flex" }} class="cartcontainr">
									<a onClick={onAddToCart} style={{ color: "white", minWidth: "130px" }} class="btn btn-dark save-change" title="Add to Cart">
										{loader}
									</a>
									{/* {wishHeart !== "added-wishlist" ?
											<a style={{ cursor: "pointer" }} onClick={onAddToWishlist} class="btn-icon-wish 
											 add-wishlist" title="Add to  Wishlist"><i class="icon-wishlist-2"></i><span 
											  id={product.variants[0].id}>Add to Wishlist</span>
											 </a>
											 :
											 <a style={{ cursor: "pointer" }} onClick={() => navigate("/wishlist")} 
											 class="btn-icon-wish add-wishlist" title="Add to 
											 Wishlist"><i class="icon-wishlist-2"></i><span id={product.variants[0].id}>Browse  wishlist</span>
											 </a>
											 } */}

									{wishHeart !== "added-wishlist" ?
										<a style={{ cursor: "pointer" }} id={product.id} onClick={onAddToWishlist} class="btn-icon-wish add-wishlist"
											title="Add to Wishlist"><i class="icon-wishlist-2"></i><span id={product.id}>
												add to wishlist</span>
										</a>
										:
										<a style={{ cursor: "pointer" }} onClick={() => navigate("/wishlist")} class="btn-icon-wish add-wishlist"
											title="Add to Wishlist"><i class="icon-wishlist-2"></i><span id={product.id}>
												Browse wishlist</span>
										</a>
									}
								</div>
								</>
								:
								<div style={{ paddingTop: 0, }} class="cartcontainr">
									<Link to={`/product/${encodeURIComponent(product.slug)}`} style={{ color: "white", }} class="btn   btn-dark " title="Add to Cart">
										View Product Details
									</Link></div>
							}

							<p style={{ color: "orangered", marginTop: 10 }}>{purchaseNote}</p>
						</div>
						<div class="product-single-share mb-3">


							{/* <div class="social-icons mr-2">
									<a href="#" class="social-icon social-facebook icon-facebook" target="_blank"
										title="Facebook"></a>
									<a href="#" class="social-icon social-twitter icon-twitter" target="_blank"
										title="Twitter"></a>
									<a href="#" class="social-icon social-linkedin fab fa-linkedin-in" target="_blank"
										title="Linkedin"></a>
									<a href="#" class="social-icon social-gplus fab fa-google-plus-g" target="_blank"
										title="Google +"></a>
									<a href="#" class="social-icon social-mail icon-mail-alt" target="_blank"
										title="Mail"></a>
								</div> */}
							{/* <!-- End .social-icons --> */}


						</div>

					</div>
				</div>
			</div>



		</>
	)
}

export default HomeQuickview
