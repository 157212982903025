import React from 'react'
import Header from '../components/Header/Header'
import { Link } from 'react-router-dom'

import Footer from '../components/Footer/Footer'
import { Helmet } from 'react-helmet'

const AboutUsScreen = () => {
  const pageHeaderStyle={
    backgroundSize:'50%/cover',
    backgroundImage:`url('assets/images/page-header-bg.jpg')`,
}
  return (
    <>
	<Helmet>
<title>Hegmakart: About Us</title>
<meta name="description" content="About Us"/>
</Helmet>
      <Header/>

      <main class="main about">
			<div class="page-header page-header-bg text-left"
				style={pageHeaderStyle}>
				<div class="container">
					<h1><span>ABOUT US</span>
						OUR COMPANY</h1>
					<a style={{color:"white"}} class="btn btn-dark">Contact</a>
				</div>
                {/* <!-- End .container --> */}
			</div>
            {/* <!-- End .page-header --> */}

			<nav aria-label="breadcrumb" class="breadcrumb-nav">
				<div class="container">
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><Link to="/" ><i class="icon-home"></i></Link></li>
						<li class="breadcrumb-item active" aria-current="page">About Us</li>
					</ol>
				</div>
                {/* <!-- End .container --> */}
			</nav>

			<div class="about-section">
				<div class="container">
					<h2 class="subtitle">OUR STORY</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
						the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
						of type and scrambled it to make a type specimen book. It has survived not only five centuries,
						but also the leap into electronic typesetting, remaining essentially unchanged.</p>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
						the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
						of type and scrambled it to make a type specimen book.</p>

					<p class="lead">“ Many desktop publishing packages and web page editors now use Lorem Ipsum as their
						default model search for evolved over sometimes by accident, sometimes on purpose ”</p>
				</div>
                {/* <!-- End .container --> */}
			</div>
            {/* <!-- End .about-section --> */}

			<div class="features-section bg-gray">
				
			</div>
            {/* <!-- End .features-section --> */}

			
			
		</main>
      <Footer/>
    </>
  )
}

export default AboutUsScreen
