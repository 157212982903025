import React, { useState,useEffect } from 'react'
import { totalValue } from './decimalFunction'
import { Link } from 'react-router-dom'
import { GET_CHECKOUT_BY_TOKEN } from '../GraphQL/Queries'
import {useMutation, useQuery } from '@apollo/client'
import { CHECKOUT_LINE_DELETE, CHECKOUT_ADD_PRODUCT_LINE_MUTATION, CHECKOUT_LINE_UPDATE } from '../GraphQL/Mutation'
import { Navigate } from 'react-router-dom'
import { MdRemoveShoppingCart } from 'react-icons/md';
import "./Wishlist.css"
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import OutOfStock from './OutOfStock'


const CartScreen = () => {

	const [cartProduct, setCartProduct]=useState([])
	const [cartData, setCartData]=useState([])
	const [savingAmount, setSavingAmount] = useState([]);
	const[checkoutLineDelete]= useMutation(CHECKOUT_LINE_DELETE)
	const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
	const [updateLineCheckout] = useMutation(CHECKOUT_LINE_UPDATE);
    const[refresh,setRefresh] = useState(1)
	const [getInputValue,setGetInputValue] = useState(1)
	const [previous,setPrevious] = useState(2)
	const [outOfStockName,setOutOfStockName] = useState([])
//console.log("getInputValue",getInputValue,"previous",previous)
useEffect(()=>{
		setRefresh(5)
		refetch()
		 },[refresh,setRefresh,])

	const removeProduct=(id)=>{
		// const { id } = e.target;
		checkoutLineDelete({
			variables: {
				token: localStorage.getItem('cartToken'),
				lineId: id
			},
			onCompleted: data=>{
				//console.log(data)	
				setRefresh(refresh +1)
				// window.location.reload(false)	
				// refetch()
			}
		})
		
	}

	const updateProduct=(e)=>{
		// setGetInputValue(getInputValue <2?1:getInputValue-1)
		if (e.target.parentElement.children[1].value > 1) {
			e.target.parentElement.children[1].value = parseInt(e.target.parentElement.children[1].value) - 1
		  }
		  else (
			e.target.parentElement.children[1].value = 1
		  )  
		  setGetInputValue(e.target.parentElement.children[1].value);
		const { id, name } = e.target;
		updateLineCheckout({
			variables: {
				token: localStorage.getItem('cartToken'),
				lines: [{
					quantity: name - 1,
					variantId: id
				}]
			},
			onCompleted: data=>{
				//console.log(data)
				setRefresh(refresh +2)	
				// refetch()
				//window.location.reload(false)
			}
		})
			
	}

	const addProduct=(e)=>{
		e.target.parentElement.children[1].value = parseInt(e.target.parentElement.children[1].value) + 1
		setGetInputValue(e.target.parentElement.children[1].value)
		const { id } = e.target;
		addLineCheckout({
			variables: {
				token: localStorage.getItem("cartToken"),
				lines: [
					{
					quantity: 1,
					variantId: id
					}
				]
			},
			onCompleted: data=>{
				//console.log(data)
				setRefresh(refresh +3)
				// refetch()
				//window.location.reload(false)	
			}
		})	
		
	}
	
	const { loading, error,refetch,data:cartItems }=useQuery(GET_CHECKOUT_BY_TOKEN,{
		context: {
			headers: {
			  Authorization:localStorage.getItem("user") !== null? "JWT " + localStorage.getItem("token"):"",
			},
		},
		variables: {
			token : localStorage.getItem('cartToken')  
		},
		onCompleted: data => {
			console.log("Data.checkout.totalPrice.gross.amount",data)
			setCartProduct(data.checkout.lines)
			setCartData(data.checkout)
			for (let index = 0; index < data.checkout.lines.length; index++) {
                setSavingAmount(savingAmount => [...savingAmount, (data.checkout.lines[index].variant.pricing.priceUndiscounted.net.amount - data.checkout.lines[index].variant.pricing.price.net.amount) * data.checkout.lines[index].quantity])
            }
			// data.checkout.lines?.map((cart)=>(
			// 	cart?.variant?.quantityAvailable === 0?null:  
			// 	setOutOfStockName(pre=>pre.filter((data)=>data!==cart?.variant?.name))
				
			// ))
		}	
	})

	// useEffect(()=>{
	// 	if(cartItems?.checkout?.lines?.length >0){
	// 		cartItems?.checkout?.lines?.map((cart)=>( 
	// 		cart?.quantity > cart?.variant?.quantityAvailable ? handleChange(cart?.variant?.id,cart?.variant?.quantityAvailable) : null
	// 	))}
	// 	if(cartItems?.checkout?.lines?.filter((cart)=>cart?.variant?.quantityAvailable === 0).length>0){
	// 		cartItems?.checkout?.lines?.map((cart)=>(
	// 					cart?.variant?.quantityAvailable !== 0?null:  
	// 					cart?.id?removeProduct(cart?.id):null
	// 				))
	// 	}

	// },[cartItems?.checkout?.id])

	if(loading){
		return (<div class="loading-overlay">
        <div class="bounce-loader">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>)
	}

	if(error) {
		return (<Navigate to="/" />);
	}

	const handleChange=(id,quantity)=>{
		//const { id, name } = e.target;
		console.log(quantity,"name",)
		if(quantity){
			//console.log("Check Check Check")
		updateLineCheckout({
				variables: {
					token: localStorage.getItem('cartToken'),
					lines: [{
						quantity: quantity,
						variantId: id
					}]
				},
				onCompleted: data=>{
					console.log("data",data)
					 setRefresh(refresh +12)	
					
					//window.location.reload(false)
				}
			})
	
		}
	}
	

// 	useEffect(()=>{
// 		if(cartItems?.checkout?.lines?.length >0){
// cartProduct?.map((cart)=>(
// 		cart?.variant?.quantityAvailable !== 0?null:  
// 		cart?.id?removeProduct(cart?.id):null
// 	))
// }
// 	},[cartItems?.checkout?.id])

// cartProduct.map((cart)=>(
// 		cart?.variant?.quantityAvailable !== 0?null:  
// 		cart?.id?removeProduct(cart?.id):null
// 	))
	// cartProduct.map((cart)=>(
	// cart.quantity>cart?.variant?.quantityAvailable?addProductQuantity(cart?.variant?.id,5):null
	// ))

		// cartProduct?.map((cart)=>( 
		// 	cart?.quantity > 5 ? handleChange(cart?.variant?.id,19) : null
		// ))
	////////////

		// {cartProduct?.map((cart)=>(
		// 	cart?.variant?.quantityAvailable === 0?null:  
		// 	setOutOfStockName(pre=>({...pre,["name"]:cart?.variant?.name}))
			
		// ))}
		console.log("outOfStockName",outOfStockName)

    return (
    <>
	<Helmet>
<title>Hegmakart: Shopping Cart</title>
<meta name="description" content="Shopping Cart"/>
</Helmet>
      <Header/>
	 
      <main class="main">
			<div class="container">
				<ul class="checkout-progress-bar d-flex justify-content-center flex-wrap">
				<li class="active">
						<Link to="/cart" style={{color:"rgb(243, 150, 85)"}} >Shopping Cart</Link>
					</li>
					<li>
						<Link to="/checkout">Checkout</Link>
					</li>
					<li class="disabled">
						<a >Order Complete</a>
					</li>
				</ul>

				{cartProduct.length===0 ? <div className='emptyWishlist'>
          <MdRemoveShoppingCart className='brokenHeart'></MdRemoveShoppingCart>
        <h1>You Don't Have Any Items In Your Cart</h1>
        <Link to="/">Go Shop</Link>
        </div> : <div class="row">
					<div class="col-lg-8">
						<div class="cart-table-container">
							<table class="table table-cart">
								<thead>
									<tr>
										<th class="thumbnail-col"></th>
										<th class="product-col">Product</th>
										<th class="price-col">Price</th>
										<th class="qty-col">Quantity</th>
										<th class="text-right">Subtotal</th>
									</tr>
								</thead>
								<tbody>
									{ cartProduct.map((cart)=>(
										<>
										<tr class="product-row">
											<td>
												<div>
												<figure class="product-image-container" style={{cursor:"pointer"}}>
													<Link to={{pathname: `/product/${encodeURIComponent(cart.variant.product.slug)}`}} class="product-image">
														<img src={cart.variant.product.media[0].url} alt="product"/>
													</Link>

													<a  id={cart.id} onClick={()=>removeProduct(cart.id)} class="btn-remove icon-cancel" title="Remove Product"></a>
												
												</figure>
												
												</div>
											
												
											</td>
											
											<td class="product-col">
												<h5 class="product-title">
													<Link to={{pathname: `/product/${encodeURIComponent(cart.variant.product.slug)}`}}>{cart.variant.name}</Link>
												</h5>
											</td>
											
											<><td> {cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? <span class="new-price" style={{paddingRight:5}}>₹{totalValue(cart.variant.pricing.price.net.amount)}</span> : <span class="new-price" style={{paddingRight:5}}>₹ {totalValue(cart.variant.pricing.price.net.amount - cart.variant.weightedShipping)}</span>}
											{cart.variant.originalPrice !== cart.variant.pricing.price.net.amount ? <span class="old-price">₹{cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? totalValue(cart.variant.originalPrice) : totalValue(cart.variant.originalPrice - cart.variant.weightedShipping)}</span> : null} 
											</td><td>
														<div class="product-single-qty">
															<div className='input-group bootstrap-touchspin bootstrap-touchspin-injected'>

																<button className='btn btn-outline text-dark btn-down-icon bootstrap-touchspin-down' name={cart.quantity} id={cart.variant.id} onClick={updateProduct} type='button'>-</button>

																<input class="horizontal-quantity form-control" autoComplete='off' type="number" id={cart.variant.id} name={getInputValue} onChange={(e) => { setGetInputValue(e.target.value) } } onKeyUp={(e) => handleChange(cart.variant.id,getInputValue)} value={cart.quantity} min={0} max={50} />

																<button className='btn btn-outline text-dark btn-up-icon bootstrap-touchspin-up' id={cart.variant.id} onClick={addProduct} type='button'></button>

															</div>
{/* {cartProduct.map((cart)=>(
                            cart?.variant?.quantityAvailable == 0?null:  
                            <h6 className='text-danger'>Out of stock</h6>
							
                        ))} */}
						{/* {
							cartItems?.checkout?.lines?.map((cart)=>( 
								cart?.variant?.quantityAvailable !== 0?	cart?.quantity > cart?.variant?.quantityAvailable ? <h6 className='text-danger'>Available {cart?.variant?.quantityAvailable} quantity</h6> : null:null
	 	))
						} */}
														</div>
														{/* <!-- End .product-single-qty --> */}
													</td><td class="text-right"><span class="subtotal-price">₹{cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? totalValue(cart.variant.pricing.price.net.amount * cart.quantity) : totalValue((cart.variant.pricing.price.net.amount - cart.variant.weightedShipping) * cart.quantity)}</span></td></>
													
										</tr>
										<tr>
										{/* {cart.variant.product.purchaseNote!==null?<div style={{color:"orangered", fontWeight:500,marginBottom:15}}>{cart.variant.product.purchaseNote.split('\n').map((i,key)=>{
											return  <div style={{width:150}}>{i}</div> })}</div>:null} */}
											<td style={{border:0,padding:0,paddingBottom:10}}></td>
											<td style={{border:0,padding:0,paddingBottom:10}}>{
                            cart?.variant?.quantityAvailable !== 0?null:  
                            <h6 className='text-danger mb-0'>Out of stock. Will be removed on checkout</h6>		
                      }
						{
							
							cart?.variant?.quantityAvailable !== 0?	cart?.quantity > cart?.variant?.quantityAvailable ? <h6 className='text-danger mb-0'> Only {cart?.variant?.quantityAvailable} available. Quantity will be updated on checkout</h6> : null:null
	 	
						}
						</td>
						<td style={{border:0,padding:0,paddingBottom:10}}></td>
						<td style={{border:0,padding:0,paddingBottom:10}}></td>
						<td style={{border:0,padding:0,paddingBottom:10}}></td>
											</tr>
										</>
										
									))}
								</tbody>
								<div>
								
								</div>
								
								<tfoot>
									<tr>
										<td colspan="5" class="clearfix">
											{/* <div class="float-left">
												<div class="cart-discount">
													<form>
														<div class="input-group">
															<input type="text" class="form-control form-control-sm"
																placeholder="Coupon Code" onChange={({ target }) => setCouponCode(target.value)} required/>
															<div class="input-group-append">
																<button class="btn btn-sm" onClick={addPromoCode} type="button">Apply
																	Coupon</button>
															</div>
														</div>
													</form>
												</div>
											</div> */}
                                            {/* <!-- End .float-left --> */}

											{/* <div class="float-right">
												<button type="submit" class="btn btn-shop btn-update-cart">
													Update Cart
												</button>
											</div> */}
                                            {/* <!-- End .float-right --> */}
										</td>
									</tr>
								</tfoot>
							</table>
							{
										cartProduct.map((cart)=>(
											<>
											{
                            cart?.variant?.quantityAvailable !== 0?null:  
                            <h5 className='text-danger mb-0 fw-bolder'>Few items are unavailable for checkout. Please continue with other available items.</h5>		
                      }
						{
							
							cart?.variant?.quantityAvailable !== 0?	cart?.quantity > cart?.variant?.quantityAvailable ? <h5 className='text-danger mb-0 fw-bolder'>Few items are unavailable for checkout. Please continue with other available items.</h5> : null:null
	 	
						}
											</>
										))

									}
						</div>
                        {/* <!-- End .cart-table-container --> */}
					</div>
                    {/* <!-- End .col-lg-8 --> */}

					<div class="col-lg-4">
					{/* {cartProduct.map((cart)=>(
                            cart?.variant?.quantityAvailable !== 0?null:  
                            <OutOfStock productName={cart?.variant?.name}/>
							
                        ))} */}
						<div class="cart-summary">
							<h3>CART TOTALS</h3>

								{ cartProduct.map((cart)=>(
                                    <div class="product" style={{marginBottom:20}}>
                                            <div class="product-details">
                                                <h6 class="product-title" style={{marginBottom:0}}>
                                                    <Link to={{pathname: `/product/${encodeURIComponent(cart.variant.product.slug)}`}}>{cart.variant.name}</Link>
                                                </h6>

                                                <span class="cart-product-info">
                                                    <span class="cart-product-qty">{cart.quantity}</span> × 	{cart.variant.weightedShipping===null ||cart.variant.weightedShipping===0 ? <span class="new-price" style={{paddingRight:5}}>₹{ totalValue(cart.variant.pricing.price.net.amount) }</span>:<span class="new-price" style={{paddingRight:5}}>₹{ totalValue(cart.variant.pricing.price.net.amount-cart.variant.weightedShipping) }</span>}{cart.variant.originalPrice !== cart.variant.pricing.price.net.amount?<span class="old-price">₹{cart.variant.weightedShipping===null ||cart.variant.weightedShipping===0 ? totalValue(cart.variant.originalPrice) : totalValue(cart.variant.originalPrice-cart.variant.weightedShipping) }</span>:null}
												
                                                </span>
												<span style={{float:'right'}}>₹{ cart.variant.weightedShipping===null || cart.variant.weightedShipping===0? totalValue(cart.variant.pricing.price.net.amount * cart.quantity) : totalValue((cart.variant.pricing.price.net.amount-cart.variant.weightedShipping)*cart.quantity) }</span>
                                            </div>
                                            {/* <!-- End .product-details -->                                             */}
                                    </div>
								))}

                                    {/* <!-- End .cart-product --> */}
                                    {/* <div class="dropdown-cart-subtotal" style={{paddingTop:'20px'}}>
                                        <span>SUBTOTAL:</span>

                                        <span class="cart-subtotal-price float-right">₹{cartData.totalPrice.net.amount}</span>
                                    </div> */}
									
									<div class="discount price">
                                        <span>Total Saving:</span>
										<span class="cart-total-discount float-right">₹{ totalValue(cartProduct.reduce((total, item)=>total+((item.variant.originalPrice-item.variant.pricing.price.net.amount)*item.quantity),0))}</span>
                                    </div>
									<div class="discount price">
                                        <span>Weighted Delivery Charges:</span>
                                        <span class="cart-subtotal-price float-right">₹{ totalValue(cartProduct.reduce((total, item)=>  total+(item.variant.weightedShipping*item.quantity),0)) }</span>
                                    </div>
									
									
                                    {/* <div class="dropdown-cart-subtotal">
                                        <span>GST:</span>

                                        <span class="cart-subtotal-price float-right">₹{cartData.totalPrice.tax.amount}</span>
                                    </div>
									
									<div class="dropdown-cart-subtotal">
                                        <span>Shipping:</span>

                                        <span class="cart-subtotal-price float-right">₹{cartData.shippingPrice.net.amount}</span>
                                    </div> */}
									
									
                                    {/* <!-- End .dropdown-cart-total --> */}

                                    
                                    {/* <!-- End .dropdown-cart-total --> */}

                                    <table class="table table-mini-cart">
                                <thead>
                                    
                                </thead>
                                <tbody></tbody>
                                <tfoot>
                                    


                                    <tr class="order-total">
                                        <td style={{paddingLeft:0,paddingTop:30}}>
                                            <h4 style={{marginBottom:0}}>Grand Total</h4>
											<p style={{fontSize:"1.2rem"}}>(inclusive of all taxes)</p>
                                        </td>
                                        <td style={{paddingRight:0,paddingTop:0}}>
                                            <b class="total-price"><span>₹{totalValue(cartData.totalPrice.gross.amount)}</span></b>
                                        </td>
                                    </tr>
                                </tfoot>
                                </table>

                               
                                
                               
                            

							<div class="checkout-methods">
								<span><h4 style={{color:"red", fontSize:"1.3rem",fontWeight:600,marginBottom:5, marginTop:20,fontFamily: 'Open Sans'}}>Note: Shipping Charges will be calculated on Checkout</h4></span>
								<Link to="/checkout" class="btn btn-block btn-dark">Proceed to Checkout
									<i class="fa fa-arrow-right"></i></Link>
							</div>
						</div>
                        {/* <!-- End .cart-summary --> */}
					</div>
                    {/* <!-- End .col-lg-4 --> */}
				</div>}




                {/* <!-- End .row --> */}
			</div>
            {/* <!-- End .container --> */}

			<div class="mb-6"></div>
            {/* <!-- margin --> */}
		</main>
     
     
<Footer/>
    </>
  )
}

export default CartScreen
