export function totalValue(x) {
    return Number.parseFloat(x).toLocaleString('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }


















  // export function totalValue(x) {
  //   return Number.parseFloat(x).toFixed();
  // }