import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client/react'
import { GET_CATEGORIES_SUBLIST } from '../../GraphQL/Queries'
import { MdKeyboardArrowDown,MdKeyboardArrowUp } from 'react-icons/md';
import { GET_CHECKOUT_BY_TOKEN } from "../../GraphQL/Queries"
import "./Footer.css"

const MobileNavbar = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showSubIcon, setShowSubIcon] = useState("")

  const [categoriess, setCategoriess] = useState([]);
  const [menuId,setMenuId]= useState()
  const [quantity, setQuantity] = useState("");
  const { } = useQuery(GET_CATEGORIES_SUBLIST, {
    variables: {
      first: 10,
      level: 0,
    },
    onCompleted: (data) => {
      //console.log(data);
      setCategoriess(data.categories.edges);
  
    },
  });
  // add to wishlist
  const [wishlistquantity, setWishlistQuantity] = useState("");
  const {refetch:refetch2} = useQuery(GET_CHECKOUT_BY_TOKEN, {
    variables: {
      token: localStorage.getItem("wishlistToken"),
    },
    onCompleted: (data) => {
      setWishlistQuantity(data.checkout.lines.length);
    //  console.log()
    },
  });
  refetch2()
  // Addto cart icon show add product indicator
  const { } = useQuery(GET_CHECKOUT_BY_TOKEN, {

    variables: {
      token: localStorage.getItem("cartToken"),
    },
    onCompleted: (data) => {
      try {
        setQuantity(data.checkout.quantity)
      } catch (error) {
        
      }
      
    },
  });
//  const SubIcon=(e)=>{
// setShowSubIcon(e)
// e === showSubIcon?setShowSubMenu(true):setShowSubMenu(false)

//  }

  return (
    <div className='position-relative'>
      {showCategories && <div class="mobileMenuContainer">
        <div class="mobile-menu-wrapper">
          <span onClick={() => { setShowCategories(false) }} class="mobileMenuClose">
            <i class="fa fa-times"></i>
          </span>
          <nav class="mobile-nav">
            <ul style={{ color: "white" }} class="mobile-menu">
              {categoriess.map((values) => (
                <>
                  <li>
                    <div> <Link to={`/category/${values.node.slug}`} className='category-List'>{values.node.name}</Link>
                      <span >
                      {showSubIcon === values.node.id?
                      <MdKeyboardArrowUp onClick={()=>setShowSubIcon("")}  style={{ fontSize: "15px", position: "absolute", top: 9, right: 14 }} />:
                      <MdKeyboardArrowDown onClick={()=>setShowSubIcon(values.node.id)} style={{ fontSize: "15px", position: "absolute", top: 9, right: 14 }}/>}
                      </span>
                    </div>
                    {showSubIcon === values.node.id?
                      <ul style={{ display: "block", color: "white" }}>
                        {values.node.children.edges.map((value) =>
                        (<li>
                          <Link to={`/category/${values.node.slug}/${value.node.slug}`}>{value.node.name}</Link>
                        </li>))}
                      </ul> : null}
                  </li>

                </>

              ))}



            </ul>



          </nav>
          {/* <!-- End .mobile-nav --> */}




        </div>
        {/* <!-- End .mobile-menu-wrapper --> */}
      </div>}

      <div class="sticky-navbar">
        <div class="sticky-info">
          <Link to="/">
            <i class="icon-home"></i>Home
          </Link>
        </div>
        <div class="sticky-info">
          <a onClick={() => { setShowCategories(true) }} class="">
            <i class="icon-bars"></i>Categories
          </a>
        </div>

        <div class="sticky-info " onClick={() => window.location.reload(false)}>
          <Link to="/wishlist" class="">
            {/* <i class="icon-wishlist-2"></i> */}
            <i class="icon-wishlist-2 position-relative">
              <span class="cart-count badge-circle">{wishlistquantity}</span>
            </i>Wishlist
          </Link>
          {/*  */}
         
        </div>
        <div class="sticky-info">
          {/* <Link to="/login"
            class="">
            <i class="icon-user-2"></i>Account
          </Link> */}
          {localStorage.getItem("user") === null ? <Link to="/login"><i class="icon-user-2"></i>Account </Link> : <Link to="/Dashboard">  <i class="icon-user-2"></i>Account</Link>}
        </div>
        <div class="sticky-info">
          <Link to="/cart" class="" >
            <i class="icon-shopping-cart position-relative">
              <span class="cart-count badge-circle">{quantity}</span>
            </i>
            Cart
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MobileNavbar
