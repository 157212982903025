import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { SAVED_ADDRESSES } from "../GraphQL/Queries";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_ADDRESS_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_ADDRESS_DELETE } from "../GraphQL/Mutation";
import { SAVED_ORDERS } from "../GraphQL/Queries";
import { PASSWORD_CHANGE_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_UPDATE_MUTATION, CREATE_CHECKOUT_MUTATION, CHECKOUT_ADD_PRODUCT_LINE_MUTATION } from "../GraphQL/Mutation";
import "./Dashboard.css";

import { BsTrash} from 'react-icons/bs'
import { Helmet } from "react-helmet";
const DashboardScreen = () => {
  const [addAddressLoader, setAddAddressLoader]=useState("SAVE ADDRESS")
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [Address, setAddress] = useState([]);
  const [addressDeleteId, setAddressDeleteId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [changedFirstName, setChangedFirstName] = useState();
  const [changedLastName, setChangedLastName] = useState();
  const [companyName, setCompanyName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [cityArea, setCityArea] = useState();
  const [cityName, setCityName] = useState();
  const [stateName, setStateName] = useState();
  const [pincode, setPincode] = useState();
  const [phone, setPhone] = useState();
  const [order, setOrders] = useState([]);
  const [errorMe, errorMessage] = useState([]);
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
	const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
const [userFirstName, setUserFirstName]= useState();
const [userLastName, setUserLastName]= useState();
  const username = localStorage.getItem("userName");
const [showPasswordChangeInterface, setShowPasswordChangeInterface]= useState(false)
  const email = localStorage.getItem("user");
  const [change, setChange]= useState(1)

  const pageTop =()=>{
    window.scrollTo(0, 0);
  }
  useEffect(()=>{
    pageTop()
    setChange(5)
},[change])

  const logoutHandeler = () => {
    //localStorage.clear();
    localStorage.removeItem('dummyemail')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userName')
    localStorage.removeItem('user')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    localStorage.removeItem('recentData')
    localStorage.removeItem('rzp_checkout_anon_id')
    localStorage.removeItem('rzp_device_id')
  };

 
  useEffect(() => {
  const loggedInUserByOtp = localStorage.getItem("loginByOtp");
  if(loggedInUserByOtp){
  setShowPasswordChangeInterface(true)
  }
}, []);

 
 


console.log(stateName)
  const reOrder = e => {
		const { id } = e.target;
    if (localStorage.getItem("cartToken") === null) {
      CreateCheckout({
        variables: {
          channel: 'default-channel',
          email: '',
          lines: []
          },
          onCompleted: data => {
           // console.log(data);
            if (data.checkoutCreate.checkout !== null) {
              localStorage.setItem('cartToken', data.checkoutCreate.checkout.token);
              for (let index = 0; index < order.length; index++) {
                if(id === order[index].node.id) {
                  for (let index1 = 0; index1 < order[index].node.lines.length; index1++) {
                      addLineCheckout({
                        variables: {
                          token: data.checkoutCreate.checkout.token,
                          lines: [
                            {
                            quantity:  order[index].node.lines[index1].quantity,
                            variantId: order[index].node.lines[index1].variant.id
                            }
                            ]
                          },
                          onCompleted: data => {
                            //console.log(data);
                          }
                        });
                  }
                }
              }
            }
          }
        });
    } else {
      for (let index = 0; index < order.length; index++) {
        if(id === order[index].node.id) {
          for (let index1 = 0; index1 < order[index].node.lines.length; index1++) {
              addLineCheckout({
                variables: {
                  token: localStorage.getItem("cartToken"),
                  lines: [
                    {
                    quantity:  order[index].node.lines[index1].quantity,
                    variantId: order[index].node.lines[index1].variant.id
                    }
                    ]
                  },
                  onCompleted: data => {
                  //  console.log(data);
                  }
                });
          }
        }
      }
    }
    errorMessage("Product added to cart successfully.");
	};
  const PageRefreshHandeler=()=>{
    setTimeout(() => {
      window.location.reload(false)
    }, 1);
  }
  return (
    <>
    <Helmet>
<title>Hegmakart: Dashboard</title>
<meta name="description" content="Dashboard"/>
</Helmet>
      {modalOpen && (
        <div className="ModalBackground">
          <div className="ModalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h3>Are You Sure You Want to Continue?</h3>
            </div>

            <div className="footer">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
                id="CancelBtn"
              >
                Cancel
              </button>
              <button onClick={addressDeleteHandeler}>Continue</button>
            </div>
          </div>
        </div>
      )}
      <Header />
      <main class="main" id="myDIV">
        {/* <div class="page-header">
          <div class="container d-flex flex-column align-items-center">
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a >Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a >Shop</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    My Account
                  </li>
                </ol>
              </div>
            </nav>

            <h1>My Account</h1>
          </div>
        </div> */}

        <div class="container account-container custom-account-container">
        <div >
								<h4 className='errorBoxContent'>{errorMe}</h4>
			</div>
          <div class="row">
            <div class="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
              <h2 class="text-uppercase">My Account</h2>
              <ul class="nav nav-tabs list flex-column mb-0" role="tablist">
                <li class="nav-item">
                  <Link to="/Dashboard"
                    class="nav-link active"
                    id="dashboard-tab"
                    data-toggle="tab"
                    style={{fontWeight:700, color:"grey",cursor:"pointer"}}
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="true"
                  >
                    Dashboard
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/order"
                    class="nav-link"
                    id="order-tab"
                    data-toggle="tab"
                   
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                  >
                    Orders
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/address"
                    class="nav-link"
                    id="address-tab"
                    data-toggle="tab"
                   
                    role="tab"
                    aria-controls="address"
                    aria-selected="false"
                  >
                    Addresses
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/account-detail"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                  
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                   Profile
                  </Link>
                </li>
                {!showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/change-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                  
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Change Password
                  </Link>
                </li>}

                {showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/set-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                  
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Set Password
                  </Link>
                </li>}
                
                {/* <li class="nav-item">
                  <a
                    class="nav-link"
                    id="shop-address-tab"
                    data-toggle="tab"
                    href="#shipping"
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Shipping Address
                  </a>
                </li> */}
                <li class="nav-item">
                  <Link to="/wishlist" onClick={PageRefreshHandeler} class="nav-link">
                    Wishlist
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/login" onClick={logoutHandeler} class="nav-link">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-lg-9 order-lg-last order-1 tab-content">
              <div
                class="tab-pane fade show active"
                id="dashboard"
                role="tabpanel"
              >
                <div class="dashboard-content">
                  <p>
                    Hello <strong class="text-dark">{username}</strong> 
                    {/* (not
                    <strong class="text-dark">{username}</strong>?
                    <Link
                      to="/login"
                      onClick={logoutHandeler}
                      class="btn btn-link "
                    >
                      Log out
                    </Link>
                    ) */}
                  </p>

                  <p>
                    From your account dashboard you can view your
                    <Link to="/order" class="btn btn-link link-to-tab" >
                      recent orders
                    </Link>
                    , manage your
                    <Link to="/address" class="btn btn-link link-to-tab" href="#address">
                      shipping and billing addresses
                    </Link>
                    
                   
                    , and
                    <Link to="/account-detail" class="btn btn-link link-to-tab" >
                      edit your account details.
                    </Link>
                  </p>

                  <div class="mb-4"></div>

                  <div class="row row-lg">
                    <div class="col-6 col-md-4">
                      <div class="feature-box text-center pb-4">
                        <a class="link-to-tab">
                      <Link to="/order">    <i class="sicon-social-dropbox"></i></Link>
                        </a>
                        <div class="feature-box-content">
                          <h3>ORDERS</h3>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-4">
                      <div class="feature-box text-center pb-4">
                        <a class="link-to-tab">
                      <Link to="/address">   <i class="sicon-location-pin"></i></Link> 
                        </a>
                        <div class="feature-box-content">
                          <h3>ADDRESSES</h3>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-4">
                      <div class="feature-box text-center pb-4">
                        <a class="link-to-tab">
                      <Link to="/account-detail">    <i class="icon-user-2"></i></Link>
                        </a>
                        <div class="feature-box-content p-0">
                          <h3>ACCOUNT DETAILS</h3>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-4">
                      <div class="feature-box text-center pb-4">
                        <Link to="/wishlist">
                          <i class="sicon-heart"></i>
                        </Link>
                        <div class="feature-box-content">
                          <h3>WISHLIST</h3>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-md-4">
                      <div class="feature-box text-center pb-4">
                        <Link to="/login" onClick={logoutHandeler} >
                          <i class="sicon-logout"></i>
                        </Link>
                        <div class="feature-box-content">
                          <h3>LOGOUT</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End .row --> */}
                </div>
              </div>
              {/* <!-- End .tab-pane --> */}

             
              {/* <!-- End .tab-pane --> */}

              {/* <div class="tab-pane fade" id="shipping" role="tabpanel">
                <div class="address account-content mt-0 pt-2">
                  <h4 class="title mb-3">Shipping Address</h4>

                  <form class="mb-2" action="#">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            First name <span class="required">*</span>
                          </label>
                          <input type="text" class="form-control" required />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            Last name <span class="required">*</span>
                          </label>
                          <input type="text" class="form-control" required />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Company </label>
                      <input type="text" class="form-control" />
                    </div>

                    <div class="select-custom">
                      <label>
                        Country <span class="required">*</span>
                      </label>
                      <select name="orderby" class="form-control">
                        <option value="" selected="selected">
                          {" "}
                          Indian Territory
                        </option>
                        <option value="1">a</option>
                        <option value="2">b</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label>
                        Street address <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="House number and street name"
                        required
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Apartment, suite, unit, etc. (optional)"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label>
                        Town / City <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label>
                        State <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label>
                        Pincode <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-footer mb-0">
                      <div class="form-footer-right">
                        <button type="submit" class="btn btn-dark py-4">
                          Save Address
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ paddingTop: "30px" }}>
                   {" "}
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="Billing address"
                  />
                   {" "}
                  <label for="Billing address">
                    Make Silling Address Your Default Address
                  </label>
                   
                </div>
              </div> */}
              {/* <!-- End .tab-pane --> */}
            </div>
            {/* <!-- End .tab-content --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* !-- End .container --> */}

        <div class="mb-5"></div>
        {/* <!-- margin --> */}
      </main>
      <Footer />
    </>
  );
};

export default DashboardScreen;