import React, { useState, useEffect } from "react";
import { totalValue } from "./decimalFunction";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useParams } from "react-router";
import Ratings from "./Ratings";
import { Link } from "react-router-dom";
import "./Checkout.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation, Thumbs } from "swiper";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PRODUCT_DETAILS, GET_PRODUCT_LIST, GET_CHECKOUT_BY_TOKEN } from "../GraphQL/Queries";
import {
  CREATE_CHECKOUT_MUTATION, CHECKOUT_SHIPPING_ADDRESS_UPDATE,
  CHECKOUT_ADD_PRODUCT_LINE_MUTATION, CHECKOUT_LINE_DELETE, CHECKOUT_LINE_UPDATE, UPDATE_WISHLIST_MUTATION
} from "../GraphQL/Mutation";
import "./ProductScreen.css";
import "./HomeScreen.css"
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Editor from "../components/Editor";


function ProductScreen() {
  const navigate = useNavigate();
  const [showBulkEnquiry, setShowBulkEnquiry] = useState(false);
  const [currentImage, setCurrentImage] = useState(
    "assets/images/products/zoom/Woodpecker Dpex III Apex Locator 001.jpg"
  );
  const setImage = (e) => {
    setCurrentImage(e.target.src);
  };
  const [bulkEnquirySuccessMessage, setBulkEnquirySuccessMessage] =
    useState(false);
  const [product, setProducts] = useState({});
  const [descriptionTab, setDescriptionTab] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const [AdditionalInformation, setAddtitionalInformation] = useState(false)
  const [reviewTab, setReviewTab] = useState(false)
  const [productQuantity, setProductQuantity] = useState(1);
  const [description, setDescription] = useState({});
  const [variantId, setVariantId] = useState("");
  const [voucherlist, setVariantVoucherList] = useState("");
  let { slug } = useParams();

  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [updateWishlist] = useMutation(UPDATE_WISHLIST_MUTATION, {
    context: {
      headers: {
        Authorization: "JWT " + localStorage.getItem("token"),
      },
    },
  });
  const [bulkErrorMsg,setBulkErrorMsg] = useState(false)
  const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
  const [checkoutShippingUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [loader, setLoader] = useState(false);
  const [bulkEnquiryLoader, setBulkEnquiryLoader] = useState("Submit");
  const [checkoutLineDelete] = useMutation(CHECKOUT_LINE_DELETE)
  const [setattributes, setattributeList] = useState([]);
  const [setvariants, setvariantList] = useState([]);
  const [metaList, setmetaList] = useState([]);
  const [recData, setRecData] = useState([]);
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [pincode, setPincode] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [errorMe, errorMessage] = useState([]);
  const [gplProductData, setGplProductData] = useState([]);
  const [productReviewData, setProductReviewData] = useState("");
  const [productA, setProductA] = useState([]);
  const [modals, setModals] = useState("")
  const [ServiceAvailabilityPopup, setServiceAvailabilityPopup] = useState(false);
  const [ServiceUnavailabilityPopup, setServiceUnavailabilityPopup] = useState(false);
  const [errorName, setErrorName] = useState()
  const [errorEmail, setErrorEmail] = useState()
  const [errorPhone, setErrorPhone] = useState()
  const [addwishlist, setAddWishlist] = useState([])
  const [errorPin, setErrorPin] = useState()
  const [errorPrice, setErrorPrice] = useState()
  const [checkAvailabilityError, setCheckAvailabilityError] = useState(false)
  const [checkAvailabilityLoader, setCheckAvailabilityLoader] = useState("Check-Availability")
  const [notMatchRecently, setNotMatchRecently] = useState()
  const [wishHeart, setWishHeart] = useState()
  const [desReadMore, setDesReadMore] = useState("descriptionReadMore")
  const [updateLineCheckout] = useMutation(CHECKOUT_LINE_UPDATE);
  const [wishlist, setWishlist] = useState([])
  const [checkWishlist, setCheckWishlist] = useState([])
  let arr = ""
  //console.log("slug",(slug))

  const { } = useQuery(GET_CHECKOUT_BY_TOKEN, {
    variables: {
      token: localStorage.getItem('wishlistToken')
    },
    onCompleted: data => {
      console.log("@@@@@@@@@@@@setCheckWishlist", data.checkout.lines.length, data.checkout.lines)
      for (let index = 0; index < data.checkout.lines.length; index++) {
        setCheckWishlist((pre) => [...pre, data.checkout.lines[index].variant.id]);
        setWishlist((pre) => [...pre, pre, data.checkout.lines[index].variant.id])
      }
    },
  })

  const checkProductAvailability = (e) => {
    e.preventDefault();
    setCheckAvailabilityLoader(
      <div className="pinCodeLoader"></div>
    );
    var options = {
      method: 'post',
      url: 'https://wsi.rocketinpocket.com/hegma/v1/hegma/checkPostalCode',
      data: JSON.stringify({
        'postal_code': productA
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    axios(options)
      .then((response) => {
        console.log(response);
        setCheckAvailabilityLoader("Check-Availability")
        if (response.data !== '') {
          setServiceAvailabilityPopup(true);
        } else {
          setServiceUnavailabilityPopup(true);
        }
      })
      .catch((error) => {
        console.log(error)
        setCheckAvailabilityLoader("Check-Availability")
        setCheckAvailabilityError(true);
      })
  };

  const { loading } = useQuery(GET_PRODUCT_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      slug: slug,
      channel: "default-channel"
    },
    onCompleted: data => {
      console.log("product555", data)
      setNotMatchRecently(data.product.id)
      setProducts(data.product)
      setattributeList(data.product.attributes)
      // setCurrentImage(data.product.thumbnail.url)
      setCurrentImage(data.product?.media[0]?.url)
      setDescription(data?.product?.description)
      setVariantId(data.product.variants[0].id)
      setvariantList(data.product.variants);
      setmetaList(data.product.metadata)
      setLoading1(false)
    }
  })

  useEffect(() => {

    var options = {
      method: 'post',
      url: 'https://wsi.rocketinpocket.com/hegma/v1/hegma/productDetails',
      data: JSON.stringify({
        'product_id': slug
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    //axios(options)
    //.then((response) => {
    // let res = response.data
    //console.log(res.data)
    // setProducts(res.data.product);
    // setattributeList(res.data.product.attributes);
    // setCurrentImage(res.data.product.thumbnail.url);
    // setDescription(res.data.product.description);
    // setVariantId(res.data.product.variants[0].id);
    // setVariantVoucherList(res.data.product.variants[0].voucherTextArr);
    // setvariantList(res.data.product.variants);
    // setLoading1(false)
    // })
    // .catch((error) => {
    // console.log(error)
    //errorMessage("There is some technical error.");
    // })
    // {console.log("notMatchRecently",notMatchRecently)}

    if (localStorage.getItem("recentData") === null) {
      localStorage.setItem("recentData", JSON.stringify([...recData]));
    } else {
      if (localStorage.getItem("recentData").split(",").length < 7 && notMatchRecently !== undefined) {
        if (!localStorage.getItem("recentData").includes(notMatchRecently) && notMatchRecently !== undefined) {
          localStorage.setItem(
            "recentData",
            JSON.stringify([
              ...JSON.parse(localStorage.getItem("recentData")),
              notMatchRecently,
            ])
          );
        }
      } else {
        if (!localStorage.getItem("recentData").includes(notMatchRecently) && notMatchRecently !== undefined) {
          localStorage.setItem(
            "recentData",
            JSON.stringify(
              JSON.parse(localStorage.getItem("recentData")).splice(1)
            )
          );
          localStorage.setItem(
            "recentData",
            JSON.stringify([
              ...JSON.parse(localStorage.getItem("recentData")),
              notMatchRecently,
            ])
          );
        }
      }
    }

    setRecData(JSON.parse(localStorage.getItem("recentData")));
  }, [notMatchRecently]);

  useEffect(() => {
    if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
      setAddWishlist([])
      let wishToken = localStorage.getItem("wishlistToken").replaceAll("[","").replaceAll("]","").replaceAll("'","").replaceAll(" ","")
      for (let index = 0; index < wishToken.split(",").length; index++) {
        setAddWishlist((addwishlist) => [...addwishlist, wishToken.split(",")[index]])
      }
    }
    getProductReviewData()
  }, [])

  const { refetch } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      first: 100,
      channel: "default-channel",
      filter: {
        ids: recData
      }
    },
    onCompleted: (data) => {
      //  console.log("recentDataGplProductData",data)
      setGplProductData(data.products.edges);
    },
  });

  const getProductReviewData = async (e) => {
    // setBulkEnquiryLoader(
    //   <div style={{ top: 10, left: 172 }} className="loaderr"></div>
    // );
    var options = {
      method: "post",
      url: "https://" + window.location.hostname + "/api/v1/getProductReviewData/",
      data: JSON.stringify({
        product_slug: slug
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((response) => {
        console.log(response.data);
        setProductReviewData(response.data);
      })
      .catch((error) => {
        // setProductReviewData("(3, 'test', '12 May 2023 10:05:08', 'Rahul', 'Singhal1')");
        // setProductReviewData("(3, 'good', '17 Apr 2023 09: 07: 13', 'Rahul', 'Singhal12')(2, ' csacs', '17 Apr 2023 09: 07: 48', 'Rahul', 'Singhal12')(4, 'dre', '17 Apr 2023 09: 15: 17', 'Rahul', 'Singhal12')(3, 'xsa', '17 Apr 2023 09: 24: 58', 'Rahul', 'Singhal12')(3, 'fdbdt', '17 Apr 2023 09: 29: 30', 'Rahul', 'Singhal12')(3, ' v  vb', '17 Apr 2023 10: 41: 08', 'Rahul', 'Singhal12')");
        console.log(error);
      });
  };

  const recentlyView = []
  if (gplProductData.length > 0 && recData !== null) {
    gplProductData.map((data, index) => (
      recentlyView[(recData.indexOf(gplProductData[index].node.id))] = gplProductData[index]
    ))
    // for(let i=0;i < recData.length;i++){
    //   recentlyView[(recData.indexOf(gplProductData[i].node.id))]= gplProductData[i]
    // }
  }
  //console.log("recentlyView",recentlyView,recData)

  if (loading1) {
    return (
      <div class="loading-overlay">
        <div class="bounce-loader">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    );
  }

  // console.log("description",description)
  const obj = JSON.parse(description);
  //console.log("descriptionJSON.parse",obj)
  const onAddToCart = async (e) => {

    setLoader(true);

    e.preventDefault();

    const { id } = e.target;

    if (localStorage.getItem("cartToken") === null) {
      CreateCheckout({
        variables: {
          channel: "default-channel",
          email: '',
          lines: [
            {
              quantity: productQuantity,
              variantId: id,
            },
          ],
        },
        onCompleted: (data) => {
          console.log(data);
          if (data.checkoutCreate.checkout !== null) {
            localStorage.setItem("cartToken", data.checkoutCreate.checkout.token);
            // window.location.reload(false);	
            refetch()
          }
        },
      });
    } else {
      addLineCheckout({
        variables: {
          token: localStorage.getItem("cartToken"),
          lines: [
            {
              quantity: productQuantity,
              variantId: id,
            },
          ],
        },
        onCompleted: (data) => {
          console.log(data);
          setLoader(false);
          // window.location.reload(false);
          refetch()
        },
      });
    }
  };

  const onAddToWishlist = async (id) => {
    // e.preventDefault();
    // console.log("Product",id,variantId,"11")
    if (!wishlist.includes(id)) {
      setWishlist([...wishlist, id])
      setAddWishlist([...addwishlist, id])
      if(localStorage.getItem("wishlistToken")===null){
        localStorage.setItem("wishlistToken", JSON.stringify([id]));
      }else{
        if(!localStorage.getItem("wishlistToken").includes(id)){
      localStorage.setItem("wishlistToken",  JSON.stringify([
        ...JSON.parse(localStorage.getItem("wishlistToken")),
        id,
      ]));}}
      updateWishlist({
        variables: {
          input: {
            wishlistToken: [...addwishlist, id],
          },
        },
        onCompleted: (data) => {
          console.log("updateWishlist",data);
          refetch()
        },
      });
    }

    setWishHeart(id === variantId ? "added-wishlist" : "")
  };
  const removeWishlist = (e) => {
    const { id } = e.target;
    if (wishlist.includes(id)) {
      let unWishlist = wishlist.filter((item) => item !== id);
      setWishlist(unWishlist)
      setAddWishlist(unWishlist)
      localStorage.setItem("wishlistToken",JSON.stringify(unWishlist));
      console.log("REMOVE TO WISHLIST")
    }

  }

  const sendEmail = async (e) => {
    e.preventDefault();
    setBulkEnquiryLoader(
      <div style={{ top: 10, left: 172 }} className="loaderr"></div>
    );
   // console.log("CALCALCALCLA",!name || !phone || !email || !pincode || !price || !quantity)
    if(!name || !phone || !email || !pincode || !price || !quantity ){
      setBulkErrorMsg(true)
      setBulkEnquiryLoader("submit");
      return false
    }
  
   
    var options = {
      method: "post",
      url: "https://" + window.location.hostname + "/api/v1/bulkEnquiry/",
      data: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        pinCode: pincode,
        price: price,
        quantity: quantity,
        productName: product.name,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((response) => {
        console.log(response);
        setShowBulkEnquiry(false);
        setBulkEnquiryLoader("submit");
        setBulkEnquirySuccessMessage(true);
        setBulkErrorMsg(false)
      })
      .catch((error) => {
        console.log(error);
        setBulkErrorMsg(false)
        errorMessage("There is some technical error.");
      });
  };


  const increaseProductQuantity = (e) => {

    e.target.parentElement.children[1].value = parseInt(e.target.parentElement.children[1].value) + 1

    setProductQuantity(e.target.parentElement.children[1].value)
  }

  const decreaseProductQuantity = (e) => {
    if (e.target.parentElement.children[1].value > 1) {
      e.target.parentElement.children[1].value = parseInt(e.target.parentElement.children[1].value) - 1
    }
    else (
      e.target.parentElement.children[1].value = 1
    )


    setProductQuantity(e.target.parentElement.children[1].value);

  };

  const PageRefreshHandeler = () => {
    setTimeout(() => {
      window.location.reload(false)
    }, 1);
  }

  // let urlval = product.category.parent.name
  //console.log(urlval.toLowerCase())
  return (
    <>
      <Helmet>
        <title>Hegmakart: {product.name}</title>
        <meta name="description" content={product.name} />
        {metaList.map((value1) => (
          <meta name={value1.key} content={value1.value} />
        ))}
      </Helmet>
      {loader && (
        <div
          style={{
            width: "100%",
            zIndex: 1060,
            height: "100vh",
            position: "fixed",
            background: "rgba(0, 0, 0, 0.6)",
          }}
        >
          <div
            style={{ left: "50%", top: "50%", zIndex: 1060 }}
            className="loaderr"
          ></div>
        </div>
      )}
      {bulkEnquirySuccessMessage && (
        <div
          onClick={() => {
            setBulkEnquirySuccessMessage(false);
          }}
          style={{
            width: "100%",
            zIndex: 1060,
            height: "100vh",
            position: "fixed",
            background: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {bulkEnquirySuccessMessage && (
            <div className="errorBoxContentt">
              <div style={{ position: "relative", padding: 15 }}>
                <BsFillCheckCircleFill
                  style={{ color: "green", marginRight: 6 }}
                ></BsFillCheckCircleFill>
                We have received your query. We will get back to you soon!
                <div
                  className="closeBtn"
                  onClick={() => {
                    setBulkEnquirySuccessMessage(false);
                    setModals("")
                  }}
                >
                  X
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <main style={{ position: "relative" }} className={`main ${modals}`}>
        <Header />
        <div style={{ maxWidth: "95%" }} class="container">

          <nav aria-label="breadcrumb" class="breadcrumb-nav">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/" >
                  <i class="icon-home"></i>
                </Link>
              </li>
              {/* {console.log("level",product.category.level,"category",product.category.parent.name)} */}
              {product.category.parent != null ?
                <>
                  {product.category.level === 1 ? (
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to={`/category/${product.category.parent.name.toLowerCase()}`}>{product.category.parent.name}</Link>
                    </li>
                  ) : null}
                </> : null}
              <></>
              {product.category.parent != null ?
                <li class="breadcrumb-item" aria-current="page">
                  <Link to={`/category/${product.category.parent.name.toLowerCase()}/${product.category.slug}`}>{product.category.name}</Link>
                </li> : null}
              <li class="breadcrumb-item active">
                <a >{product.name}</a>
              </li>
            </ol>
          </nav>

          <div class="product-single-container product-single-default">
            <div class="cart-message d-none">
              <strong class="single-cart-notice">{product.name}</strong>
              <span>has been added to your cart.</span>
            </div>

            <div class="row">
              <div class="col-lg-5 col-md-5 product-single-gallery">
                <div class="product-slider-container">
                  <div style={{ display: "flex", justifyContent: "center" }} class="">
                    <div style={{ height: 368, width: 368 ,}} class="product-item">
                      <img
                        style={{ height: '100%',objectFit:"contain" }}
                        class="product-single-image"
                        src={currentImage}
                      />
                    </div>
                  </div>
                  {/* <!-- End .product-single-carousel --> */}
                  {/* <span class="prod-full-screen">
                    <i class="icon-plus"></i>
                  </span> */}
                </div>
                {/* {console.log(product.media.length)} */}
                {product.media.length <= 3 ?
                  <div class="prod-thumbnail">
                    {product.media.map((media) => (
                      <div style={{ height: 100, marginTop: "20px", maxWidth: 105, border: "1px solid #dadada", borderRadius: 3, marginRight: 15 }} class="owl-dot">
                        <img
                          style={{ height: "100%",objectFit:"contain" }}
                          onClick={setImage}
                          src={media.url}
                          alt="product-thumbnail"
                        />
                      </div>
                    ))}
                  </div>
                  :
                  <div class="prod-thumbnail">
                    <Swiper
                      breakpoints={{
                        290: {
                          slidesPerView: 1,
                        },
                        300: {
                          slidesPerView: 2,
                        },
                        400: {
                          slidesPerView: 3,
                        },
                        480: {
                          slidesPerView: 4,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        992: {
                          slidesPerView: 3,
                        },
                        1200: { slidesPerView: 4, }
                      }}
                      spaceBetween={10}
                      centeredSlides={false}
                      loop={false}
                      slidesPerView={1}
                      navigation={true}
                      grabCursor={true}
                      modules={[Thumbs, Navigation]}
                      className="mySwiper">
                      <div className="">

                        {product.media.map((media) => (
                          <SwiperSlide >
                            <div style={{ height: 100, marginTop: "20px", maxWidth: 100, border: "1px solid #dadada", padding: "5px", borderRadius: 3, }} >
                              <img
                                style={{ height: "100%" }}
                                onClick={setImage}
                                src={media.url}
                                alt="product-thumbnail"

                              />
                            </div>
                          </SwiperSlide>
                        ))}


                      </div>
                    </Swiper>
                  </div>
                }
                <div class="cartcontain">
                  {product.variants[0].quantityAvailable !== 0 ?
                    !product.productType.hasVariants ? (

                      <>
                        <div
                          style={{ marginRight: 32, position: "relative" }}
                          class="cartcontainr"
                        >
                          {/* {console.log(product.variants.length, product.variants[0].quantityAvailable)} */}
                          <a
                            id={variantId}
                            onClick={onAddToCart}
                            style={{ color: "white" }}
                            class="btn btn-dark save-change"
                            title="Add to Cart"
                          >
                            Add to Cart
                          </a>
                        </div>
                      </>
                    ) : null
                    : null}

                  <div style={{ paddingLeft: 0 }} class="cartcontainn">
                    <button
                      class="btn btn-dark"
                      onClick={() => {
                        setShowBulkEnquiry(true);
                        setModals("pageBg")
                      }}
                    >
                      Bulk Enquiry
                    </button>

                  </div>
                  {console.log("checkWishlist.includes(product.variants[0].id)", wishHeart !== "added-wishlist" && !checkWishlist.includes(variantId))}
                  <div class="cartcontainr">
                    {!checkWishlist.includes(variantId) ?
                      <><a
                        style={{ cursor: "pointer", fontSize: "1.3rem" }} 
                        onClick={()=>onAddToWishlist(product.id)}

                        class={`btn-icon-wish add-wishlist`}
                        title="Add to Wishlist"
                      >
                        <i class="icon-wishlist-2" id={product.id}></i>
                        <span>add to wishlist</span>
                      </a></>
                      :
                      <a
                        style={{ cursor: "pointer", fontSize: "1.3rem" }}
                        onClick={() => navigate("/wishlist")}

                        class={`btn-icon-wish add-wishlist ${checkWishlist.includes(variantId) ? "added-wishlist" : wishHeart} heartt`}
                        title="Add to Wishlist"
                      >
                        <i class="icon-wishlist-2" id={checkWishlist.includes(product.id)}></i>
                        <span>Browse  wishlist</span>
                      </a>
                    }

                  </div>
                </div>
              </div>
              {/* <!-- End .product-single-gallery --> */}

              <div class="col-lg-7 col-md-7 product-single-details">
                <h1 class="product-title" style={{ fontSize: "20px", fontWeight: 500 }}>
                  {product.name}
                </h1>

                <div class="ratings-container">
                  <div className="">
                    {!(product.rating == null || product.rating == 0) ?
                      <span className="" style={{ width: "100%" }}>
                        {product.rating.toString().length > 1?product.rating.toFixed(1):product.rating} <i className='fas fa-star text-warning'></i>
                      </span> : null}
                    {/* <!-- End .ratings --> */}
                    <span className="tooltiptext tooltip-top">
                      {product.id}
                    </span>
                  </div>
                  {/* <!-- End .product-ratings --> */}

                  {/* <a style={{ color: "#878787", fontWeight: 500 }} class="rating-link">
                    ( {product.rating} reviews )
                  </a> */}
                </div>
                {/* <!-- End .ratings-container --> */}
                {setattributes.map((attributes) => (
                  <>
                    {attributes.attribute.name === "Manufacturer" ? (
                      <ul class="single-info-list">
                        <li

                        >
                          <strong style={{ textTransform: "capitalize" }}> Manufacturer:</strong>{" "}
                          <span style={{ textTransform: "initial", fontWeight: 600, color: "#666" }}>  {attributes.values.length > 0 ? attributes.values[0].name : null}</span>
                        </li>
                      </ul>
                    ) : null}
                  </>
                ))}
                <hr class="short-divider" />

                {!product.productType.hasVariants ? (
                  <div class="price-box">
                    <>
                      {product.variants[0].originalPrice
                        !==
                        product.pricing.priceRange.start.net.amount ? (
                        <>
                        <span class="new-price" style={{paddingRight:5}}>
                            ₹{totalValue(product.pricing.priceRange.start.net.amount - product.variants[0].weightedShipping)}
                          </span>
                          {product.variants[0].originalPrice !== "0.00" && totalValue(product.variants[0].originalPrice) !== totalValue(product.pricing.priceRange.start.net.amount) ? <span class="old-price">
                            ₹
                            {
                              totalValue(product.variants[0].originalPrice - product.variants[0].weightedShipping)
                            }
                          </span> : null}
                        
                          {/* product.variants[0].weightedShipping !== null && product.variants[0].weightedShipping !== '0.00'? */}
                          {!(product.variants[0].weightedShipping === null || product.variants[0].weightedShipping === '0.00') ? <span style={{ color: "orangered", marginLeft: 10 }}>+ ₹ {totalValue(parseInt(product.variants[0].weightedShipping))} Weighted Delivery Charges</span> : null}
                        </>
                      ) : (
                        <>
                          <span class="new-price" style={{paddingRight:5}}>
                            ₹{(product.pricing.priceRange.start.net.amount).toLocaleString('en-IN')}
                          </span>
                          {!(product.variants[0].weightedShipping === null || product.variants[0].weightedShipping === '0.00') ? <span style={{ color: "orangered", marginLeft: 10 }}>+ ₹ {totalValue(parseInt(product.variants[0].weightedShipping))} Weighted Delivery Charges</span> : null}
                        </>

                      )}
                    </>
                  </div>
                ) : (
                  <div class="price-box">
                    <span style={{ color: "grey", fontWeight: 500, marginLeft: 0,paddingRight:5 }} class="new-price">
                      Starts from:
                      {product.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity) === totalValue(product.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? product.pricing.priceRange.start.net.amount - item.weightedShipping : product.pricing.priceRange.start.net.amount : totall = totall), Infinity)) ?
                        <span style={{ color: "black", fontFamily: "sans-serif" }}> ₹{totalValue(product.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? product.pricing.priceRange.start.net.amount - item.weightedShipping : product.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span>
                        : <> 
                        <span class="product-price" style={{ color: "black", fontFamily: "sans-serif" }}> ₹{totalValue(product.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? product.pricing.priceRange.start.net.amount - item.weightedShipping : product.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span>
                        <strike style={{ fontSize: "2rem", fontFamily: "sans-serif" }}> ₹{totalValue(product.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity))}</strike>

                          </>}

                    </span>

                  </div>
                )}
                {!product.productType.hasVariants ? (
                  product.variants[0].quantityAvailable !== 0 ?
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginTop: 0, marginRight: 10, marginBottom: 0 }}
                          class="product-single-qty"
                        >
                          <div style={{ height: "100%" }} className="input-group bootstrap-touchspin bootstrap-touchspin-injected">

                            <button
                              style={{ color: "black" }}
                              onClick={decreaseProductQuantity}
                              className="btn btn-outline  bootstrap-touchspin-down"
                              type="button"
                            >-</button>

                            <input
                              style={{ height: "100%" }}
                              onChange={(event) => {
                                setProductQuantity(event.target.value);
                              }}
                              class="horizontal-quantity form-control"
                              defaultValue="1"
                              min={0}
                              type="text"
                            />

                            <button
                              style={{ color: "black" }}
                              onClick={increaseProductQuantity}
                              className="btn btn-outline  bootstrap-touchspin-up"
                              type="button"
                            >+</button>

                          </div>
                        </div>

                        <div
                          style={{
                            width: 113,
                            float: "initial",
                            paddingTop: 0,
                            position: "relative",
                          }}
                          class="cartcontainr"
                        >
                          <a
                            id={variantId}
                            onClick={onAddToCart}
                            style={{ color: "white", fontSize: 11 }}
                            class="btn btn-dark save-change"
                            title="Add to Cart"
                          >
                            add to cart
                          </a>
                        </div>

                      </div>
                      {/* <div>
                        <p style={{ color: "#EF6A37", marginTop: 20 }}>
                          {voucherlist.map((voucher) => (
                            <>
                              {voucher.voucherText} <br />
                            </>
                          ))}
                        </p>
                      </div> */}
                    </>
                    : <span style={{ display: "flex", flexWrap: "nowrap", justifyContent: "start", marginLeft: 5, fontSize: 15, color: "red", fontWeight: "500" }}>SOLD OUT</span>
                ) : null}


                {/* <!-- End .price-box --> */}
                {product.purchaseNote ? <div style={{ color: "grey", fontWeight: 500, marginTop: 15 }} class="decrip">
                  {product.purchaseNote.split('\n').map((i, key) => {
                    return <div>{i}</div>
                  })}
                </div> : null}

                {(product.codAvailable == null || product.codAvailable) ?
                  <p style={{ fontWeight: 500, display: "flex", marginTop: 15 }}>Services: <p style={{ color: "#0EA501", fontWeight: 500, paddingLeft: 5 }}>Cash on delivery available</p></p> : <p style={{ fontWeight: 500, display: "flex", marginTop: 15 }}>Services: <p style={{ color: "orange", fontWeight: 500, paddingLeft: 5 }}>Cash on delivery not available</p></p>}

                <form
                  onSubmit={checkProductAvailability}
                  style={{ marginTop: 50, marginBottom: 15 }}
                >
                  <p style={{ fontWeight: 500 }}>Enter Your Pincode to Check Service Availability</p>
                  <div style={{ height: 30, }}>
                    <input
                      className="pincodeAvailableBox"
                      type=""
                      name="pin"
                      pattern="[0-9]{6}"
                      maxlength="6"
                      onChange={(event) => {
                        setProductA(event.target.value),
                          setServiceAvailabilityPopup(false),
                          setServiceUnavailabilityPopup(false),
                          setCheckAvailabilityError(false)
                      }}
                      required
                    />
                    <button type="submit" value="Check-Availability" className="mx-3 pincodeAvailableButton position-relative">{checkAvailabilityLoader}</button>

                  </div>


                  {ServiceAvailabilityPopup && (
                    <div style={{ color: "#6f6f6f", fontWeight: 500 }} class="pincheck">
                      Delivery available for the pincode ({productA})
                    </div>
                  )}

                  {ServiceUnavailabilityPopup && (
                    <div style={{ color: "red" }} class="pincheck">
                      This product is not available for delivery on pincode ({productA})
                    </div>
                  )}
                  {checkAvailabilityError && (
                    <div style={{ color: "red" }} class="pincheck">
                      There is some technical error
                    </div>
                  )}
                  <br />
                </form>


                <div class="product-desc">
                  <p></p>
                </div>
                {/* <!-- End .product-desc --> */}

                {/* <li>
									CATEGORY: <strong><a href="#" class="product-category">EQUIPMENT</a></strong>
								</li>

								<li>
									TAGs: <strong><a href="#" class="product-category">DENTAL</a></strong>,
									<strong><a href="#" class="product-category">EQUIPMENT</a></strong>
								</li>
                                 */}

                {/* <!-- End .product-single-qty --> */}

                {product.productType.hasVariants ? (
                  <div class="overoffer">
                    {setvariants.map((variants) => (
                      <div

                        class={`mainoffer addtoCartPghCon ${variants.quantityAvailable === 0 ? 'soldOutCon' : null}`}
                      >
                        {/* <figure style={{paddingRight:10}} class="product-image-container">
                            <a class="product-image">
                              <img
                                style={{ maxWidth: 70, height: 70 }}
                                src={variants.media.length === 0 ? "" : variants.media[0].url }
                                alt="product"
                              />
                            </a>
                          </figure> */}
                        <div class="underoffer">
                          <p
                            style={{ fontWeight: 600, fontSize: 16, marginBottom: 3 }}
                            class="ptitle"
                          >
                            {variants.name}
                          </p>
                          {variants.weightedShipping === null ? <b className="" style={{paddingRight:5}}> ₹{totalValue(variants.pricing.price.net.amount)}</b> : <b> ₹{totalValue(variants.pricing.price.net.amount - variants.weightedShipping)} </b>}
                          {variants.originalPrice !==
                            totalValue(variants.pricing.price.net.amount) ? (
                            <>

                              {variants.weightedShipping === null ? <strike>
                                ₹{totalValue(variants.originalPrice)}
                              </strike> : <strike>
                                ₹{totalValue(variants.originalPrice - variants.weightedShipping)}
                              </strike>}


                            </>

                          ) : null}
                          
                          
                          {!(variants.weightedShipping === null || variants.weightedShipping === '0.00') ? <span style={{ color: "#ff4500", marginLeft: 10, fontWeight: 500 }}> + ₹ {totalValue(parseInt(variants.weightedShipping))} Weighted Delivery Charges</span> : null}

                          {/* <p class="offdescrp">  {product.seoDescription}<br/></p> */}
                          {/* <div style={{ marginTop: 13 }}>
                            <p style={{ color: "#EF6A37" }}>
                              {variants.voucherTextArr.map((voucher) => (
                                <>
                                  {voucher.voucherText} <br />
                                </>
                              ))}
                            </p>
                          </div> */}
                        </div>
                        {console.log(variants.quantityAvailable)}
                        {variants.quantityAvailable !== 0 ?
                          <><div class="product-single-qty">
                            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">

                              <button
                                style={{ color: "black" }}
                                onClick={decreaseProductQuantity}
                                className="btn btn-outline  bootstrap-touchspin-down noContent"
                                type="button"
                              >-</button>

                              <input
                                onChange={(event) => {
                                  setProductQuantity(event.target.value);
                                }}
                                class="horizontal-quantity form-control"
                                defaultValue="1"
                                min={0}
                                type="text" />

                              <button
                                style={{ color: "black" }}
                                onClick={increaseProductQuantity}
                                className="btn btn-outline  bootstrap-touchspin-up"
                                type="button"
                              >+</button>

                            </div>
                          </div><div
                            class="product-single-qty"
                            style={{
                              float: "left",
                              width: "50%",
                              marginRight: 25,
                            }}
                          >
                              <a
                                id={variants.id}
                                onClick={onAddToCart}

                                style={{ color: "white", width: 120, height: "47.9px", fontSize: "1.2rem" }}

                                class="btn btn-dark save-change"
                                title="Add to Cart"
                              >
                                add to cart
                              </a>
                            </div></> : <span className="soldOut" >SOLD OUT</span>}
                      </div>
                    ))}
                  </div>
                ) : null}

                {/* <!--		    <div class="col-lg-3 col-md-3">
							<h1 class="product-title" style="font-size:20px">Woodpecker Dpex III Apex Locator </h1>
					   
					        </div>
						</div>
						--> */}

                <a class="btn btn-gray view-cart d-none">
                  View cart
                </a>
              </div>
              {/* <!-- End .product-action --> */}

              <hr class="divider mb-0 mt-0" />
              <div style={{ maxWidth: "95%" }} class="container">
                <div class="product-single-share mb-3">
                  <label class="sr-only">Share:</label>

                  {/* <div class="social-icons mr-2">
									<a href="#" class="social-icon social-facebook icon-facebook" target="_blank"
										title="Facebook"></a>
									<a href="#" class="social-icon social-twitter icon-twitter" target="_blank"
										title="Twitter"></a>
									<a href="#" class="social-icon social-linkedin fab fa-linkedin-in" target="_blank"
										title="Linkedin"></a>
									<a href="#" class="social-icon social-gplus fab fa-google-plus-g" target="_blank"
										title="Google +"></a>
									<a href="#" class="social-icon social-mail icon-mail-alt" target="_blank"
										title="Mail"></a>
								</div> */}
                  {/* <!-- End .social-icons --> */}
                </div>
              </div>
              {/* <!-- End .product single-share --> */}
            </div>
            {/* <!-- End .product-single-details --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .product-single-container --> */}
        <div style={{ maxWidth: "95%" }} class="container">
          <div class="product-single-tabs">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item ">
                <a
                  href="#product-desc-content"
                  class="nav-link active"
                  id="product-tab-desc"
                  data-toggle="tab"

                  role="tab"
                  aria-controls="product-desc-content"
                  aria-selected="true"
                >
                  Description
                </a>
              </li>

              <li class="nav-item">
                <a
                  href="#product-tags-content"
                  class="nav-link"
                  id="product-tab-tags"
                  data-toggle="tab"

                  role="tab"
                  aria-controls="product-tags-content"
                  aria-selected="false"
                >
                  Additional Information
                </a>
              </li>

              <li class="nav-item">
                <a
                  href="#product-reviews-content"
                  class="nav-link"
                  id="product-tab-reviews"
                  data-toggle="tab"

                  role="tab"
                  aria-controls="product-reviews-content"
                  aria-selected="false"
                >
                  Reviews
                </a>
              </li>
            </ul>

            <div class="tab-content">
              <div

                class="tab-pane fade show active pt-0"
                id="product-desc-content"
                role="tabpanel"
                aria-labelledby="product-tab-desc"
              >
                {/* <div class="product-desc-content">
                  <p>{obj.blocks[0].data.text}</p>
                </div> */}
                <div class={`product-desc-content mt-3 ${desReadMore}`}>
                  {obj?.blocks?.map((des) => (
                    <p className="mb-1">{des?.data?.text}</p>
                  ))}
                  {/* <Editor data={description} id={"productDes"}/> */}
                </div>
                {/* {console.log("obj.blocks.length",obj?.blocks?.map((des)=>des?.data?.text?.length),obj?.blocks?.length,obj.blocks)} */}
               
                {obj?.blocks?.length >= 2 || obj?.blocks?.[0]?.data?.text?.length >= 180  ? <span className="text-primary pointer" onClick={() => setDesReadMore(desReadMore === "descriptionReadMore" ? " " : "descriptionReadMore")}>{desReadMore === "descriptionReadMore" ? "Read more..." : "Read Less..."}</span> : null}
                {/* <!-- End .product-desc-content --> */}
              </div>

              {/* <!-- End .tab-pane --> */}
              <div

                class="tab-pane fade"
                id="product-tags-content"
                role="tabpanel"
                aria-labelledby="product-tab-tags"
              >
                <table class="table table-striped mt-2">
                  <tbody>
                    {setattributes.map((attributes) => (
                      <>
                        {attributes.values.length > 0 ? (
                          <>
                            <tr>
                              <th>{attributes.attribute.name}</th>
                              {attributes.values[0].file !== null ? (
                                <td>
                                  <a
                                    href={'https://hegmadental.s3.ap-south-1.amazonaws.com/file_upload/' + decodeURI(attributes.values[0].name)}
                                    target="_blank"
                                    download
                                  >
                                    {decodeURI(attributes.values[0].name)}
                                  </a>
                                </td>
                              ) : (
                                <td>
                                  {attributes.values.map((values) => (
                                    <>
                                      {values.length > 1 ? (
                                        <>{values.name}</>
                                      ) : (
                                        <>{values.name} ,</>
                                      )}
                                    </>
                                  ))}
                                </td>
                              )}
                            </tr>
                          </>
                        ) : null}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* <!-- End .tab-pane --> */}
              <div

                class="tab-pane fade"
                id="product-reviews-content"
                role="tabpanel"
                aria-labelledby="product-tab-reviews"
              >
                <div class="product-reviews-content">
                  {/* <h3 class="reviews-title">No Reviews for this Product</h3> */}

                  {(() => {
                    let td = [];
                    const arr1 = productReviewData.split(")(");
                    if (arr1.length >= 1) {
                      for (let index = 0; index < arr1.length; index++) {
                        var response1 = arr1[index];
                        response1 = response1.replaceAll(" '", "").replaceAll("'", "").replaceAll("(", "").replaceAll(")", "");
                        td.push(<div class="comment-list">
                          <div class="comments">
                            <figure class="img-thumbnail">
                              <img src="https://hegmadental.s3.ap-south-1.amazonaws.com/static/assets/images/blog/author.jpg" alt="author" width="80"
                                height="80" />
                            </figure>

                            <div class="comment-block">
                              <div class="comment-header">
                                <div class="comment-arrow"></div>

                                <div class="ratings-container float-sm-right">
                                  <div class="">
                                    <span className="" style={{ width: "100%" }}>
                                      {response1.split(",")[0]} <i className='fas fa-star text-warning'></i>
                                    </span>
                                    <span class="tooltiptext tooltip-top"></span>
                                  </div>
                                </div>

                                <span class="comment-by">
                                  <strong>{response1.split(",")[3]} {response1.split(",")[4]}</strong> – {response1.split(",")[2]}
                                </span>
                              </div>

                              <div class="comment-content">
                                <p>{response1.split(",")[1]}.</p>
                              </div>
                            </div>
                          </div>
                        </div>);
                      }
                    } else {
                      td.push(<div class="comment-list">
                        <div class="comments">
                          {/* <figure class="img-thumbnail">
                              <img src="https://hegmadental.s3.ap-south-1.amazonaws.com/static/assets/images/blog/author.jpg" alt="author" width="80"
                                height="80" />
                            </figure> */}

                          <div class="comment-block">
                            {/* <div class="comment-header">
                                <div class="comment-arrow"></div>

                                <div class="ratings-container float-sm-right">
                                  <div class="">
                                    <span className="" style={{ width: "100%" }}>
                                      {response1.split(",")[0]} <i className='fas fa-star text-warning'></i>
                                    </span>
                                    <span class="tooltiptext tooltip-top"></span>
                                  </div>
                                </div>

                                <span class="comment-by">
                                  <strong>{response1.split(",")[3]} {response1.split(",")[4]}</strong> – {response1.split(",")[2]}
                                </span>
                              </div> */}

                            <div class="comment-content">
                              <p>No reviews yet for this product.</p>
                            </div>
                          </div>
                        </div>
                      </div>);
                    }
                    return td;
                  })()}

                  {/* <div class="divider"></div>

								<div class="add-product-review">
									<h3 class="review-title">Add a review</h3>

									<form action="#" class="comment-form m-0">
										<div class="rating-form">
											<label for="rating">Your rating <span class="required">*</span></label>
											<span class="rating-stars">
												<a class="star-1" href="#">1</a>
												<a class="star-2" href="#">2</a>
												<a class="star-3" href="#">3</a>
												<a class="star-4" href="#">4</a>
												<a class="star-5" href="#">5</a>
											</span>

											<select name="rating" id="rating" required="" style={{display: 'none'}}>
												<option value="">Rate…</option>
												<option value="5">Perfect</option>
												<option value="4">Good</option>
												<option value="3">Average</option>
												<option value="2">Not that bad</option>
												<option value="1">Very poor</option>
											</select>
										</div>

										<div class="form-group">
											<label>Your review <span class="required">*</span></label>
											<textarea cols="5" rows="6" class="form-control form-control-sm"></textarea>
										</div>


										<div class="row">
											<div class="col-md-6 col-xl-12">
												<div class="form-group">
													<label>Name <span class="required">*</span></label>
													<input type="text" class="form-control form-control-sm" required/>
												</div>
											</div>

											<div class="col-md-6 col-xl-12">
												<div class="form-group">
													<label>Email <span class="required">*</span></label>
													<input type="text" class="form-control form-control-sm" required/>
												</div>
											</div>

											<div class="col-md-12">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input"
														id="save-name" />
													<label class="custom-control-label mb-0" for="save-name">Save my
														name, email, and website in this browser for the next time I
														comment.</label>
												</div>
											</div>
										</div>

										<input type="submit" class="btn btn-primary" value="Submit"/>
									</form>
								</div>*/}
                </div>

                {/* <!-- End .product-reviews-content --> */}
              </div>

              {/* <!-- End .tab-pane --> */}
            </div>
            {/* <!-- End .tab-content --> */}
          </div>
          {/* <!-- End .product-single-tabs --> */}

          <div style={{ maxWidth: "95%" }} className="container">
            <h2 class="section-title ls-n-10 pb-3 m-b-4">Recently Viewed</h2>

            <div class="row no-gutters">
              {recentlyView.slice(0, recentlyView.length - 1).reverse().map((value, index) => (
                <>
                  {recData !== null ? (
                    <>
                      {recData.includes(value.node.id) ? (
                        <div className="col-6 col-sm-4 col-md-3 col-xl-2">
                          <div className="product-default inner-quickview inner-icon">
                            <figure onClick={PageRefreshHandeler}>
                              <Link
                                to={{
                                  pathname: `/product/${encodeURIComponent(value.node.slug)}`,
                                }}
                              >
                                <img
                                  src={value.node.media[0].url}
                                  width="217"
                                  height="217"
                                  alt="product"
                                />
                                <img
                                  src={value.node.media[0].url}
                                  width="217"
                                  height="217"
                                  alt="product"
                                />
                              </Link>

                              <div className="btn-icon-group">
                                <Link
                                  to={{
                                    pathname: `/product/${encodeURIComponent(value.node.slug)}`,
                                  }}
                                  className="btn-icon btn-add-cart"
                                >
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              </div>
                              {/* <Link
                                to={`/category/${value.node.id}`}
                                onClick={() => {
                                  setQuickviewOpen(true);
                                }}
                                className="btn-quickview"
                                title="Quick View"
                              >
                                Quick View
                              </Link> */}
                            </figure>
                            <div className="product-details">
                              <div className="category-wrap">
                                <div className="category-list">
                                  <Link
                                    to={{
                                      pathname: `/product/${encodeURIComponent(value.node.slug)}`,
                                    }}
                                    className="product-category"
                                  >
                                    {value.node.category.name}
                                  </Link>
                                </div>
                                {/* <a onClick={wishlist.includes(value.node.variants[0].id)&&  checkWishlist.includes(value.node.variants[0].id) ?removeWishlist:onAddToWishlist} class={wishlist.includes(value.node.variants[0].id) &&  checkWishlist.includes(value.node.variants[0].id)? "btn-icon-wished added-wishlists" : "btn-icon-wished"} title="wishlist"> 
												   <i className="icon-heart" id={value.node.variants[0].id}></i>
												</a> */}
                                <a
                                  onClick={()=>wishlist.includes(value.node.id) ? removeWishlist : onAddToWishlist(value.node.id)} class={wishlist.includes(value.node.id) ? "btn-icon-wishs added-wishlists" : "btn-icon-wishs"}
                                  title="Add to Wishlist"
                                // className="btn-icon-wish"
                                >
                                  <i
                                    className="icon-heart"
                                    id={value.node.id}
                                  ></i>
                                </a>
                              </div>
                              <h3 className="product-title">
                                <Link
                                  to={{
                                    pathname: `/product/${encodeURIComponent(value.node.slug)}`,
                                  }}
                                >
                                  {value.node.name}{" "}
                                </Link>
                              </h3>
                              <div class="ratings-container">
                                <div class="">
                                  {!(value.node.rating == null || value.node.rating == 0) ?
                                    <span class="">
                                      {value.node.rating.toString().length > 1?value.node.rating.toFixed(1):value.node.rating} <i className='fas fa-star text-warning'></i>
                                    </span> : null}
                                  {/* <!-- End .ratings --> */}
                                  <span class="tooltiptext tooltip-top">
                                    {value.node.id}
                                  </span>
                                </div>
                                {/* <!-- End .product-ratings --> */}
                              </div>
                              {/* <!-- End .product-container --> */}
                              {!value.node.productType.hasVariants ?
                                <div className="price-box">
                                  <>
                                    {value.node.variants[0].originalPrice !== value.node.pricing.priceRange.start.net.amount ?
                                      <>
                                      {value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount)}</span> : <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
                                        {value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice)}</span> : <> {value.node.variants[0].originalPrice !== "0.00" && totalValue(value.node.variants[0].originalPrice) !== totalValue(value.node.pricing.priceRange.start.net.amount) ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice - value.node.variants[0].weightedShipping)}</span> : null}</>}
                                        
                                      </>
                                      : <span className="product-price">₹{totalValue(value.node.weightedShipping === null || value.node.weightedShipping === 0 ? value.node.pricing.priceRange.start.net.amount : value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
                                  </>
                                </div> :
                                <div className="price-box">
                                  <span style={{ color: "black", fontSize: "1.8rem",  paddingRight:5,
                                        paddingBottom:5,
                                        display:"inline-block" }}>From </span>
                                  {value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity) === totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity)) ? <span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span> : <>   
                                    <span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span>
                                    <strike class="product-pricee"> ₹{totalValue(value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity))}</strike></>}
                                  {/* <span className="product-price">From ₹{value.node.pricing.priceRange.start.net.amount}</span> */}
                                </div>
                              }
                              {/* <!-- End .price-box --> */}
                            </div>
                            {/* <!-- End .product-details --> */}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          </div>
          <div className="mb-4"></div>
          {/* <!-- margin --> */}
        </div>
        <Footer />
      </main>
      {showBulkEnquiry && (
        <div
          style={{ display: "block" }}
          class="form-popup"
          id="myForm"
        >
          <form class="form-container ">
            <h4>
              <span class="popuptitle">
                Let us know the details of Bulk Quantity required
                for {product.name}
              </span>
              {bulkErrorMsg?<p className="text-danger text-center fs-5 fw-normal">Please fill the all fields</p>:null}
            </h4>
            <div class="form-row">
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="name">
                  <b>Name</b>
                </label>
                <input

                  type="text"
                  placeholder="Enter Your Name"
                  name="name"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  required
                />{" "}
              </div>
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="phone">
                  <b>Phone</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Phone Number"
                  name="phone"
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                  required
                />
              </div>{" "}
            </div>

            <div class="form-row">
              {" "}
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="email">
                  <b>Email</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Email"
                  name="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  required
                />
              </div>
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="pin">
                  <b>Pin Code</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Pin Code"
                  name="pin"
                  onChange={(event) => {
                    setPincode(event.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div class="form-row">
              {" "}
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="pin">
                  <b>Price</b>
                </label>
                <input
                  type="text"
                  placeholder="Expected Price Per Piece"
                  name="price"
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                  required
                />
              </div>
              <div class="col-md-6 col-sm-12">
                {" "}
                <label for="pin">
                  <b>Quantity</b>
                </label>
                <input
                  type="number"
                  placeholder="Enter Quantity"
                  name="quantity"
                  onChange={(event) => {
                    setQuantity(event.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div class="pcbutton">
              {" "}
              <button
                style={{ position: "relative" }}
                type="submit"
                class="btn "
                onClick={sendEmail}
              >
                {bulkEnquiryLoader}
              </button>
            </div>

            <span
              class="deleteMeetingClose"
              onClick={() => {
                setShowBulkEnquiry(false);
                setModals("")
              }}
            >
              &times;
            </span>
          </form>
        </div>
      )}








    </>
  );
}

export default ProductScreen;
