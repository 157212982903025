import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Helmet } from 'react-helmet'

const OfflineOrder = () => {
	const bannerStyle = {
		backgroundSize: '60%/cover',
		backgroundImage: `url('assets/images/elements/page-header.png')`,
		backgroundRepeat: 'no-repeat'

	}

	const containerStyle = {
		textAlign: 'center',
		paddingTop: '10px',
		paddingBottom: '60px'
	}
	return (
		<>
			<Helmet>
				<title>Hegmakart: Offline Orders</title>
				<meta name="description" content="Offline Orders" />
			</Helmet>
			<Header />
			<main class="main info-box-wrapper">
				{/* <div class="category-banner-container bg-gray">
					<div class="category-banner banner text-uppercase"
						style={bannerStyle}>
						<div class="container position-relative">
							<nav aria-label="breadcrumb" class="breadcrumb-nav text-black">
								<ol class="breadcrumb justify-content-center">
									<li class="breadcrumb-item"><a >Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Offline Order</li>
								</ol>
							</nav>
							<h1 class="page-title text-center text-black">Offline Order</h1>

						</div>
					</div>
				</div> */}

				<div class="container mt-2" style={containerStyle}>					
						<div>
							<h5 style={{ lineHeight: 2.5,marginBottom:0 }}>
								Call us : +91-8553666877 / +91-8553666977 <br />
								<span>Email :</span><a href="mailto:support@hegmakart.com" style={{color:"#08C"}}> support@hegmakart.com </a> <br />
							</h5>
						</div>
						<br />
						<h5 className='mb-1'>Contact Address :</h5>
						<p>	#515,1st floor, 6th 'A' main, AECS Layout,<br />
						A Block,Singasandra , Bengalore. 560068<br />
						Karnataka, India
					</p>

				</div>
				{/* <!-- End .container --> */}



			</main>
			<Footer />
		</>
	)
}

export default OfflineOrder
