import React from "react";
import UpperHeader from "./UpperHeader";
import StickyHeader from "./StickyHeader";

const Header = () => {
  return (
    <>
      <div className="page-wrapper">
        <header className="header">
          
          <UpperHeader />
          {/* <!-- End .header-top --> */}
          <StickyHeader />
          {/* <!-- End .header-bottom --> */}
        
        </header>
        {/* <!-- End .header --> */}
      </div>
     

      
    </>
  );
};

export default Header;
