import React, { useState, useEffect } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { GET_PRODUCT_LIST } from '../GraphQL/Queries'
import { useMutation, useQuery } from '@apollo/client'
import { CHECKOUT_LINE_DELETE, CREATE_CHECKOUT_MUTATION, CHECKOUT_ADD_PRODUCT_LINE_MUTATION } from '../GraphQL/Mutation'
import { Link, Navigate } from 'react-router-dom'
import { FaHeartBroken } from 'react-icons/fa';
import './Modal.css'
import "./Wishlist.css"
import { Helmet } from 'react-helmet'
import { totalValue } from './decimalFunction'

const WishlistScreen = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [Token, setToken] = useState()
  const [wishlistProduct, setWishlistProduct] = useState([])
  const [checkoutLineDelete] = useMutation(CHECKOUT_LINE_DELETE)
  const [removeItemID, setRemoveItemId] = useState()
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
  const [loader, setLoader] = useState(false)
  const [change, setChange] = useState(1)
  const [addwishlist, setAddWishlist] = useState([])

  const pageTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    pageTop()
    setChange(5)
  }, [change])

  useEffect(() => {
    if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
      setAddWishlist([])
     // let wishToken = localStorage.getItem("wishlistToken").replaceAll("[","").replaceAll("]","").replaceAll("'","").replaceAll(" ","")
      let wishToken = localStorage.getItem("wishlistToken")
     // console.log('wishToken',wishToken)
     setAddWishlist(wishToken?JSON.parse(wishToken):"")
      // for (let index = 0; index < wishToken.split(",").length; index++) {
      //   setAddWishlist((addwishlist) => [...addwishlist, wishToken.split(",")[index]])
      // }
    }
    const getToken = localStorage.getItem('wishlistToken')
    //console.log("sfaghd")
    if (getToken) {
      setToken(getToken)
    }
  }, []);

  let wishToken = ''
  if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
    wishToken = localStorage.getItem("wishlistToken").split(',')
  }

  const { loading, data, refetch } = useQuery(GET_PRODUCT_LIST, {
    fetchPolicy: "no-cache",
		variables: {
			first: 12,
			channel: "default-channel",
			filter: {
				ids: wishToken,
			}
		},
    onCompleted: data => {
      // if(data.checkout.lines[1].variant.media[0].url.length === null) { console.log('some') }
       console.log("wishlistProduct",data)

      setWishlistProduct(data?.products?.edges)
    },

  })


  const removeItemHandler = (e) => {
    
    if (addwishlist.includes(removeItemID)) {
      if(addwishlist.length === 0){console.log("hello")}
      let unWishlist = addwishlist?.filter((item) => item !== removeItemID);
      setAddWishlist(unWishlist)
      localStorage.setItem("wishlistToken",JSON.stringify(unWishlist));
      setModalOpen(false)
      console.log("sssssfgf",removeItemID,addwishlist.length,"unWishlist",unWishlist)
    }
    
  }

  const onAddToCart = async e => {
    setLoader(true)

    e.preventDefault();
    const { id } = e.target;
    if (localStorage.getItem("cartToken") === null) {
      CreateCheckout({
        variables: {
          channel: 'default-channel',
          email: '',
          lines: [
            {
              quantity: 1,
              variantId: id
            }
          ]
        },
        onCompleted: data => {
          // console.log(data);
          if (data.checkoutCreate.checkout !== null) {
            localStorage.setItem('cartToken', data.checkoutCreate.checkout.token);
          }
          // window.location.reload(false)
          setLoader(false)
        }
      });
    } else {
      addLineCheckout({
        variables: {
          token: localStorage.getItem("cartToken"),
          lines: [
            {
              quantity: 1,
              variantId: id
            }
          ]
        },
        onCompleted: data => {
          console.log(data);
          setLoader(false)
          // window.location.reload(false)
          refetch()
        }
      });
    }
  };


  if (wishlistProduct?.length === 0) { console.log('some error occured') }
  refetch()

  return (
    <>
      <Helmet>
        <title>Hegmakart: Wishlist</title>
        <meta name="description" content="Wishlist" />
      </Helmet>
      {loader && (
        <div
          style={{
            width: "100%",
            zIndex: 1060,
            height: "100vh",
            position: "fixed",
            background: "rgba(0, 0, 0, 0.6)",
          }}
        >
          <div
            style={{ left: "50%", top: "50%", zIndex: 1060 }}
            className="loaderr"
          ></div>
        </div>
      )}
      {modalOpen && <div className="ModalBackground">
        <div className="ModalContainer">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              X
            </button>
          </div>
          <div className="title">
            <h3>Are You Sure You Want to Continue?</h3>
          </div>

          <div className="footer">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
              id="CancelBtn"
            >
              Cancel
            </button>
            <button onClick={removeItemHandler}>Continue</button>
          </div>
        </div>
      </div>}
      <Header />
      <main
        className="main">
        {/* <div class="page-header">
                <div class="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" class="breadcrumb-nav">
                        <div class="container">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="demo4.html">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    Wishlist
                                </li>
                            </ol>
                        </div>
                    </nav>

                    <h1 >Wishlist</h1>
                </div>
            </div> */}



        {wishlistProduct?.length > 0 ? <div class="container">
          <div class="wishlist-title">
            <h2 class="p-2">My wishlist </h2>
          </div>
          <div class="wishlist-table-container">
            <table class="table table-wishlist mb-0">
              <thead>
                <tr>
                  <th class="thumbnail-col"></th>
                  <th class="product-col">Product</th>
                  <th class="price-col">Price</th>
                  <th class="status-col"></th>
                  <th class="action-col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {wishlistProduct?.map((values) => (
                  <tr class="product-row">

                    <td key={values.node.id}>
                      {/* {console.log(values.varient.media[0].length)} */}
                      <figure class="product-image-container">
                        <a class="product-image">
                          <Link to={`/product/${encodeURIComponent(values.node.slug)}`}><img src={values.node.media.length > 0 ? values.node.media[0].url : null} alt="product" /></Link>
                        </a>

                        <a class="btn-remove icon-cancel" onClick={() => { setRemoveItemId(values.node.id); setModalOpen(true) }} title="Remove Product"></a>
                      </figure>
                    </td>
                    <td>
                      <h5 class="product-title">

                        <Link to={`/product/${encodeURIComponent(values.node.slug)}`}>{values.node.name}</Link>
                      </h5>
                    </td>
                    <td class="price-box">₹{totalValue(values.node.pricing.priceRange.start.gross.amount)}</td>
                    <td>
                      <span class="stock-status"></span>
                    </td>
                    {values.node.variants[0].quantityAvailable !== 0 ?
                      <td class="action">
                        <button onClick={onAddToCart} id={values.node.variants[0].id} class="btn btn-dark btn-add-cart product-type-simple btn-shop">
                          ADD TO CART
                        </button>
                      </td> : <td><span style={{ display: "flex", flexWrap: "nowrap", justifyContent: "start", marginLeft: 5, fontSize: 15, color: "red", fontWeight: "500" }}>SOLD OUT</span></td>}
                  </tr>
                ))}





              </tbody>
            </table>
          </div>

        </div> :

          <div className='emptyWishlist'>
            <FaHeartBroken className='brokenHeart'></FaHeartBroken>
            <h1>You Don't Have Any Items In Your Wishlist</h1>
            <Link to="/">Go Shop</Link>
          </div>}

        {/* <!-- End .container --> */}
      </main>
      <Footer />
    </>
  )
}

export default WishlistScreen
