import React, { useState, useEffect, useRef } from "react";
import { totalValue } from "../../screens/decimalFunction";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CATEGORIES_SUBLIST, GET_CHECKOUT_BY_TOKEN, GET_PRODUCT_LIST, GET_PRODUCT_Types } from "../../GraphQL/Queries";
import { TOKEN_VERIFY_MUTATION, TOKEN_REFRESH_MUTATION, CHECKOUT_ADD_PROMO_CODE, CREATE_CHECKOUT_MUTATION, CHECKOUT_CUSTOMER_ATTACH } from "../../GraphQL/Mutation";
import './header.css'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdRemoveShoppingCart } from 'react-icons/md';
import axios from "axios";
import { BsArrowClockwise } from "react-icons/bs";

const StickyHeader = () => {
  const [showCart, setShowCart] = useState(false)
  const [searchName, setSearchName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [savingAmount, setSavingAmount] = useState([]);
  const [cartData, setCartData] = useState([])
  const navigate = useNavigate();
  const [tokenverify] = useMutation(TOKEN_VERIFY_MUTATION);
  const [tokenrefresh] = useMutation(TOKEN_REFRESH_MUTATION);
  const [addPromoCode] = useMutation(CHECKOUT_ADD_PROMO_CODE)
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [showSearch, setShowSearch] = useState(false)
  const [recentSearch, setRecentSearch] = useState([])
  const [showRecentList, setShowRecentList] = useState([])
  const [suggestionProduct, setSuggestionProduct] = useState([])
  const [suggestionProductType, setSuggestionProductType] = useState([])
  const [suggestionBrand, setSuggestionBrand] = useState([])
  const [quantity, setQuantity] = useState(0);
  const [cartProduct, setCartProduct] = useState([])
  const [cartAmount, setCartAmount] = useState(0)
  const [cursor, setCursor] = useState(-1);
  const sugOutLickHide = useRef(null);
  const [wishlistquantity, setWishlistQuantity] = useState("");



  const [customerAttach] = useMutation(CHECKOUT_CUSTOMER_ATTACH, {
    context: {
      headers: {
        Authorization: "JWT " + localStorage.getItem("token"),
      },
    },
  });


  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
      setWishlistQuantity(localStorage.getItem("wishlistToken").split(",").length);
    }
    let cemail = "guest@hegmakart.com"
    if (loggedInUser) {
      setIsLoggedIn(true);
      tokenverify({
        variables: {
          token: localStorage.getItem("token")
        },
        onCompleted: data => {
          if (!data.tokenVerify.isValid) {
            tokenrefresh({
              variables: {
                refreshToken: localStorage.getItem("refreshToken")
              },
              onCompleted: data => {
                //console.log(data);
                if (data.tokenRefresh.token === null) {
                  localStorage.clear()
                  setTimeout(() => {
                    window.location.reload(false)
                  }, 1);
                } else {
                  localStorage.setItem('token', data.tokenRefresh.token);
                }
              }
            });
          }
        }
      });
      cemail = localStorage.getItem("email")
    }

    if (localStorage.getItem("cartToken") === null) {
      CreateCheckout({
        variables: {
          channel: "default-channel",
          email: cemail,
          lines: [],
        },
        onCompleted: (data) => {
          console.log(data);
          if (data.checkoutCreate.checkout !== null) {

            localStorage.setItem("cartToken", data.checkoutCreate.checkout.token);
            localStorage.setItem('dummyemail', '1');
            if (localStorage.getItem("user") !== null && localStorage.getItem("cartToken") !== null) {
              customerAttach({
                variables: {
                  token: localStorage.getItem('cartToken')
                },
                onCompleted: data => {
                  // console.log(data)
                }
              })
            }
          }

        },
      });
    }

    

  }, [localStorage.getItem("wishlistToken")?.split(",").length]);

  useEffect(() => {
    setRecentSearch(JSON.parse(localStorage.getItem("recentSearch")))
  }, [])
  useEffect(() => {
    window.addEventListener("mousedown", clickOutside);
    return () => {
      window.removeEventListener("mousedown", clickOutside)
    }
  }, [])

  const clickOutside = (e) => {
    if (sugOutLickHide.current && !sugOutLickHide.current.contains(e.target)) {
      setSuggestionProduct(""), setSuggestionProductType(""), setShowRecentList('')
    }
  }
  const username = localStorage.getItem("userName");
  const logoutHandeler = () => {
    //localStorage.clear();
    localStorage.removeItem('dummyemail')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userName')
    localStorage.removeItem('user')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    localStorage.removeItem('recentData')
    localStorage.removeItem('rzp_checkout_anon_id')
    localStorage.removeItem('rzp_device_id')
    localStorage.removeItem('wishlistToken')
    localStorage.removeItem('tempEmail')
    setTimeout(() => {
      window.location.reload(false)
    }, 1);

  };

  const [categoriess, setCategoriess] = useState([]);
  const { } = useQuery(GET_CATEGORIES_SUBLIST, {
    variables: {
      first: 10,
      level: 0,
    },
    onCompleted: (data) => {
      //console.log(data);
      setCategoriess(data.categories.edges);
    },
  });


  const { refetch: refetch3 } = useQuery(GET_PRODUCT_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      first: 5,
      channel: "default-channel",
      sortBy: {
        direction: "ASC",
        field: "PRIORITY",
      },
      filter: {
        search: searchName
      }
    },
    onCompleted: (data) => {
      // console.log("setSuggestionProduct",data)
      setSuggestionProduct(data.products.edges);
    },
  });

  const { refetch: refetch4 } = useQuery(GET_PRODUCT_Types, {
    fetchPolicy: "no-cache",
    variables: {
      first: 5,
      channel: "default-channel",
      filter: {
        search: searchName
      }
    },
    onCompleted: (data) => {
      // console.log("gplProductDatasetSuggestionProductType ", data)
      setSuggestionProductType(data.productTypes.edges.filter((data, i, arr) => !data.node.name.toLowerCase().includes("variant")));
    },
  });

  // let productTypes =suggestionProductType.filter((data,i,arr)=> !data.node.name.match("variant"))

  // console.log(localStorage.getItem("user") !== null && localStorage.getItem("cartToken") !== null,"CHECKCHECKCHECK888888888888888888",localStorage.getItem("user") !== null?"true":"false")
  const { refetch: refetch1, loading } = useQuery(GET_CHECKOUT_BY_TOKEN, {
    context: {
      headers: {
        Authorization: localStorage.getItem("user") !== null ? "JWT " + localStorage.getItem("token") : "",
      },
    },
    variables: {
      token: localStorage.getItem("cartToken"),
    },
    onCompleted: (data) => {
      try {
        let id = data.checkout.id
        setQuantity(data.checkout.lines.length);
        setCartProduct(data.checkout.lines)
        setCartData(data.checkout)
        setCartAmount(data.checkout.totalPrice.gross.amount)
        if (data.checkout.discount.amount === 0) {
          localStorage.removeItem('dummycoupon');
        }
      } catch (error) {
        console.log("error", error)
        // localStorage.removeItem("cartToken")
      }

      // for (let index = 0; index < data.checkout.lines.length; index++) {
      //   setSavingAmount(savingAmount => [...savingAmount, (data.checkout.lines[index].variant.pricing.priceUndiscounted.net.amount - data.checkout.lines[index].variant.pricing.price.net.amount) * data.checkout.lines[index].quantity])
      //   let varId = data.checkout.lines[index].variant.id
      //   var options = {
      //     method: 'post',
      //     url: 'https://wsi.rocketinpocket.com/hegma/v1/hegma/bulkDiscounts',
      //     data: JSON.stringify({
      //       'variant_id': varId
      //     }),
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //     }
      //   };
      //   axios(options)
      //     .then((response) => {
      //       let res = response.data
      //       console.log(res.data)
      //       let vouCode
      //       let qua = 0
      //       for (let index1 = 0; index1 < res.data.vouchers.edges.length; index1++) {
      //         if (data.checkout.lines[index].quantity >= res.data.vouchers.edges[index1].node.minCheckoutItemsQuantity) {
      //           if (res.data.vouchers.edges[index1].node.minCheckoutItemsQuantity > qua) {
      //             qua = res.data.vouchers.edges[index1].node.minCheckoutItemsQuantity
      //             vouCode = res.data.vouchers.edges[index1].node.code
      //           }
      //         }
      //       }
      //       addPromoCode({
      //         variables: {
      //           token: localStorage.getItem('cartToken'),
      //           promoCode: vouCode
      //         },
      //         onCompleted: data => {
      //           console.log(data)
      //           localStorage.setItem('dummycoupon', '1');
      //         }
      //       })
      //     })
      //     .catch((error) => {
      //       console.log(error)
      //       errorMessage("There is some technical error.");
      //     })
      // }
    },
  });

  const searchProduct = (e) => {
    setSearchName(" ")
    e.preventDefault();
    if (searchName !== "") {
      let sea = searchName.replaceAll('%', ' ').replaceAll('/', ' ').replaceAll('\\', ' ')
      navigate("/search/" + sea);
      setSearchLocal()
    };
    var options = {
      method: 'post',
      url: 'https://wsi.rocketinpocket.com/hegma/v1/hegma/saveSearchTerms',
      data: JSON.stringify({
        'email': localStorage.getItem("user") === null ? "" : localStorage.getItem("user"),
        'search_term': searchName
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    axios(options).then((response) => {
      console.log(response)
      setSearchName("")
    }).catch((error) => {
      console.log(error)
    })


  };

  const brand = () => {
    var options = {
      method: "post",
      url: "https://" + window.location.hostname + "/api/v1/productBrandList/",
      data: JSON.stringify({
        brand_name: searchName
      }),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(options)
      .then((response) => {
        console.log(response.data);
        setSuggestionBrand([])
        var response1 = response.data;
        response1 = response1.replaceAll("'", "").replaceAll("(", "").replaceAll(")", "");
        const arr1 = response1.split(",");
        for (let index = 0; index < arr1.length - 1; index++) {
          setSuggestionBrand(suggestionBrand => [...suggestionBrand, arr1[index]])
        }

      })
      .catch((error) => {
        setSuggestionBrand([])
        // var response1 = "('Suraksha',)('Suraksha',)('Suraksha',)('Suraksha',)('DispoVan',)";
        // response1 = response1.replaceAll("'", "").replaceAll("(", "").replaceAll(")", "");
        // const arr1 = response1.split(",");
        // for (let index = 0; index < arr1.length; index++) {
        //   setSuggestionBrand(suggestionBrand => [...suggestionBrand, arr1[index]])
        // }
        console.log(error);
      });
  }


  const setSearchLocal = () => {
    let searchNameTrim = searchName.trim()
    // console.log("SearchNameTrim",searchNameTrim)
    if (localStorage.getItem("recentSearch") === null) {
      localStorage.setItem("recentSearch", JSON.stringify([searchNameTrim]))
    } else {
      if (!localStorage.getItem("recentSearch").includes(searchNameTrim)) {
        let array = JSON.parse(localStorage.getItem("recentSearch"))
        array.push(searchNameTrim)
        localStorage.setItem("recentSearch", JSON.stringify(array))
      }
    }

  }

  useEffect(() => {
    showRecentSearch();
    brand();
  }, [searchName])
  const showRecentSearch = () => {
    let typeText = recentSearch?.filter((data) => data.toLowerCase().includes(searchName.toLowerCase()))

    setShowRecentList(typeText ? typeText : "")


  }
  // const keyboardArrow = (e) => {
  //   if (e.key === "ArrowDown") {
  //     console.log("ArrowDown")
  //     setCursor(prevState =>
  //       prevState < showRecentList.length - 1 ? prevState + 1 : prevState
  //     );
  //   }
  //   if (e.key === "ArrowUp") {
  //     console.log("ArrowUp")
  //     setCursor(prevState =>
  //       prevState > 0 ? prevState - 1 : prevState
  //     );
  //   }
  //   if (e.key === "Enter") {
  //     console.log("Enter")
  //     setCursor(showRecentList[cursor])
  //   }
  // }
  //console.log("datacursor", cursor)
  const PageRefreshHandeler = () => {
    setTimeout(() => {
      window.location.reload(false)
    }, 1);
  }
  //console.log("SuggestionProductType",suggestionProductType)

  const keyboardEnter = (e) => {
    if (e.key === "Enter") {
      searchProduct(e);
    }
  }
  refetch1()
  refetch3()
  refetch4()

  return (
    <>
      <div className=" sticky-header"  >
        <div className="container align-items-center height" ref={sugOutLickHide}>
          <div className="header-left col-lg-2 w-auto pl-0">

            <Link to="/">
              <span className="logo">
                <img
                  onClick={PageRefreshHandeler}
                  src="https://hegmadental.s3.ap-south-1.amazonaws.com/static/logoHegma.png"
                  alt="Logo"
                />
              </span>
            </Link>
          </div>
          {/* <!-- End .header-left --> */}



          <div className="container shopByCategory">
            <nav className="navbar">
              <ul className="">
                <li className="">
                  Shop By Category
                  <ul style={{ marginTop: 12 }}>
                    {categoriess.map((values, indx) => {
                      //console.log(values)
                      return (
                        <>
                          <Link to={`/category/${values.node.slug}`}>
                            <li key={values.node.id}>
                              {values.node.name}
                              <MdOutlineKeyboardArrowRight
                                style={{ fontSize: "15px" }}
                              />

                              <ul style={{ height: 1000, backgroundColor: "transparent" }} className="">
                                {values.node.children.edges.map(
                                  (value, index) => {
                                    return (
                                      <Link
                                        to={`/category/${values.node.slug}/${value.node.slug}`}
                                      >
                                        <li style={{ backgroundColor: "white" }} key={index}>
                                          {value.node.name}
                                        </li> </Link>
                                    );
                                  }
                                )}
                              </ul>
                              {/* END .submenu */}

                              {/* END .col-lg-4 */}

                              {/* END .col-lg-4 */}

                              {/* END .row */}

                              {/* <!-- End .megamenu --> */}
                            </li> </Link>
                        </>
                      );
                    })}
                  </ul>
                  <MdKeyboardArrowDown style={{ fontSize: "15px" }} />
                </li>
              </ul>
            </nav>
          </div>

          {/* Search Bar Start */}
          <div className="searchMainContainer position-relative">
            <form method="get" autoComplete="off" action="#" className={`searchBar-Container d-flex align-items-center    px-4 py-1  ${!showSearch ? "showSearch" : null} ${!isLoggedIn ? "mr-105" : ""}`} >
              <input className=" me-2 mb-0 border-0 focusNone  fs-3 py-2" id="myInput" type="search" placeholder="Search..." aria-label="Search"
                onChange={({ target }) => setSearchName(target.value)}
                required style={{ width: "100%", fontSize: 15 }} autoComplete="off" onKeyDown={e => keyboardEnter(e)} />
              {/* search bar Suggestion start */}
              {searchName.trim().length > 2 ?
                showRecentList.length !== 0 || suggestionProduct.length !== 0 || suggestionProductType.length !== 0 || suggestionBrand.length !== 0 ?
                  <div>
                    <div className="sugContainer">
                      {showRecentList.length !== 0 ?
                        <>  <div className="">
                          <div className="productSeeAllCon ">
                            <span className="inProducts pb-1">Recent Search</span>
                            {/* <span className="s4eeAll">See All</span> */}
                          </div><div className=" pt-1">
                            {showRecentList?.map((data, i) => (
                              i < 5 && <div onClick={()=>setSearchName("")} key={i} className={`d-flex align-items-center pb-1 item py-1 pl-2  ${cursor === i ? "item" : ""}`}>
                                <span><BsArrowClockwise style={{ color: "gray", fontSize: 15 }} /></span>
                                <Link to={`/search/${encodeURIComponent(data)}`}><p className="mb-0 clockText">{data}</p></Link>
                              </div>
                            ))}
                          </div>
                        </div>
                        </> : null}

                      {/* IN Products */}
                      {suggestionProduct.length !== 0 ? <div className="pt-2">
                        <div className=" productSeeAllCon ">
                          <span className="inProducts">In Products</span>
                          {/* <span className="seeAll">See All</span> */}
                        </div>
                        <div className="">
                          {suggestionProduct?.map((data, i) => {
                            // console.log("gplProductDatasetSuggestionProductType ", data.node.media.length)
                            return (
                              <Link to={`/product/${encodeURIComponent(data.node.slug)}`}>
                                <div onClick={() => setSearchName('')} key={i} className="suggestionImgCon item px-2 pb-2">
                                  {data.node.media.length !== 0 ? <img src={data.node.media[0].url} alt="" className="suggestionImg" /> : null}
                                  <div className="productNameSug">
                                    <span className="productCleanText">{data.node.category.name}</span>
                                    <h6 className="mb-0 productNameTextSug">{data.node.name}</h6>
                                  </div>
                                </div>
                              </Link>
                            )
                          })}
                        </div>
                      </div> : null}
                      {/* In Product Types */}
                      {suggestionProductType.length !== 0 ? <div className="">
                        <div className="productSeeAllCon  pt-2 ">
                          {/* <span className="inProducts">In Products Types</span> */}
                          {/* <span className="seeAll">See All</span> */}
                        </div>
                        <div className="py-1">
                          {suggestionProductType.map((data) => (
                            <Link to={`/productType/${data.node.name}`} onClick={()=>setSearchName("")}><span className="productTypeText item py-1 pl-2">{data.node.name}</span></Link>
                          ))}
                        </div>
                      </div> : null}

                      {/* In Product Brand */}
                      {suggestionBrand.length !== 0 ? <div className="">
                        <div className="productSeeAllCon  pt-2 ">
                          <span className="inProducts">Brands</span>
                          {/* <span className="seeAll">See All</span> */}
                        </div>
                        <div className="py-1">
                          {suggestionBrand.map((data) => (
                            <Link to={`/brand/${data}`} onClick={()=>setSearchName("")}><span className="productTypeText item py-1 pl-2">{data}</span></Link>
                          ))}
                        </div>
                      </div> : null}

                    </div>
                  </div>
                  : null
                : null}
              {/* search bar Suggestion End */}
              <div><button
                className="btn icon-magnifier pb-1 p-0 searchIcon-toggles"
                id="myBtn"
                title="Search"
                onClick={(e) => searchProduct(e)}
              >
              </button></div>
            </form>
            <a
              className="searchbar-toggles"
              role="button"
              title="Search"
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className="icon-search-3" style={{ fontSize: 22 }}></i>
            </a>
          </div>
          {/* Search Bar End */}




          <div className="header-right w-lg-max ml-0">
            {/* <!-- End .header-search --> */}
            <div className="header-contact d-none d-lg-flex pl-4 ml-3 mr-xl-5">
              <nav className="main-nav w-100">
                <ul className="menu">
                  <li className="active"></li>
                </ul>
              </nav>
            </div>
            {!isLoggedIn && <Link to="/login">
              <span className="header-icon d-inline-block px-3" title="Login">
                <i className="icon-user-2 fs-1 phone"></i>
              </span>
            </Link>}

            {isLoggedIn && <div style={{ maxWidth: "95%" }} className="container">
              <nav className="user-navbar">
                <ul className="">
                  <li className="user-drop-down">
                    Hi, {username === ' ' ? localStorage.getItem("email").substring(0, 10) : username.substring(0, 10) + '..'}
                    <ul style={{ width: 150, position: "absolute", top: 5, left: "-10px" }} className="user-drop-down-list">
                      <Link to="/Dashboard"> <li style={{ width: 150 }}> Dashboard  </li></Link>

                      <Link to="/" onClick={logoutHandeler}><li style={{ width: 150 }}> Logout </li></Link>
                    </ul>
                    <MdKeyboardArrowDown style={{ fontSize: "15px" }} />
                  </li>
                </ul>
              </nav>
            </div>}

            <Link to="/wishlist">
              {" "}
              <span
                // onClick={PageRefreshHandeler}
                className="header-icon d-inline-block px-4"
                title="Wishlist"
              >
                {/* {wishlistquantity === 0? <i  className="icon-wishlist-2"></i>:<i class="fas fa-heart text-danger"></i>} */}
                <i className={`icon-wishlist-2 ${wishlistquantity > 0 ? "redWishlistIcon" : "blackWishlistIcon"}`}></i>

                <span className="cart-count wishlist-badge-circle">{wishlistquantity}</span>

              </span>
            </Link>
            <div className="dropdown cart-dropdown">
              <a
                onClick={() => { setShowCart(true) }}
              >
                <i style={{ cursor: "pointer" }} className="minicart-icon"></i>
                <span style={{ top: -3, right: -4, cursor: "pointer" }} className="cart-count badge-circle">{quantity}</span>
              </a>



              {showCart && <><div onClick={() => { setShowCart(false) }} className="sideBarCartOverlay"></div>
                <div className="sideBarCart">
                  <Link to="#" title="Close (Esc)" className="sideBarCartCloseButton" onClick={() => { setShowCart(false) }}>
                    ×
                  </Link>

                  {cartProduct.length === 0 ? <div className='emptyWishlist'>
                    <MdRemoveShoppingCart className='brokencart'></MdRemoveShoppingCart>
                    <h3>You Don't Have Any Items In Your Cart</h3>
                    <Link to="/" onClick={() => { setShowCart(false) }}>Go Shop</Link>
                  </div> : <div className="dropdownmenu-wrapper custom-scrollbar">
                    <div style={{ fontWeight: 700 }} className="dropdown-cart-header">Shopping Cart</div>
                    {/* <!-- End .dropdown-cart-header --> */}

                    <div className="dropdown-cart-products"></div>
                    {/* <!-- End .cart-product --> */}
                    <div style={{ display: "grid", gridRowGap: 20 }}>

                      {cartProduct.map((cart) => (
                        <tbody style={{ paddingBottom: 10, borderBottom: "1px solid #e6ebee" }}>
                          <tr style={{ display: "flex", flexDirection: "column" }} class="product-row">
                            <div>
                              <td>
                                <figure class="product-image-container">
                                  <Link to={{ pathname: `/product/${encodeURIComponent(cart.variant.product.slug)}` }} class="product-image">
                                    <img style={{ maxWidth: 50, height: 50 }} src={cart.variant.product.media[0].url} alt="product" />
                                  </Link>


                                </figure>
                              </td>
                              <td class="product-col">
                                <h5 style={{ paddingLeft: 10 }} class="product-title">
                                  <Link to={{ pathname: `/product/${encodeURIComponent(cart.variant.product.slug)}` }}>{cart.variant.name}</Link>
                                </h5>
                              </td>

                            </div>



                            <div style={{ paddingTop: 8, display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontSize: "1.3rem", fontWeight: 600 }} class="cart-product-info">
                                <span class="cart-product-qty">{cart.quantity}</span> × {cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? <span class="new-price" style={{paddingRight:5}}>₹{totalValue(cart.variant.pricing.price.net.amount)}</span> : <span class="new-price" style={{paddingRight:5}}>₹{totalValue(cart.variant.pricing.price.net.amount - cart.variant.weightedShipping)}</span>}{cart.variant.originalPrice !== cart.variant.pricing.price.net.amount ? <span class="old-price">₹{cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? totalValue(cart.variant.originalPrice) : totalValue(cart.variant.originalPrice - cart.variant.weightedShipping)}</span> : null}
                              </span>
                              {/* <span style={{float:'right'}}>₹4545</span> */}
                              <td style={{ fontWeight: "bold", fontSize: 13 }}>₹{cart.variant.weightedShipping === null || cart.variant.weightedShipping === 0 ? totalValue(cart.variant.pricing.price.net.amount * cart.quantity) : totalValue((cart.variant.pricing.price.net.amount - cart.variant.weightedShipping) * cart.quantity)}</td>
                            </div>
                            {
                            cart?.variant?.quantityAvailable !== 0?null:  
                            <p className='text-danger mb-0' style={{fontSize:11}}>Out of stock. Will be removed on checkout</p>		
                      }
						{
							
							cart?.variant?.quantityAvailable !== 0?	cart?.quantity > cart?.variant?.quantityAvailable ? <p style={{fontSize:11}} className='text-danger mb-0'> Only {cart?.variant?.quantityAvailable} available. Quantity will be updated on checkout</p> : null:null
	 	
						}
                          </tr>


                        </tbody>
                      ))}

                    </div>

                    {/* <div style={{marginTop:30,fontSize:"1.3rem", fontWeight:600}} class="discount price">
                                        <span>Subtotal:</span>

                                        <span class="cart-total-discount float-right">₹{totalValue (cartProduct.reduce((total, item)=>  total+(item.variant.weightedShipping===null || item.variant.weightedShipping===0?item.variant.pricing.price.net.amount*item.quantity:(item.variant.pricing.price.net.amount-item.variant.weightedShipping)*item.quantity),0)) }</span>
                                    </div> */}
                    <div style={{ marginTop: 30, fontSize: "1.3rem", fontWeight: 600 }} class="discount price">
                      <span>Total Saving:</span>
                      <span class="cart-total-discount float-right">₹{totalValue(cartProduct.reduce((total, item) => total + ((item.variant.originalPrice - item.variant.pricing.price.net.amount) * item.quantity), 0))}</span>
                    </div>
                    <div style={{ fontSize: "1.3rem", fontWeight: 600 }} class="discount price">
                      <span>Weighted Delivery Charges:</span>

                      <span class="cart-total-discount float-right">₹{totalValue(cartProduct.reduce((total, item) => total + (item.variant.weightedShipping * item.quantity), 0))}</span>
                    </div>

                    {/* <div style={{fontSize:"1.3rem", fontWeight:600}} class="dropdown-cart-subtotal">
                                        <span>GST:</span>

                                        <span class="cart-subtotal-price float-right">₹{cartData.totalPrice.tax.amount}</span>
                                    </div> */}

                    {/* <div style={{fontSize:"1.3rem", fontWeight:600}} class="dropdown-cart-subtotal">
                                        <span>Shipping:</span>

                                        <span class="cart-subtotal-price float-right">₹{cartData.shippingPrice.net.amount}</span>
                                    </div> */}





                    <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 30, fontSize: "1.8rem" }} class="order-total">
                      <span style={{ paddingLeft: 0, marginBottom: 20 }}>
                        <h6 style={{ fontSize: "1.8rem", marginBottom: 0 }}>Grand Total</h6>
                        <p style={{ fontSize: "1.2rem" }}>(inclusive of all taxes)</p>
                      </span>
                      <span style={{ paddingRight: 0 }}>
                        <b class="total-price"><span>₹{totalValue(cartData.totalPrice.gross.amount)}</span></b>
                      </span>
                    </div>

                    {/* <!-- End .dropdown-cart-total --> */}



                    <div class="dropdown-cart-action">
                      <Link to="/cart" class="btn btn-gray btn-block view-cart">View
                        Cart</Link>
                      <Link to="/checkout" class="btn btn-dark btn-block">Checkout</Link>
                    </div>
                    {/* <!-- End .dropdown-cart-total --> */}
                  </div>}
                  {/* <!-- End .dropdownmenu-wrapper --> */}
                </div>
              </>}
              {/* <!-- End .dropdown-menu --> */}
            </div>
            {/* <!-- End .dropdown --> */}
          </div>
          {/* <!-- End .header-right --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
    </>
  );
};

export default StickyHeader;
