import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { SAVED_ADDRESSES } from "../GraphQL/Queries";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_ADDRESS_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_ADDRESS_DELETE } from "../GraphQL/Mutation";
import { SAVED_ORDERS } from "../GraphQL/Queries";
import { PASSWORD_CHANGE_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_UPDATE_MUTATION, CREATE_CHECKOUT_MUTATION, CHECKOUT_ADD_PRODUCT_LINE_MUTATION } from "../GraphQL/Mutation";
import "./Dashboard.css";
import axios from "axios";
import validator from 'validator'
import { Navigate } from 'react-router-dom'

import { BsTrash } from 'react-icons/bs'
import { Helmet } from "react-helmet";
const AccountDetail = () => {
  const [addAddressLoader, setAddAddressLoader] = useState("SAVE ADDRESS")
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [Address, setAddress] = useState([]);
  const [addressDeleteId, setAddressDeleteId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [changedFirstName, setChangedFirstName] = useState();
  const [changedLastName, setChangedLastName] = useState();
  const [eMail, setEmail] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [cityArea, setCityArea] = useState();
  const [cityName, setCityName] = useState();
  const [stateName, setStateName] = useState();
  const [pincode, setPincode] = useState();
  const [phone, setPhone] = useState();
  const [order, setOrders] = useState([]);
  const [errorMe, errorMessage] = useState([]);
  const [errorMMe, errorMMessage] = useState([]);
  const [errorEMe, errorEMessage] = useState([]);
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
  const [userFirstName, setUserFirstName] = useState();
  const [userLastName, setUserLastName] = useState();
  const [userEmail, setUserEmail] = useState();
  const username = localStorage.getItem("userName");
  const [showPasswordChangeInterface, setShowPasswordChangeInterface] = useState(false)
  const [tempInterface, setTempInterface] = useState(true)
  const email = localStorage.getItem("user");
  const [changePasswordLoader, setChangePasswordLoader] = useState("save changes")

  const logoutHandeler = () => {
    //localStorage.clear();
    localStorage.removeItem('dummyemail')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userName')
    localStorage.removeItem('user')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    localStorage.removeItem('recentData')
    localStorage.removeItem('rzp_checkout_anon_id')
    localStorage.removeItem('rzp_device_id')
  };

  let navigate = useNavigate()

  useEffect(() => {
    const loggedInUserByOtp = localStorage.getItem("loginByOtp");
    if (loggedInUserByOtp) {
      setShowPasswordChangeInterface(true)
    }
    const tempEmail = localStorage.getItem("tempEmail");
    if (tempEmail) {
      setTempInterface(false)
    }
  }, []);



  const [accountUpdateM] = useMutation(ACCOUNT_UPDATE_MUTATION, {
    context: {
      headers: {
        Authorization: "JWT " + localStorage.getItem("token"),
      },
    },
  });



  const changeNameHandeler = (e) => {
    e.preventDefault();

    if (!validator.isEmail(userEmail)) {
      errorMMessage("")
      errorMessage("")
      errorEMessage("Please enter a valid email id");
      return false;
    }

    let phone1 = '' + phone
    let firstChar = phone1.substring(0, 1)
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(userEmail)) {
      errorMMessage("")
      errorMessage("")
      errorEMessage("Please enter a valid email id");
      return false;
    }
    if (!(phone1.match('[0-9]{10}') && firstChar > 5 && phone1.length == 10)) {
      errorEMessage("")
      errorMessage("")
      errorMMessage("Please enter a valid mobile number");
      return false;
    }
    var options = {
      method: 'post',
      url: "https://" + window.location.hostname + "/api/v1/updateUserDetails/",
      data: JSON.stringify({
        'first_name': userFirstName,
        'last_name': userLastName,
        'email': userEmail,
        'mobile': '' + phone,
        'token': localStorage.getItem('token')
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    axios(options)
      .then((response) => {
        console.log(response.data);
        if (response.data !== '') {
          if (localStorage.getItem('email') !== userEmail) {
            if (response.data == 'invalid email') {
              errorEMessage("")
              errorMMessage("")
              errorMessage("This email is registered with another user");
            } else {
              localStorage.clear()
              navigate('/login')
            }
          } else {
            localStorage.setItem('userName', changedFirstName + " " + changedLastName);
            window.location.reload(false)
          }
        }
      })
      .catch((error) => {
          console.log(error)
        errorEMessage("")
        errorMMessage("")
        errorMessage("There is some technical error.");
      })

  };

  const { loading } = useQuery(SAVED_ADDRESSES, {
    fetchPolicy: "no-cache",
    context: {
      headers: {
        Authorization: "JWT " + localStorage.getItem("token"),
      },
    },
    onCompleted: (data) => {
      console.log(data)
      localStorage.setItem('userName', data.me.firstName + " " + data.me.lastName);
      setUserFirstName(data.me.firstName);
      setUserLastName(data.me.lastName)
      if (tempInterface) {
        setUserEmail(data.me.email)
      }
      setPhone(data.me.mobileNo)
    },
  });



  if (loading) {
    return (<div class="loading-overlay">
      <div class="bounce-loader">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>)

  }
  const PageRefreshHandeler = () => {
    setTimeout(() => {
      window.location.reload(false)
    }, 1);
  }

  return (
    <>
      <Helmet>
        <title>Hegmakart: My Profile</title>
        <meta name="description" content="My Profile" />
      </Helmet>
      {modalOpen && (
        <div className="ModalBackground">
          <div className="ModalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h3>Are You Sure You Want to Continue?</h3>
            </div>

            <div className="footer">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
                id="CancelBtn"
              >
                Cancel
              </button>
              <button onClick={addressDeleteHandeler}>Continue</button>
            </div>
          </div>
        </div>
      )}
      <Header />
      <main class="main">
        {/* <div class="page-header">
          <div class="container d-flex flex-column align-items-center">
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a >Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a >Shop</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    My Account
                  </li>
                </ol>
              </div>
            </nav>

            <h1>My Account</h1>
          </div>
        </div> */}

        <div class="container account-container custom-account-container">
          <div >
            <h4 className='errorBoxContent'>{errorMe}</h4>
          </div>
          <div class="row">
            <div class="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
              <h2 class="text-uppercase">My Account</h2>
              <ul class="nav nav-tabs list flex-column mb-0" role="tablist">
                <li class="nav-item">
                  <Link to="/Dashboard"
                    class="nav-link active"
                    id="dashboard-tab"
                    data-toggle="tab"
                    style={{ fontWeight: 400, color: "grey", cursor: "pointer" }}
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="true"
                  >
                    Dashboard
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/order"
                    class="nav-link"
                    id="order-tab"
                    data-toggle="tab"

                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                  >
                    Orders
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/address"
                    class="nav-link"
                    id="address-tab"
                    data-toggle="tab"

                    role="tab"
                    aria-controls="address"
                    aria-selected="false"
                  >
                    Addresses
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/account-detail"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                    style={{ fontWeight: 700, color: "grey", cursor: "pointer" }}
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Profile
                  </Link>
                </li>
                {!showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/change-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"

                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Change Password
                  </Link>
                </li>}

                {showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/set-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"

                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Set Password
                  </Link>
                </li>}

                {/* <li class="nav-item">
                  <a
                    class="nav-link"
                    id="shop-address-tab"
                    data-toggle="tab"
                    href="#shipping"
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Shipping Address
                  </a>
                </li> */}
                <li class="nav-item">
                  <Link to="/wishlist" onClick={PageRefreshHandeler} class="nav-link">
                    Wishlist
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/login" onClick={logoutHandeler} class="nav-link">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-lg-9 order-lg-last order-1 tab-content">

              {/* <!-- End .tab-pane --> */}

              <div style={{ display: "block", opacity: 1 }} class="tab-pane fade" id="edit" role="tabpanel">
                <h6>If you change email, then you have to login again</h6>
                <h3 class="account-sub-title d-none d-md-block mt-0 pt-1 ml-1">
                  <i class="icon-user-2 align-middle mr-3 pr-1"></i>Profile
                  Details
                </h3>
                <div class="account-content">
                  <div>



                    <form onSubmit={changeNameHandeler} >
                      <div class="row">
                        <div class="col-md-6 nameChange">
                          <div class="form-group">
                            <label for="acc-name">
                              First name <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={userFirstName}
                              id="acc-name"
                              name="acc-name"
                              onChange={(event) => {
                                setUserFirstName(event.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div class="col-md-6 nameChange">
                          <div class="form-group">
                            <label for="acc-lastname">
                              Last name <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              defaultValue={userLastName}

                              class="form-control"
                              id="acc-lastname"
                              name="acc-lastname"
                              onChange={(event) => {
                                setUserLastName(event.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div class="col-md-6 nameChange">
                          <div class="form-group">
                            <label for="acc-lastname">
                              Email <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              defaultValue={userEmail}

                              class="form-control"
                              id="acc-lastname"
                              name="acc-lastname"
                              onChange={(event) => {
                                setUserEmail(event.target.value);
                              }}
                              required
                            />
                          </div>
                          <h4 className='errorBoxContent' style={{ justifyContent: "left" }}>{errorEMe}</h4>
                        </div>

                        <div class="col-md-6 nameChange">
                          <div class="form-group">
                            <label for="acc-lastname">
                              Mobile Number <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              defaultValue={phone}

                              class="form-control"
                              id="acc-lastname"
                              name="acc-lastname"
                              onChange={(event) => {
                                setPhone(event.target.value);
                              }}
                              required
                            />
                          </div>
                          <h4 className='errorBoxContent' style={{ justifyContent: "left" }}>{errorMMe}</h4>
                        </div>

                      </div>
                      <div class="form-footer  ">
                        <button
                          type="submit"
                          class="btn btn-dark mr-0 nameChangeBtn"
                        >
                          Save changes
                        </button>
                      </div>
                    </form>




                  </div>
                </div>
              </div>
              {/* <!-- End .tab-pane --> */}


              {/* <!-- End .tab-pane --> */}

              {/* <div class="tab-pane fade" id="shipping" role="tabpanel">
                <div class="address account-content mt-0 pt-2">
                  <h4 class="title mb-3">Shipping Address</h4>

                  <form class="mb-2" action="#">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            First name <span class="required">*</span>
                          </label>
                          <input type="text" class="form-control" required />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            Last name <span class="required">*</span>
                          </label>
                          <input type="text" class="form-control" required />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Company </label>
                      <input type="text" class="form-control" />
                    </div>

                    <div class="select-custom">
                      <label>
                        Country <span class="required">*</span>
                      </label>
                      <select name="orderby" class="form-control">
                        <option value="" selected="selected">
                          {" "}
                          Indian Territory
                        </option>
                        <option value="1">a</option>
                        <option value="2">b</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label>
                        Street address <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="House number and street name"
                        required
                      />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Apartment, suite, unit, etc. (optional)"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label>
                        Town / City <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label>
                        State <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label>
                        Pincode <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" required />
                    </div>

                    <div class="form-footer mb-0">
                      <div class="form-footer-right">
                        <button type="submit" class="btn btn-dark py-4">
                          Save Address
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ paddingTop: "30px" }}>
                   {" "}
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="Billing address"
                  />
                   {" "}
                  <label for="Billing address">
                    Make Silling Address Your Default Address
                  </label>
                   
                </div>
              </div> */}
              {/* <!-- End .tab-pane --> */}
            </div>
            {/* <!-- End .tab-content --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* !-- End .container --> */}

        <div class="mb-5"></div>
        {/* <!-- margin --> */}
      </main>
      <Footer />
    </>
  );
};

export default AccountDetail;
