import React, { useState } from "react";
import UpperFooter from "./UpperFooter";
import LowerFooter from "./LowerFooter";
import MiddleFooter from "./MiddleFooter";
import "./Footer.css"
import MobileNavbar from "./MobileNavbar";
import { GET_MENU_LIST } from '../../GraphQL/Queries'
import { useQuery } from '@apollo/client'

const Footer = () => {
  const [menuData, setMenuData] = useState([])
  const [name, setName] = useState("");

  const { } = useQuery(GET_MENU_LIST, {
    variables: {
      first: 100,
      channel: "default-channel"
    },
    onCompleted: (data) => {
      setMenuData(data.menus.edges[0].node.items)
      setName(data.menus.edges[0].node.name)
    },
  });

  return (
    <>
      <footer>
        <div class="footer">
          <UpperFooter />

          <div style={{ maxWidth: "95%" }} class="container">
            <MiddleFooter />
            <div className="mb-3">
              <div class="col gu12 _3MmGvF">
                <div class="_1GTrm1">
                  <span class="_2oyLgr">{name}:</span>
                  {menuData.map((value) => (
                  <a
                    class="_3CuAg8"
                    href={value.url}
                  >
                    {value.name}
                  </a>
                  ))}
                </div>
              </div>
            </div>
            <LowerFooter />
          </div>
          {/* <!-- End .footer-bottom --> */}
        </div>
      </footer>

      {/* <div class="loading-overlay">
        <div class="bounce-loader">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div> */}

      <div class="mobileMenuOverlays"></div>
      {/* <!-- End .mobil-menu-overlay --> */}

      <MobileNavbar />
    </>
  );
};

export default Footer;
