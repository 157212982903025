import React,{useEffect,useState} from 'react'
import Header from '../components/Header/Header'
import "./Term&Conditions.css"
import Footer from '../components/Footer/Footer'
import { Helmet } from 'react-helmet'

const PrivacyPolicyScreen = () => {
  const bannerStyle={
    backgroundSize:'60%/cover',
    backgroundImage:`url('assets/images/elements/page-header.png')`,
backgroundRepeat:'no-repeat'

}

const containerStyle={
textAlign: 'justify',
paddingTop: '10px',
paddingBottom: '90px'
}
const [change, setChange]= useState(1)

const pageTop =()=>{
  window.scrollTo(0, 0);
}
useEffect(()=>{
  pageTop()
  setChange(5)
},[change])
  return (
    <>
	<Helmet>
<title>Hegmakart: Privacy Policy</title>
<meta name="description" content="Privacy Policy"/>
</Helmet>
      <Header/>
      <main class="main info-box-wrapper">
			{/* <div class="category-banner-container bg-gray">
				<div class="category-banner banner text-uppercase"
					style={bannerStyle}>
					<div class="container position-relative">
						<nav aria-label="breadcrumb" class="breadcrumb-nav text-black">
							<ol class="breadcrumb justify-content-center">
								<li class="breadcrumb-item"><a>Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
							</ol>
						</nav>
						<h1 class="page-title text-center text-black">Privacy Policy</h1>
					</div>
				</div>
			</div> */}

			<div class="container mt-2" style={containerStyle}>
				
                    <p>
					The privacy of our customers and site visitors are very important to us. We do not 
publish, sell or rent client details to third parties for their marketing purposes without any
explicit consent. Read this privacy policy to know more about the ways in which your 
personal information is used and protected <br/>
The privacy policies of this statement apply to our services available under the domain 
and sub-domains of hegmakart.com (the "Site") and apply generally to our parent, 
affiliates, subsidiaries or joint venture websites. By accepting the Privacy Policy and User 
Agreement in registration, you agree to disclose your personal information.<br/>
Our policies describe the information regarding regular site operations; information we 
collect from you and what may happen to that information. The policies are incorporated 
into and subject to the terms of User Agreement. The policy is effective upon acceptance 
in registration for new or all registering users

<br/><h4>Note</h4>
Our privacy policy is subject to change at any time without notice. To make sure you are 
aware of any changes, please review this page periodically. By visiting this website you 
agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree 
please do not use or access our Site
<br/><h4>1. Your Privacy</h4>
At hegmakart.com, we are extremely proud of our commitment to protect your privacy. 
We value your trust in us. We will work hard to earn your confidence so that you can 
enthusiastically use our services and recommend us to friends and family. Please read 
the following policy to understand how your personal information will be treated as you 
make full use of our Site.
<br/><h4>2. Privacy Assurance</h4>
We promise that we will not sell or rent your personal information to third parties for their
marketing purposes without your explicit consent. From time to time we may reveal 
general statistical information about our website and visitors, such as number of visitors, 
number and type of goods and services purchased, etc. Your trust and confidence are 
our highest priority.
<br/><h4>3. Information Collected</h4>
When you use our Site, we collect and store your personal information. Our primary goal 
in doing so is to provide a safe, efficient, smooth and customized experience. This allows 
us to provide services and features that most likely meet your needs and to make your 
experience safer and easier. Importantly, we only collect personal information about you 
that we consider necessary for this purpose.<br/>
In general, you can browse the Site without revealing any personal information about 
yourself. Once you give us your personal information, you are not anonymous to us. To 
fully use our Site, you will need to register using our online registration form, where you 
may be required to provide us with your name, date of birth, contact number, email id, 
user id, password, residence / place of business information, billing information, shipping 
information, bank account details and other personal information as indicated on the 
forms throughout the Site.<br/>
Where possible, we indicate which fields are required and which fields are optional. You 
always have the option to not provide information by choosing not to use a particular 
service or feature on the Site. We may automatically track certain information about you 
based upon your behavior on our site. We use this information to do internal research on 
our users' demographics, interests, and behavior to better understand, protect and serve
our users<br/>
We use data collection devices such as "cookies" on certain pages of the Site to help 
analyze our web page flow, measure promotional effectiveness, and promote trust and 
safety. "Cookies" are small files placed on your hard drive that assist us in providing our 
services. We offer certain features that are only available through the use of a "cookie". 
We also use cookies to allow you to enter your password less frequently during a session.
Cookies can also help us provide information that is targeted to your interests. You are 
always free to decline our cookies if your browser permits, although in that case you may
not be able to use certain features on the Site and you may be required to reenter your 
password more frequently during a session. For more details on the usage data collected 
please refer to our Cookie Policy.<br/>
If you choose to buy or sell on the Site, we collect information about your buying and 
selling behavior. We also collect other users' comments about you in our feedback area 
such as product review, merchant rating etc. If you are a seller, we also collect some 
additional information, such as a billing address, banking information other payment 
instrument details and tracking information from monetary transactions
If you choose to post messages on our message boards, chat rooms or other message 
areas or leave feedback for other users, we will collect that information you provide to 
us. We retain this information as necessary to resolve disputes, provide customer 
support and troubleshoot problems as permitted by law.
<br/><h4>4. Usage of your information</h4>
We use your personal information to facilitate the services you request. We use your 
personal information in the file we maintain about you, and other information we obtain 
from your current and past activities on the Site to: resolve disputes; troubleshoot 
problems; help promote safe trading; collect fees owed; measure consumer interest in 
the services provided by us, inform you about online and offline offers, products, 
services, and updates; customize your experience; detect and protect us against error, 
fraud and other criminal activity; enforce our User Agreement; and as otherwise 
described to you at the time of collection.<br/>
You agree that we may use personal information about you to improve our marketing 
and promotional efforts, to analyze site usage, improve the Site's content and product 
offerings, and customize the Site's content, layout, and services. These uses improve the 
Site and better tailor it to meet your needs, so as to provide you with a smooth, efficient, 
safe and customized experience while using the Site<br/>
By accepting the User Agreement and Privacy Policy, you expressly agree to receive this 
information. If you do not wish to receive these communications, we encourage you to 
opt out of the receipt of certain communications in your profile under My Account or by 
clicking unsubscribe any email communication. You may make changes to your profile at 
any time.
<br/><h4>5. Disclosure of your information</h4>
We may also use your information to deliver information to you that, in some cases, are 
targeted to your interests, such as new services and promotions. The following are 
examples of some categories of persons to whom we would share your information with 
from time-to-time.

<ul className='terms'>
	<li>Advertisements: Where a user enters information on any form of an advertiser, 
the information is simultaneously collected by us and the advertiser. The 
information is used in accordance with the terms of this privacy policy and is used
by the advertiser as per the advertisers' prevalent privacy policies. Because we do
not control the privacy practices of these advertisers, you should evaluate their 
practices before deciding to provide the said information.</li>
<li>Products and Services offered by third parties: There are certain items and 
services for which third parties request for information before the item/service is 
listed on the Site. The information provided by the user for such purposes is 
simultaneously collected by us and the respective third party. This information is 
utilized by the third party in accordance with its privacy policy and the terms of 
the offer. Because we do not control the privacy practices of these third parties, 
you should evaluate their practices before deciding to use their services.</li>
<li>Internal Service Providers: We may use third parties that we refer to as internal 
service providers to facilitate or outsource one or more aspects of the business, 
product and service operations that we provide to you on the Site and therefore 
we may provide some of your personal information directly to these internal 
service providers. These internal service providers' are subject to confidentiality 
agreements with us and other legal restrictions that prohibit their use of the 
information we provide them for any other purpose except to facilitate the 
specific outsourced Site related operation, unless you have explicitly agreed or 
given your prior permission to them for additional uses.</li>
<li>Other Corporate Entities: Hegmakart.com and its parent, affiliates, subsidiaries, 
joint ventures, or any combination of such, will share some or all of your personal 
information with another business entity should we plan to, merge with, or be 
acquired by that business entity. Should such a combination occur, we will require
that the new combined entity follow this privacy policy with respect to your 
personal information.</li>
<li>Legal Requests: We cooperate with law enforcement and regulatory inquiries, as 
well as other third parties to enforce laws, such as: intellectual property rights, 
fraud and other rights, to help protect you and the community from bad actors. 
Therefore, in response to a verified request by law enforcement or other 
government officials relating to a criminal investigation or alleged illegal activity, 
we can (and you authorize us to) disclose your name, city, state, telephone 
number, email address, Registered User ID history, fraud complaints, and listing 
history without a subpoena.<br/>
Further, we can (and you authorize us to) disclose your name, street address, city,
state, zip code, country, phone number, email, and company name to Intellectual 
Property Right's owners under confidentiality agreement, as we in our sole 
discretion believe necessary or appropriate in connection with an investigation of 
fraud, intellectual property infringement, piracy, or other unlawful activity.<br/>
Due to the existing regulatory environment, we cannot ensure that all of your 
private communications and other personal information will never be disclosed in 
ways not otherwise described in this Privacy Policy. Therefore, although we use 
industry standard practices to protect your privacy, we do not promise, and you 
should not expect, that your personal information or private communications will 
always remain private.
</li>
</ul>

<br/><h4>What about children's privacy?</h4>
Children are not eligible to use our Site and we ask that minors (under the age of 18) do 
not submit any personal information to us. If you are under the age of 18, you cannot 
buy or sell any items on the Site. If you wish to purchase or sell an item on the Site such 
purchase or sale may be made by your legal guardian or parents who have registered as 
users of the Site provided that such purchase or sale is not in relation to any adult 
material/mature audience items displayed on the Site, the sale or purchase of which 
to/by minors is strictly prohibited.
<br/><h4>Access or change your personally identifiable information</h4>
To protect your privacy and security, we will verify your identity before granting access 
or making changes to your personally identifying information. If you have registered your
profile on Hegmakart.com, your registered user ID and Password are required in order to 
access your profile information.
<br/><h4>Security</h4>
When you place orders or access your account information, we offer the use of a secure 
server. The secure server software (SSL) encrypts all information you put in before it is 
sent to us. Furthermore, all of the customer data we collect is protected against 
unauthorized access. To prevent unauthorized access, maintain data accuracy, and 
ensure correct use of information, we will employ reasonable security practices and 
procedures and current Internet security methods and technologies in compliance with 
Information Technology Act, 2000 and rules made there under.
For more privacy related information 




</p>
                    
			</div>
            {/* <!-- End .container --> */}

			
				
		</main>
      <Footer/>
    </>
  )
}

export default PrivacyPolicyScreen
