const MainApi=[

    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    },
    
    {
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
    }
    
    ]
    
    const recentlyView=[
        {
            
                image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
                hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
                hot:'HOT',
                discount:'-20%',
                title:'Woodpecker Apex Locator - Digital Display',
                oldPrice:'₹90.00',
                newPrice:'₹70.00'
             
        },
    
        {
            
            image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
            hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
            hot:'HOT',
            discount:'-20%',
            title:'Woodpecker Apex Locator - Digital Display',
            oldPrice:'₹90.00',
            newPrice:'₹70.00'
         
    },
    
    {
            
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
     
    },
    
    {
            
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
     
    },
    
    {
            
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
     
    },
    
    {
            
        image:"assets/images/products/Woodpecker Apex Locator - Digital Display 002.jpg",
        hoverImage:"assets/images/products/Woodpecker Apex Locator - Digital Display 004.jpg",
        hot:'HOT',
        discount:'-20%',
        title:'Woodpecker Apex Locator - Digital Display',
        oldPrice:'₹90.00',
        newPrice:'₹70.00'
     
    }
    
    ]




    const testimonialApi=[
        {
            
                image:"assets/images/testimonial1.jpeg",
              name:"Dr. Akash CL",
             review:"Providing a diverse range of products at the lowest possible price in comparison to other platforms. It has been in use for the last 2.5 years. The packaging is good, and the delivery is quick. Strongly advised."
        },
        {
            
            image:"assets/images/testimonial2.jpeg",
          name:"Dr. Amal Madni",
         review:"Excellent service. I found Hegmakart.com to be excellent in terms of pricing competitiveness, prompt delivery, and courteous staff. Continue to add new products to the catalogue."
    },
    {
            
        image:"assets/images/testimonial3.jpeg",
      name:"Dr. M. Badrinath"
      ,
         review:"Amazing products which we don’t get in a single shop, are easily available on hegmakart . it has been a good experience to shop form hegmakart as they provide reasonable prices and discounts unlike retail shops. Packaging and delivery are completely satisfactory. I would recommend every dental practitioner to shop from hegmakart"
     
},
{
            
    image:"assets/images/testimonial4.jpeg",
  name:"Dr. Mbeba",
  review:"I have been ordering products for my clinic since almost 2 yrs. and experience is really good. It’s totally hassle free and product quality is great. I am highly satisfied with the services. It makes the working at clinic so smooth. I recommend all the dentists to order the products online with Hegmakart for the best deals."
 
},
    
        
    
    ]


    
    export {MainApi,recentlyView,testimonialApi}
    
    
    
   
    
