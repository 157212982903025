import React from 'react'
import Header from '../components/Header/Header'
import "./OrderCompleted.css"
import { Link } from 'react-router-dom'
import { AiFillCheckCircle } from 'react-icons/ai';
import Footer from '../components/Footer/Footer';
import { Helmet } from 'react-helmet';

const OrderCompletedScreen = () => {
  const bannerStyle={
    backgroundSize:'60%/cover',
    backgroundImage:`url('assets/images/elements/page-header.png')`,
backgroundRepeat:'no-repeat'

}
  return (
    <div>
	<Helmet>
<title>Hegmakart: Order Completed</title>
<meta name="description" content="Order Completed"/>
</Helmet>
    <Header/>
    <main class="main info-box-wrapper">
			<div class="category-banner-container bg-gray">
				<div class="category-banner  text-uppercase"
					style={bannerStyle}>
					<div class="container position-relative">
						<nav aria-label="breadcrumb" class="breadcrumb-nav text-black">
							<ol class="breadcrumb justify-content-center">
								<li class="breadcrumb-item"><Link to="/">Home</Link></li>
								<li class="breadcrumb-item active" aria-current="page">Order Completed</li>
							</ol>
						</nav>
						<h1 class="page-title text-center text-black">Order Completed</h1>
					</div>
				</div>
			</div>

			<div class="containerrr"  >
		<AiFillCheckCircle className='orderCompleted'/>		
     <h4>Your Order Is Successfully placed. Thank you for Shopping with Us.</h4>
	 <h4>Order Details will be sent to your email and phone.</h4>        
			<h5 style={{paddingBottom:'90px',paddingTop:'20px'}}><span style={{color:"orangered",cursor:"pointer"}}></span></h5>
			</div>
		
            {/* <!-- End .container --> */}

			
				
		</main>
    <Footer/>
      
    </div>
  )
}

export default OrderCompletedScreen
