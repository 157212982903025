import React, { useState, useEffect } from 'react'
import { totalValue } from './decimalFunction';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PRODUCT_DETAILS, GET_PRODUCT_LIST, GET_CATEGORIES_BANNERS, GET_FILTERS_LIST, GET_ATTRIBUTE_LIST, COLLECTION_DETAILS, GET_PRODUCT_Types, GET_CHECKOUT_BY_TOKEN } from '../GraphQL/Queries';
import { CHECKOUT_ADD_PRODUCT_LINE_MUTATION, CREATE_CHECKOUT_MUTATION, CHECKOUT_LINE_UPDATE, UPDATE_WISHLIST_MUTATION } from '../GraphQL/Mutation';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Ratings from './Ratings';
import { Link } from 'react-router-dom';
import { Slider } from '@material-ui/core';
import "./ProductSearchScreen.css"
import "./Quickview.css"
// import "./HomeScreen.css"
import { DirectionEnum } from '../components/Enum/direction.ts';
import { FieldEnum } from '../components/Enum/field.ts';
import { ImCross } from "react-icons/im";
import { Helmet } from 'react-helmet';
import { MdOutlineSearchOff, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaBlog } from 'react-icons/fa';



const BrandSearchScreen = () => {
	const location = useLocation();
	const navigate = useNavigate()
	//console.log("location.pathname",location.pathname);
	const [sidebarToggle, setSidebarToggle] = useState(false);
	const [showCategoryList, setShowCategoryList] = useState(false)
	const [gplProductData, setGplProductData] = useState([]);
	const [gplProductData1, setGplProductData1] = useState([]);
	const [filterList, setFilterList] = useState([]);
	const [productSlugId, setProductSlugId] = useState([]);
	const [productPagingData, setProductPagingData] = useState([]);
	const [bannerr, setBanner] = useState()
	let { id } = useParams();
	let { psearchData } = useParams();
	let { productType } = useParams();
	let { cosearchData } = useParams();
	let { attsearchData } = useParams();
	const [filterSlug, setFilterSlug] = useState();
	const [collectionId, setCollectionId] = useState();
	const [attributes, setattributeList] = useState([]);
	const [categoryName, setCategoryName] = useState();
	const [categorySlug, setCategorySlug] = useState();
	const [subcategoryName, setSubCategoryName] = useState();
	const [categoryLevel, setCategoryLevel] = useState();
	const [priceinfo, setPriceInfo] = useState([])
	const [rangeVal, setRangeVal] = useState([priceinfo[0], priceinfo[priceinfo.length - 1]])
	const [selectattributes, selectattributeList] = useState([]);
	const [selectattributesdata, selectattributeDataList] = useState([]);
	const [subcategoryIdinfo, setSubCategoryIdInfo] = useState([])
	const [productSearchField, setProductSearchField] = useState(true)
	const [collectionSearchField, setCollectionSearchField] = useState(true)
	const [categorySearchField, setCategorySearchField] = useState(false)
	const [productTypeSearchField, setProductTypeSearchField] = useState(false)
	const [attributeSearchField, setAttributeSearchField] = useState(false)
	const [priceRangeMinValue, setPriceRangeMinValue] = useState(priceinfo[0])
	const [priceRangeMaxValue, setPriceRangeMaxValue] = useState(priceinfo[priceinfo.length - 1])
	const search = useLocation().search;
	let categorydata = new URLSearchParams(search).get("c");
	let attributedata = new URLSearchParams(search).get("a");
	let sortdata = new URLSearchParams(search).get("s");
	let pagingdata = new URLSearchParams(search).get("pa");
	let cursordata = new URLSearchParams(search).get("v");
	const [currentImage, setCurrentImage] = useState('assets/images/products/zoom/Woodpecker Dpex III Apex Locator 001.jpg')
	const [product, setProducts] = useState({})
	const [setattributes1, setattributeList1] = useState([]);
	const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
	const [updateWishlist] = useMutation(UPDATE_WISHLIST_MUTATION, {
		context: {
			headers: {
				Authorization: "JWT " + localStorage.getItem("token"),
			},
		},
	});
	const [variantId, setVariantId] = useState("");
	const [quickviewOpen, setQuickviewOpen] = useState(false)
	const [recData, setRecData] = useState([]);
	const [collectionName, setCollectionName] = useState("");
	const [directionSort, setDirectionSort] = useState(false)
	const [fieldSort, setFieldSort] = useState(false)
	const [fieldSort1, setFieldSort1] = useState(false)
	const [fieldSort2, setFieldSort2] = useState(false)
	const [isFirst, setIsFirst] = useState(true)
	const [after, setAfter] = useState(false)
	const [before, setBefore] = useState(false)
	const [afterData, setAfterData] = useState("");
	const [beforeData, setBeforeData] = useState("");
	const [firstCursor, setFirstCursor] = useState("");
	const [lastCursor, setLastCursor] = useState("");
	const [collapseFilter, setCollapseFilter] = useState({})
	const [quickViewId, setQuickViewId] = useState()
	const [productNotFound, setProductNotFound] = useState(false)
	const [previousId, setPreviousId] = useState()
	const [getPreviousId, setGetPreviousId] = useState()
	const [suggestionProductType, setSuggestionProductType] = useState([])
	const [purchaseNote, setPurchaseNote] = useState('')
	let attributesArrObj = [];
	const [updateLineCheckout] = useMutation(CHECKOUT_LINE_UPDATE);
	const [wishlist, setWishlist] = useState([])
	const [addwishlist, setAddWishlist] = useState([])
	const [wishHeart, setWishHeart] = useState()
	const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
	const [checkWishlist, setCheckWishlist] = useState([])
	//console.log("previusId",previousId,"productType",productType,"routesid",id,"psearchData",psearchData,"categorydata",categorydata)
	//console.log("suggestionProductType",suggestionProductType)


	const { } = useQuery(GET_CHECKOUT_BY_TOKEN, {
		variables: {
			token: localStorage.getItem('wishlistToken')
		},
		onCompleted: data => {
			for (let index = 0; index < data.checkout.lines.length; index++) {
				setCheckWishlist((pre) => [...pre, data.checkout.lines[index].variant.id])
				setWishlist((previous) => [...previous, data.checkout.lines[index].variant.id])
			}
		},
	})
	const setImage = (e) => {
		setCurrentImage(e.target.src)
	}

	if (categorydata === null) {
		categorydata = '';
	}
	if (attributedata === null) {
		attributedata = '';
	}
	if (sortdata === null) {
		sortdata = '';
	}
	if (pagingdata === null) {
		pagingdata = '';
	}
	if (cursordata === null) {
		cursordata = '';
	}
	if (attributedata !== '') {
		let valuesArr = attributedata.split(',')
		valuesArr.forEach((val) => {
			attributesArrObj.push(val)
		})
	}
	if (psearchData !== undefined) {
		if (psearchData.includes('- ')) {
			psearchData = psearchData.replace('- ', '')
		}
	}

	{ priceinfo.sort(function (a, b) { return a - b }) }

	useEffect(() => {
		const sub = id;
		setFilterSlug(sub)
		if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
			setAddWishlist([])
			let wishToken = localStorage.getItem("wishlistToken").replaceAll("[", "").replaceAll("]", "").replaceAll("'", "").replaceAll(" ", "")
			for (let index = 0; index < wishToken.split(",").length; index++) {
				setAddWishlist((addwishlist) => [...addwishlist, wishToken.split(",")[index]])
			}
		}

		if (id !== undefined) {
			setCategorySearchField(true)
			setProductTypeSearchField(false)
		}
		if (productType !== undefined) {
			setProductTypeSearchField(true)
			setProductSearchField(false)
			setCollectionSearchField(false)
			setCategorySearchField(false)
		}
		if (psearchData !== undefined) {
			setProductSearchField(true)
			setCollectionSearchField(false)
			setProductTypeSearchField(false)
			setCategorySearchField(false)
		}

		if (cosearchData !== undefined) {
			setProductSearchField(false)
			setCollectionSearchField(true)
			setProductTypeSearchField(false)
			setCategorySearchField(false)
		}
		if (attsearchData !== undefined) {
			setProductSearchField(false)
			setCollectionSearchField(false)
			setProductTypeSearchField(false)
			setCategorySearchField(false)
			setAttributeSearchField(true)
		}
		if (categorydata !== '') {
			setProductSlugId(categorydata.split(','))
			setProductSearchField(false)
			setCollectionSearchField(false)
			setCategorySearchField(true)
			setProductTypeSearchField(false)
		}
		if (attributedata !== '') {
			selectattributeDataList(attributedata.split(','))
			setProductSearchField(false)
			setCollectionSearchField(false)
			setProductTypeSearchField(false)
			setAttributeSearchField(true)
		}
		if (sortdata === 'R') {
			setDirectionSort(true)
			setFieldSort(true)
			setFieldSort1(true)
		}
		if (sortdata === 'N') {
			setDirectionSort(true)
			setFieldSort(false)
			setFieldSort1(false)
		}
		if (sortdata === 'PL') {
			setDirectionSort(false)
			setFieldSort(false)
			setFieldSort1(true)
		}
		if (sortdata === 'PH') {
			setDirectionSort(true)
			setFieldSort(false)
			setFieldSort1(true)
		}
		if (sortdata === 'PR') {
			setDirectionSort(false)
			setFieldSort(false)
			setFieldSort1(false)
			setFieldSort2(false)
		}
		if (pagingdata === 'pr') {
			setIsFirst(false)
			setAfter(false)
			setBefore(true)
			setBeforeData(cursordata)
		}
		if (pagingdata === 'ne') {
			setIsFirst(true)
			setAfter(true)
			setBefore(false)
			setAfterData(cursordata)
		}
		setRecData(JSON.parse(localStorage.getItem('recentData')));
	}, [psearchData, productType, id]);


	const { refetch: refetch1 } = useQuery(GET_CATEGORIES_BANNERS, {
		fetchPolicy: "no-cache",
		variables: {
			slug: id,
		},
		onCompleted: data => {
			//console.log("dddddddddddddddddd",data)
			if (categorydata === '') {
				setProductSlugId(data.category.id)
			} else {
				setProductSlugId(categorydata.split(','))
			}
			setSubCategoryName(data.category.name);
			setCategoryLevel(data.category.level);
			//	console.log("subcategory",subcategoryName,data)
			if (data.category.backgroundImage !== null) {
				setBanner(data.category.backgroundImage.url)
			}
			if (data.category.level !== 0) {
				setCategoryName(data.category.parent.name);
				setCategorySlug(data.category.parent.slug)

			}
		}
	})

	const { } = useQuery(GET_PRODUCT_Types, {
		fetchPolicy: "no-cache",
		variables: {
			first: 5,
			channel: "default-channel",
			filter: {
				search: productType
			}
		},
		onCompleted: (data) => {
			setSuggestionProductType([])
			//console.log(data.productTypes.edges.length,"Product type Data",data)
			for (let index = 0; index < data.productTypes.edges.length; index++) {
				setSuggestionProductType((pre) => [...pre, data.productTypes.edges[index].node.id])
			}
		},
	});
	// console.log("suggestionProductType",suggestionProductType)

	const { } = useQuery(COLLECTION_DETAILS, {
		fetchPolicy: "no-cache",
		variables: {
			id: cosearchData,
			channel: 'default-channel'
		},
		onCompleted: data => {
			setCollectionName(data.collection.name)
			setCollectionId(data.collection.id)
		}
	})
	//console.log("collectionSearchField",collectionSearchField,"productSearchField",productSearchField,"categorySearchField",categorySearchField,"attributeSearchField",attributeSearchField,"productTypeSearchField",productTypeSearchField)
	// if   if  if
	const { loading: loading1 } = useQuery(GET_PRODUCT_LIST, {
		fetchPolicy: "no-cache",
		variables: {
			first: 12,
			after: after ? afterData : "",
			channel: "default-channel",
			sortBy: {
				direction: directionSort ? DirectionEnum.DESC : DirectionEnum.ASC,
				field: fieldSort ? FieldEnum.RATING : fieldSort1 ? FieldEnum.PRICE : fieldSort2 ? FieldEnum.PUBLICATION_DATE : FieldEnum.PRIORITY
			},
			filter: {
				collections: collectionSearchField ? collectionId : [],
				search: productSearchField ? psearchData : "",
				categories: categorySearchField ? productSlugId : [],
				attributes: attributeSearchField ? [{
					slug: "manufacturer",
					values: attsearchData
				}] : [],
				productTypes: productTypeSearchField ? suggestionProductType : [],
				price: {
					gte: priceRangeMinValue,
					lte: priceRangeMaxValue
				}
			}
		},
		onCompleted: data => {
			//console.log("data",data)
			if (isFirst) {
				setGplProductData(data.products.edges);
				setProductPagingData(data.products.pageInfo);
				setFirstCursor(data.products.pageInfo.startCursor);
				setLastCursor(data.products.pageInfo.endCursor);
				for (let index = 0; index < data.products.edges.length; index++) {
					for (let index1 = 0; index1 < data.products.edges[index].node.attributes.length; index1++) {
						for (let index2 = 0; index2 < data.products.edges[index].node.attributes[index1].values.length; index2++) {
							selectattributeList(selectattributes => [...selectattributes, data.products.edges[index].node.attributes[index1].values[index2].id])
							selectattributeDataList(selectattributesdata => [...selectattributesdata, `{values:["${data.products.edges[index].node.attributes[index1].values[index2].name}"] slug:"${data.products.edges[index].node.attributes[index1].attribute.slug}"}`])
						}
					}
					setSubCategoryIdInfo(subcategoryIdinfo => [...subcategoryIdinfo, data.products.edges[index].node.category.id])
					setPriceInfo(priceinfo => [...priceinfo, data.products.edges[index].node.pricing.priceRange.start.net.amount]);
				} setPreviousId((pre) => { setGetPreviousId(pre === undefined ? id : pre); return id })
			}
		}
	})

	// else else else
	const { loading: loading2 } = useQuery(GET_PRODUCT_LIST, {
		fetchPolicy: "no-cache",
		variables: {
			last: 12,
			before: before ? beforeData : "",
			channel: "default-channel",
			sortBy: {
				direction: directionSort ? DirectionEnum.DESC : DirectionEnum.ASC,
				field: fieldSort ? FieldEnum.RATING : fieldSort1 ? FieldEnum.PRICE : fieldSort2 ? FieldEnum.PUBLICATION_DATE : FieldEnum.PRIORITY
			},
			filter: {
				collections: collectionSearchField ? collectionId : [],
				search: productSearchField ? psearchData : "",
				categories: categorySearchField ? productSlugId : [],
				attributes: attributeSearchField ? [{
					slug: "manufacturer",
					values: attsearchData
				}] : [],
				productTypes: productTypeSearchField ? suggestionProductType : [],
				price: {
					gte: priceRangeMinValue,
					lte: priceRangeMaxValue
				}
			}
		},
		onCompleted: data => {
			if (!isFirst) {
				setGplProductData(data.products.edges);
				setProductPagingData(data.products.pageInfo);
				setFirstCursor(data.products.pageInfo.startCursor);
				setLastCursor(data.products.pageInfo.endCursor);
				for (let index = 0; index < data.products.edges.length; index++) {
					for (let index1 = 0; index1 < data.products.edges[index].node.attributes.length; index1++) {
						for (let index2 = 0; index2 < data.products.edges[index].node.attributes[index1].values.length; index2++) {
							selectattributeList(selectattributes => [...selectattributes, data.products.edges[index].node.attributes[index1].values[index2].id])
							selectattributeDataList(selectattributesdata => [...selectattributesdata, `{values:["${data.products.edges[index].node.attributes[index1].values[index2].name}"] slug:"${data.products.edges[index].node.attributes[index1].attribute.slug}"}`])
						}
					}
					setSubCategoryIdInfo(subcategoryIdinfo => [...subcategoryIdinfo, data.products.edges[index].node.category.id])
					setPriceInfo(priceinfo => [...priceinfo, data.products.edges[index].node.pricing.priceRange.start.net.amount]);
				}
			}
		}
	})





	//{console.log("data.products.edges.length",gplProductData)}

	useEffect(() => {
		if (gplProductData.length === 0) {
			setProductNotFound(true)
		} else { setProductNotFound(false) }
	}, [gplProductData])


	const { } = useQuery(GET_PRODUCT_LIST, {
		variables: {
			first: 100,
			channel: "default-channel",
			filter: {
				ids: recData
			}
		},
		onCompleted: (data) => {
			//console.log("RecentData",data)
			setGplProductData1(data.products.edges);
		},
	});

	const recentlyData = []
	if (localStorage.getItem('recentData') !== null) {
		gplProductData1.map((data, index) => (
			recentlyData[(recData.indexOf(gplProductData1[index].node.id))] = gplProductData1[index]
			// for(let index = 0; index < gplProductData1.length;index++){
			// 	recentlyView[recData.indexOf(gplProductData1[index].node.id)] = gplProductData1[index]
			// }
		))
	}

	//console.log("gplProductData1",gplProductData1,"recentlyView",recentlyData,"recData",recData)

	const { loading: loading3 } = useQuery(GET_FILTERS_LIST, {
		variables: {
			first: 20,
			level: 0
		},
		onCompleted: data => {
			// console.log(data)
			setFilterList(data.categories.edges);
			if (categorydata !== '') {
				setSubCategoryIdInfo(productSlugId);
			}
		}
	})

	const { } = useQuery(GET_ATTRIBUTE_LIST, {
		variables: {
			first: 20, sortBy: {
				direction: "ASC",
				field: "NAME",
			}
		},
		onCompleted: data => {
			//console.log(data);
			setattributeList(data.attributes.edges);
		}
	})

	const onAddToCart = async (e) => {

		// setLoader(true);

		e.preventDefault();

		const { id } = e.target;

		if (localStorage.getItem("cartToken") === null) {
			CreateCheckout({
				variables: {
					channel: "default-channel",
					email: '',
					lines: [
						{
							quantity: 1,
							variantId: id,
						},
					],
				},
				onCompleted: (data) => {
					//console.log(data);
					if (data.checkoutCreate.checkout !== null) {
						localStorage.setItem("cartToken", data.checkoutCreate.checkout.token);
						// window.location.reload(false);	
						// refetch()
					}
				},
			});
		} else {
			addLineCheckout({
				variables: {
					token: localStorage.getItem("cartToken"),
					lines: [
						{
							quantity: 1,
							variantId: id,
						},
					],
				},
				onCompleted: (data) => {
					//console.log(data);
					//   setLoader(false);
					// window.location.reload(false);
					//   refetch()
				},
			});
		}
	};


	const onAddToWishlist = (e) => {

		const { id } = e.target;
		if (!wishlist.includes(id)) {
			setWishlist([...wishlist, id])
			setAddWishlist([...addwishlist, id])
			if(localStorage.getItem("wishlistToken")===null){
				localStorage.setItem("wishlistToken", JSON.stringify([id]));
			  }else{
				if(!localStorage.getItem("wishlistToken").includes(id)){
			  localStorage.setItem("wishlistToken",  JSON.stringify([
				...JSON.parse(localStorage.getItem("wishlistToken")),
				id,
			  ]));}}
			updateWishlist({
				variables: {
					input: {
						wishlistToken: [...addwishlist, id],
					},
				},
				onCompleted: (data) => {
					console.log(data);
				},
			});
		}
		setWishHeart(id === variantId ? "added-wishlist" : "")

	};

	const removeWishlist = (e) => {
		const { id } = e.target;
		if (wishlist.includes(id)) {
			let unWishlist = wishlist.filter((item) => item !== id);
			setWishlist(unWishlist)
			setAddWishlist(unWishlist)
			localStorage.setItem("wishlistToken", JSON.stringify(unWishlist));
			console.log("REMOVE TO WISHLIST")
		}

	}
	const handleCategoryFilter = (e) => {
		const { checked, id } = e.target;
		var url = window.location.href;
		// console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU",url,checked,"subcategoryIdinfo",subcategoryIdinfo)
		if (url.indexOf("?") > -1) {
			url = url.substring(0, url.indexOf("?"))
		}

		// Case 1 : The user checks the box
		if (checked) {
			url += '?c=' + encodeURIComponent([...subcategoryIdinfo, id]);
		}

		// Case 2  : The user unchecks the box
		else {
			url += '?c=' + encodeURIComponent(subcategoryIdinfo.filter((e) => e !== id));
		}

		url += '&s=' + sortdata + '&pa=' + pagingdata + '&v=' + cursordata + '&a=' + attributedata;
		window.location.href = url;
		window.location.href();
	}

	const handleAttributeFilter = (e) => {
		const { checked, id } = e.target;
		var url = window.location.href;
		if (url.indexOf("?") > -1) {
			url = url.substring(0, url.indexOf("?"))
		}
		// alert(checked)
		// Case 1 : The user checks the box
		if (checked) {
			url += '?a=' + [...selectattributesdata, id];
		}

		// Case 2  : The user unchecks the box
		else {
			url += '?a=' + selectattributesdata.filter((e) => e !== id);
		}

		url += '&s=' + sortdata + '&pa=' + pagingdata + '&v=' + cursordata + '&c=' + categorydata;
		window.location.href = url;
		window.location.href();
	}

	const handleSortFilter = (e) => {
		const { value } = e.target;
		var url = window.location.href;
		if (url.indexOf("?") > -1) {
			url = url.substring(0, url.indexOf("?"))
		}

		url += '?s=' + encodeURIComponent(value) + '&c=' + categorydata + '&pa=' + pagingdata + '&v=' + cursordata + '&a=' + attributedata;
		window.location.href = url;
		window.location.href();
	}

	const handlePagingFilter = (e) => {
		const { id } = e.target;
		var url = window.location.href;
		if (url.indexOf("?") > -1) {
			url = url.substring(0, url.indexOf("?"))
		}
		let curssor
		if (id === 'pr') {
			curssor = firstCursor
		} else {
			curssor = lastCursor
		}
		url += '?pa=' + id + '&v=' + curssor + '&s=' + sortdata + '&c=' + categorydata + '&a=' + attributedata;
		window.location.href = url;
		window.location.href();
	}

	const updateRangeVal = (e, data) => {
		setRangeVal(data)
		console.log(data)
	}


	const { loading } = useQuery(GET_PRODUCT_DETAILS, {
		fetchPolicy: "no-cache",
		variables: {
			slug: psearchData ? quickViewId : quickViewId,
			channel: "default-channel"
		},
		onCompleted: data => {
			//console.log("QuickView",data)
			setProducts(data.product)
			setattributeList1(data.product.attributes)
			setCurrentImage(data.product.modal?.[0].url)
			setVariantId(data.product.variants[0].id)

		}
	})
	//console.log("setattributeList1",setattributes1)
	if (loading) {
		return (<div class="loading-overlay">
			<div class="bounce-loader">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>)
	}
	if (loading1) {
		return (<div class="loading-overlay">
			<div class="bounce-loader">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>)
	}
	if (loading2) {
		return (<div class="loading-overlay">
			<div class="bounce-loader">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>)
	}
	if (loading3) {
		return (<div class="loading-overlay">
			<div class="bounce-loader">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
		</div>)
	}
	const sidebarOpen = () => {
		return (
			sidebarToggle ? setSidebarToggle(false) : setSidebarToggle("sidebar-opened")
		)
	}
	window.addEventListener('popstate', (event) => {
		setQuickviewOpen(false)
	});
	refetch1()


	return (

		<div >
			<Helmet>
				<title>Hegmakart: Search Products</title>
				<meta name="description" content="Search Products" />
			</Helmet>
			<div className={sidebarToggle}>
				{quickviewOpen && (<div className='modalBackground'>
					<div className='modalContainer'>
						<div className="titleCloseBtn">
							<button
								onClick={() => {
									setQuickviewOpen(false);
								}}
							>
								<span style={{ display: "inline-block", fontSize: 22, fontWeight: "600", color: "black" }}>X</span>
							</button>


						</div>
						<div class="col-lg-5 col-md-4 product-single-gallery">
							<div class="product-slider-container">
								{/* <div class="label-group">
									<div class="product-label label-hot">HOT</div>

									<div class="product-label label-sale">
										-16%
									</div>
								</div> */}

								<div class="">
									<div class="product-item">
										<img class="product-single-image"
											src={currentImage}
										/>
									</div>


								</div>
								{/* <!-- End .product-single-carousel --> */}
								<span class="prod-full-screen">
									<i class="icon-plus"></i>
								</span>
							</div>

							<div class="prod-thumbnail owl-dots">
								{product.media.map((media) => (
									<div class="owl-dot">
										<img onClick={setImage} src={media.url} width="110" height="110"
											alt="product-thumbnail" />
									</div>
								))}
							</div>
						</div>



						<div class="col-lg-7 col-md-4 product-single-details">

							<h1 class="product-title" style={{ fontSize: '20px' }}>{product.name} </h1>



							<div class="ratings-container">
								<div className="">
									<span className="" style={{ width: '100%' }}><Ratings value={product.rating} /></span>
									{/* <!-- End .ratings --> */}
									<span className="tooltiptext tooltip-top" >{product.id}</span>
								</div>
								{/* <!-- End .product-ratings --> */}

								<a class="rating-link">( {product.rating} )</a>
							</div>
							{/* <!-- End .ratings-container --> */}
							{setattributes1.map((attributes) => (
								<>
									{attributes.attribute.name === "Manufacturer" ? (
										<ul class="single-info-list">
											<li>
												MANUFACTURER:{" "}
												<strong>{attributes.values.length !== 0 ? attributes.values[0].name : ""}</strong>
											</li>
										</ul>
									) : null}
								</>
							))}
							<hr class="short-divider" />

							<div class="price-box">
								{product.pricing.priceRangeUndiscounted.start.net.amount !== product.pricing.priceRange.start.net.amount ?
									<span class="old-price">₹{totalValue(product.pricing.priceRangeUndiscounted.start.net.amount)}</span> : null}
								<span class="new-price">₹{totalValue(product.pricing.priceRange.start.net.amount)}</span>
							</div>


							{/* <div onClick={onAddToCart} style={{ paddingTop: 0 }} class="cartcontainr"><a style={{ color: "white" }} class="btn btn-dark " title="Add to Cart">Add to
							Cart</a>

						</div> */}
							<div style={{
								display: 'flex',
								flexDirection: "column"
							}}>
								{!product.productType.hasVariants ?
									<> <div style={{ paddingTop: 0, display: "flex", alignItems: "baseline" }} class="cartcontainr">
										<a onClick={onAddToCart} id={product.variants[0].id} style={{ color: "white", minWidth: "140px" }} class="btn btn-dark save-change" title="Add to Cart">
											add to cart
										</a>

										<div class="product-single-share mb-3">
											<label class="sr-only">Share:</label>

											{wishHeart !== "added-wishlist" ?
												<a style={{ cursor: "pointer" }} onClick={onAddToWishlist} class="btn-icon-wish add-wishlist" title="Add to 
											 Wishlist"><i class="icon-wishlist-2"></i><span id={product.id}>Add to Wishlist</span>
												</a>
												:
												<a style={{ cursor: "pointer" }} onClick={() => navigate("/wishlist")}
													class="btn-icon-wish add-wishlist " title="Add to 
											 Wishlist"><i class="icon-wishlist-2"></i><span id={product.id}>Browse  wishlist</span>
												</a>
											}

										</div>
									</div></>
									:
									<div style={{ paddingTop: 0 }} class="cartcontainr">

										<button onClick={() => navigate(`/product/${encodeURIComponent(product.slug)}`)} style={{ border: "none", padding: 0 }} title="Add to Cart">
											<Link to={psearchData ? `/search/${psearchData}` : `/category/${previousId}`} style={{ color: "white" }} class="btn btn-dark ">View Product Details</Link>
										</button></div>
								}
								<p style={{ color: "orangered", marginTop: 10 }}>{product.purchaseNote}</p>
							</div>


						</div>
					</div>
				</div>)}



				<Header />
				<main className="main">
					{id && <div className="category-banner-container bg-gray">
						<div className=" banner text-uppercase categoryBanner"
							style={{ backgroundImage: `url(${bannerr})`, }}>

						</div>
					</div>
					}
					<div style={{ maxWidth: "95%" }} className="container">
						<nav aria-label="breadcrumb" className="breadcrumb-nav">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/" ><i className="icon-home"></i></Link></li>
								{/* <li className="breadcrumb-item"><a >Home</a></li> */}
								{id !== undefined ?
									<>
										{categoryLevel === 1 ? <li class="breadcrumb-item" aria-current="page"><Link to={`/category/${categorySlug}`}>{categoryName}</Link></li> : null}
										<li className="breadcrumb-item active" aria-current="page">{subcategoryName}</li>
									</> :
									<>
										{psearchData !== undefined ?
											<li className="breadcrumb-item active" aria-current="page">Search Result For {psearchData}</li> :
											<li className="breadcrumb-item active" aria-current="page">{collectionName}</li>}
									</>
								}

							</ol>
						</nav>

						<div className="row" style={{ marginBottom: 100 }}>
							<div className="col-lg-9 main-content">
								<nav className="toolbox sticky-header" data-sticky-options="{'mobile': true}">
									<div className="toolbox-left">
										<a className="sidebar-toggle" onClick={sidebarOpen}>
											<svg data-name="Layer 3" id="Layer_3"
												viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
												<line x1="15" x2="26" y1="9" y2="9" className="cls-1"></line>
												<line x1="6" x2="9" y1="9" y2="9" className="cls-1"></line>
												<line x1="23" x2="26" y1="16" y2="16" className="cls-1"></line>
												<line x1="6" x2="17" y1="16" y2="16" className="cls-1"></line>
												<line x1="17" x2="26" y1="23" y2="23" className="cls-1"></line>
												<line x1="6" x2="11" y1="23" y2="23" className="cls-1"></line>
												<path
													d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z"
													className="cls-2"></path>
												<path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2"></path>
												<path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3"></path>
												<path
													d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z"
													className="cls-2"></path>
											</svg>
											<span>Filter</span>
										</a>

										<div className="toolbox-item toolbox-sort">
											<label>Sort By:</label>

											<div className="select-custom">
												<select name="orderby" className="form-control" onChange={handleSortFilter}>
													{(sortdata === null || sortdata === 'R') ?
														<option value="PR" selected="selected">Sort by relevance</option>
														: <option value="PR">Sort by relevance</option>}
													{/* {sortdata === 'PR' ?
													<option value="PR" selected="selected">Sort by Priority</option> :
													<option value="PR">Sort by Priority</option>} */}
													{sortdata === 'N' ?
														<option value="N" selected="selected">Sort by newness</option> :
														<option value="N">Sort by newness</option>}
													{sortdata === 'PL' ?
														<option value="PL" selected="selected">Sort by price: low to high</option> :
														<option value="PL">Sort by price: low to high</option>}
													{sortdata === 'PH' ?
														<option value="PH" selected="selected">Sort by price: high to low</option> :
														<option value="PH">Sort by price: high to low</option>}

												</select>
											</div>
											{/* <!-- End .select-custom --> */}


										</div>
										{/* <!-- End .toolbox-item --> */}
									</div>
									{/* <!-- End .toolbox-left --> */}

									<div className="toolbox-right">
										{/* <div className="toolbox-item toolbox-show">
										<label>Show:</label>

										<div className="select-custom">
											<select name="count" className="form-control">
												<option value="12">12</option>
												<option value="24">24</option>
												<option value="36">36</option>
											</select>
										</div>
										
									</div> */}
										{/* <!-- End .toolbox-item --> */}

										<div className="toolbox-item layout-modes">
											<a onClick={() => { setShowCategoryList(false) }} className="layout-btn btn-grid active" title="Grid">
												<i className="icon-mode-grid"></i>
											</a>
											<a onClick={() => { setShowCategoryList(true) }} className="layout-btn btn-list" title="List">
												<i className="icon-mode-list"></i>
											</a>
										</div>
										{/* <!-- End .layout-modes --> */}
									</div>
									{/* <!-- End .toolbox-right --> */}
								</nav>
								{!showCategoryList && (<div className="row">
									{gplProductData.map((value) => (
										<div className="col-6 col-sm-4 col-md-3 " key={value.node.id}>
											<div className="product-default inner-quickview inner-icon">
												<figure>

													{value.node.media[0] ?
														<>	<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>
															<img src={value.node.media[0].url} width="280" height="280"
																alt="product" />
															<img src={value.node.media[0].url} width="280" height="280"
																alt="product" />
														</Link>
														</> : null}

												</figure>

												<div className="product-details">
													<div className="category-wrap">
														<div className="category-list">
															<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>{value.node.category.name}</Link>
														</div>
													</div>

													<h3 className="product-title"><Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }} >{value.node.name}</Link></h3>

													<div className="ratings-container">
														<div className="">
															{!(value.node.rating == null || value.node.rating == 0)?
																<span className="" style={{ width: "100%" }}>
																	{value.node.rating.toString().length > 1?value.node.rating.toFixed(1):value.node.rating} <i className='fas fa-star text-warning'></i>
																</span> : null}

															<span className="tooltiptext tooltip-top" >{value.node.id}</span>
														</div>

													</div>

													{!value.node.productType.hasVariants ?
														<div className="price-box">
															<>
																{value.node.variants[0].originalPrice !== value.node.pricing.priceRange.start.net.amount ?
																	<>
																		{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice)}</span> : <>{value.node.variants[0].originalPrice !== "0.00" && totalValue(value.node.variants[0].originalPrice) !== totalValue(value.node.pricing.priceRange.start.net.amount) ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice - value.node.variants[0].weightedShipping)}</span> : null} </>}
																		{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount)}</span> : <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
																	</>
																	: <span className="product-price">₹{totalValue(value.node.weightedShipping === null || value.node.weightedShipping === 0 ? value.node.pricing.priceRange.start.net.amount : value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
															</>
														</div> :
														<div className="price-box">
															<span style={{ color: "black", fontSize: "1.8rem" }}>From </span>
															{value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity) === totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity)) ? <span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span> : <>   <strike class="product-pricee"> ₹{totalValue(value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity))}</strike>
																<span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span></>}
														</div>
													}


													<div className="product-action" style={{ textAlign: 'left' }}>
														<a onClick={wishlist.includes(value.node.id) ? removeWishlist : onAddToWishlist} class={wishlist.includes(value.node.id) ? "btn-icon-wished added-wishlists" : "btn-icon-wished"} title="wishlist">
															<i className="icon-heart" id={value.node.id}></i>
														</a>

														<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }} className="btn-icon btn-add-cart"><i
															className="fa fa-arrow-right"></i><span>SELECT
																OPTIONS</span></Link>
														{psearchData ?
															<a onClick={() => { setQuickviewOpen(true), setQuickViewId(value.node.slug) }} className="btn-quickview"
																title="Quick View"><i className="fas fa-external-link-alt"></i></a>
															:
															<a onClick={() => { setQuickviewOpen(true), setQuickViewId(value.node.slug) }} class="btn-quickview" title="Quick View">
																<i class="fas fa-external-link-alt"></i>
															</a>
														}
													</div>
												</div>

											</div>
										</div>
									))}

									{productNotFound &&
										<div className='d-flex flex-column align-items-center mx-auto'>
											<MdOutlineSearchOff style={{ fontSize: 50, color: "gray", margin: "10px 0" }} />
											<h4 className='mb-1'>Sorry, no results found!</h4>
											<h5 className='text-center px-5' style={{ fontSize: 15 }}>Please check the spelling or call us on +918553666877/977 help you with right product.</h5>
										</div>

									}




								</div>)}
								{/* row row  row  row  orw */}
								{showCategoryList && (<div class="row pb-4">
									{gplProductData.map((value) => (<div class="col-sm-12 col-6 product-default left-details product-list mb-2">
										<figure>
											<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>
												<img src={value.node.media[0].url} width="250" height="250"
													alt="product" />
												<img src={value.node.media[0].url} width="250" height="250"
													alt="product" />
											</Link>
										</figure>

										<div class="product-details">
											<div class="category-list">
												<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>{value.node.category.name}</Link>
											</div>

											<h3 class="product-title"><Link to={`/product/${encodeURIComponent(value.node.slug)}`}>{value.node.name}</Link> </h3>

											<div class="ratings-container">
												<div class="">
													<span class="" ><Ratings value={value.node.rating} /></span>
													{/* <!-- End .ratings --> */}
													<span class="tooltiptext tooltip-top">{value.node.id}</span>
												</div>
												{/* <!-- End .product-ratings --> */}
											</div>
											{/* <!-- End .product-container --> */}

											{/* <p class="product-description">Pellentesque habitant morbi tristique senectus et
										netus et malesuada fames ac turpis egestas. Vestibulum tortor
										quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit
										amet quam egestas semper. Aenean
										ultricies mi vitae est. Mauris placerat eleifend leo.
									</p> */}

											{!value.node.productType.hasVariants ?
												<div className="price-box">
													<>
														{value.node.variants[0].originalPrice !== value.node.pricing.priceRange.start.net.amount ?
															<>
																{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="old-price">₹{value.node.variants[0].originalPrice}</span> : <span className="old-price">₹{value.node.variants[0].originalPrice - value.node.variants[0].weightedShipping}</span>}
																{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="product-price">₹{value.node.pricing.priceRange.start.net.amount}</span> : <span className="product-price">₹{value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping}</span>}
															</>
															: <span className="product-price">₹{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? value.node.pricing.priceRange.start.net.amount : value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping}</span>}
													</>
												</div> :
												<div className="price-box">
													<span style={{ color: "black", fontSize: "1.8rem" }}>From </span>
													{value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity) === totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity)) ? <span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span> : <>   <strike class="product-pricee"> ₹{value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity)}</strike>
														<span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span></>}
												</div>
											}
											{/* <!-- End .price-box --> */}

											<div class="product-action">
												<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }} class="btn-icon btn-add-cart">
													<i class="fa fa-arrow-right"></i>
													<span>SELECT OPTIONS</span>
												</Link>
												<a onClick={wishlist.includes(value.node.id) ? removeWishlist : onAddToWishlist} class={wishlist.includes(value.node.id) ? "btn-icon-wished added-wishlists" : "btn-icon-wished"} title="wishlist">
													<i className="icon-heart" id={value.node.id}></i>
												</a>
												{psearchData ?
													<a onClick={() => { setQuickviewOpen(true), setQuickViewId(value.node.slug) }} className="btn-quickview"
														title="Quick View"><i className="fas fa-external-link-alt"></i></a>
													:
													<a onClick={() => { setQuickviewOpen(true), setQuickViewId(value.node.slug) }} class="btn-quickview" title="Quick View">
														<i class="fas fa-external-link-alt"></i>
													</a>
												}
											</div>
										</div>
										{/* <!-- End .product-details --> */}
									</div>))}


									{productNotFound &&
										<div className='d-flex flex-column align-items-center mx-auto'>
											<MdOutlineSearchOff style={{ fontSize: 50, color: "gray", margin: "10px 0" }} />
											<h4 className='mb-1'>Sorry, no results found!</h4>
											<h5 className='text-center px-5' style={{ fontSize: 15 }}>Please check the spelling or call us on +918553666877/977 help you with right product.</h5>
										</div>

									}



								</div>)}

								<nav className="toolbox toolbox-pagination">
									{/* <div className="toolbox-item toolbox-show">
									<label>Show:</label>

									<div className="select-custom">
										<select name="count" className="form-control">
											<option value="12">12</option>
											<option value="24">24</option>
											<option value="36">36</option>
										</select>
									</div>
									
								</div> */}
									{/* <!-- End .toolbox-item --> */}

									<ul className="pagination toolbox-item">
										{productPagingData.hasPreviousPage ?
											<li className="page-item"><a className="page-link" id='pr' onClick={handlePagingFilter} >&lt;&lt; Previous</a></li>
											: null}
										{/* <li className="page-item active">
										<a className="page-link" href="#">Current <span className="sr-only">(current)</span></a>
									</li> */}
										{productPagingData.hasNextPage ?
											<li className="page-item"><a className="page-link" id='ne' onClick={handlePagingFilter} >Next &gt;&gt;</a></li>
											: null}
									</ul>
								</nav>
							</div>
							{/* <!-- End .col-lg-9 --> */}
							{/* Filter Side */}
							<div className="sidebar-overlay"></div>
							<aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
								<div className='d-flex justify-content-end pb-3'>{sidebarToggle ? <span onClick={() => setSidebarToggle(false)}><ImCross /></span> : ""}</div>

								<div className="sidebar-wrapper">

									<div className="widget">

										<h3 className="widget-title">
											<a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true"
												aria-controls="widget-body-2">Categories</a>


										</h3>

										<div className="collapse show" id="widget-body-2">
											<div className="widget-body">
												<ul className="cat-list">
													{filterList.map((filter) => (
														<>
															{filter.node.products.totalCount > 0 ?
																<li>
																	{/* <a href="#widget-category-2" className="collapsed" data-toggle="collapse"
																	role="button" aria-expanded="false"
																	aria-controls="widget-category-2"></a> */}
																	<a className={collapseFilter[filter.node.id] ? "" : "collapsed"}
																	>
																		<Link to={`/category/${filter.node.slug}`}>{filter.node.name}</Link>
																		<span class="products-count">({filter.node.products.totalCount})</span>
																		<span className='toggle' onClick={() => setCollapseFilter((prev) => ({ ...prev, [filter.node.id]: !prev[filter.node.id] }))}></span>
																	</a>
																	{collapseFilter[filter.node.id] && <div clasName={collapseFilter[filter.node.id] ? "show" : "collapse"}>
																		<ul class="cat-sublist">
																			{filter.node.children.edges.map(subFilter => (
																				<>
																					{subFilter.node.products.totalCount > 0 ?
																						<li><span></span> <Link to={`/category/${filter.node.slug}/${subFilter.node.slug}`}>{subFilter.node.name}</Link><span class="products-count">({subFilter.node.products.totalCount})</span></li> : null}

																				</>
																			))}

																		</ul>
																	</div>}
																</li> : null}
														</>
													))}
												</ul>
											</div>
											{/* <!-- End .widget-body --> */}
										</div>
										{/* <!-- End .collapse --> */}
									</div>
									{/* <!-- End .widget --> */}
									{/* Price */}
									<div className="widget">
										<h3 className="widget-title">
											<a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true"
												aria-controls="widget-body-3">Price</a>
										</h3>

										<div className="collapse show" id="widget-body-3">
											<div className="widget-body pb-0">
												<form>
													{/* <Slider style={{color:'#F39655'}}  value={rangeVal} onChange={updateRangeVal} min={priceinfo[0]} max={priceinfo[priceinfo.length - 1]}/> */}
													{/* <!-- End .price-slider-wrapper --> */}
													<div style={{ display: "flex", justifyContent: "space-between" }}>
														<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
															<span style={{ fontWeight: 500, color: "#777" }}>Min</span>
															<input className='priceRangeBox' type="text" onChange={(e) => { setPriceRangeMinValue(e.target.value) }} defaultValue={priceinfo[0]} />
														</div>
														<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>

															<span style={{ fontWeight: 500, color: "#777" }}>Max</span>
															<input className='priceRangeBox' type="text" onChange={(e) => { setPriceRangeMaxValue(e.target.value) }} defaultValue={priceinfo[priceinfo.length - 1]} />
														</div>
													</div>

													<div
														className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
														{/* <div className="filter-price-text">
														Price:
														<span>₹{priceinfo[0]} - ₹{priceinfo[priceinfo.length - 1]}</span>
													</div> */}
														{/* <!-- End .filter-price-text --> */}

														{/* <button type="submit" className="btn btn-primary">Filter</button> */}
													</div>
													{/* <!-- End .filter-price-action --> */}
												</form>
											</div>
											{/* <!-- End .widget-body --> */}
										</div>
										{/* <!-- End .collapse --> */}
									</div>
									{/* <!-- End .widget --> */}

									{/* {attributes.map((attribute) => (
									<>
										{attribute.node.choices.totalCount > 0 ?
											<div className="widget">
												<h3 className="widget-title">
													<a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true"
														aria-controls="widget-body-2">{attribute.node.name}<span className="products-count">({attribute.node.choices.totalCount})</span></a>
												</h3>

												<div className="collapse show" id="widget-body-2">
													<div className="widget-body">
														<ul className="cat-list">
															{attribute.node.choices.edges.map((choice) => (
																<>
																	{selectattributes.includes(choice.node.id) ?
																		<li>
																			<span><input type="checkbox" style={{ accentColor: "orangered" }} checked={selectattributes.includes(choice.node.id)} id={`{values:["${choice.node.name}"] slug:"${attribute.node.slug}"}`} /></span>
																			<a href="#widget-category-1" data-toggle="collapse" role="button"
																				aria-expanded="true" aria-controls="widget-category-1">
																				{choice.node.name}
																			</a>
																		</li> : null}
																</>
															))}
														</ul>
													</div>

												</div>
												
											</div> : null}
									</>
								))} */}

								</div>

								{/* <!-- End .widget --> */}

								{/* <!-- End .sidebar-wrapper --> */}
							</aside>
							{/* <!-- End .col-lg-3 --> */}
						</div>

						{/* <!-- End .row --> */}
					</div>
					{/* <!-- End .container --> */}


					<div style={{ maxWidth: "95%" }} class="container">

						<h2 class="section-title ls-n-10 pb-3 m-b-4">Recently Viewed</h2>

						<div class="row no-gutters">

							{recentlyData.slice(0, recentlyData.length - 1).reverse().map((value) => (
								<>
									{recData !== null ?
										<>
											{recData.includes(value.node.id) ?
												<div className="col-6 col-sm-4 col-md-3 col-xl-2">
													<div className="product-default inner-quickview inner-icon">
														<figure>
															<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>
																<img src={value.node.media[0].url} width="217"
																	height="217" alt="product" />
																<img src={value.node.media[0].url} width="217"
																	height="217" alt="product" />
															</Link>

															<div className="btn-icon-group">
																<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }} className="btn-icon btn-add-cart"><i
																	className="fa fa-arrow-right"></i></Link>
															</div>
															{/* <Link to={`/category/${value.node.id}`} onClick={() => { setQuickviewOpen(true) }} className="btn-quickview"
															title="Quick View">Quick View</Link> */}
														</figure>
														<div className="product-details">
															<div className="category-wrap">
																<div className="category-list">
																	<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }} className="product-category">{value.node.category.name}</Link>
																</div>
																{/* <a onClick={wishlist.includes(value.node.variants[0].id)&&  checkWishlist.includes(value.node.variants[0].id) ?removeWishlist:onAddToWishlist} class={wishlist.includes(value.node.variants[0].id) &&  checkWishlist.includes(value.node.variants[0].id)? "btn-icon-wished added-wishlists" : "btn-icon-wished"} title="wishlist"> 
												   <i className="icon-heart" id={value.node.variants[0].id}></i>
												</a> */}
																<a onClick={wishlist.includes(value.node.id) ? removeWishlist : onAddToWishlist} class={wishlist.includes(value.node.id) ? "btnicon-wishs added-wishlists" : "btn-icon-wishs"} ><i
																	className="icon-heart" id={value.node.id}></i></a>
															</div>
															<h3 className="product-title">
																<Link to={{ pathname: `/product/${encodeURIComponent(value.node.slug)}` }}>{value.node.name} </Link>
															</h3>
															<div class="ratings-container">
																<div class="">
																	{!(value.node.rating == null || value.node.rating == 0) ?
																		<span class="">
																			{value.node.rating.toString().length > 1?value.node.rating.toFixed(1):value.node.rating} <i className='fas fa-star text-warning'></i>
																		</span> : null}
																	{/* <!-- End .ratings --> */}
																	<span class="tooltiptext tooltip-top">{value.node.id}</span>
																</div>
																{/* <!-- End .product-ratings --> */}
															</div>
															{/* <!-- End .product-container --> */}
															{!value.node.productType.hasVariants ?
																<div className="price-box">
																	<>
																		{value.node.variants[0].originalPrice !== value.node.pricing.priceRange.start.net.amount ?
																			<>
																				{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice)}</span> : <>{value.node.variants[0].originalPrice !== "0.00" && totalValue(value.node.variants[0].originalPrice) !== totalValue(value.node.pricing.priceRange.start.net.amount) ? <span className="old-price">₹{totalValue(value.node.variants[0].originalPrice - value.node.variants[0].weightedShipping)}</span> : null}</>}
																				{value.node.weightedShipping === null || value.node.weightedShipping === 0 ? <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount)}</span> : <span className="product-price">₹{totalValue(value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
																			</>
																			: <span className="product-price">₹{totalValue(value.node.weightedShipping === null || value.node.weightedShipping === 0 ? value.node.pricing.priceRange.start.net.amount : value.node.pricing.priceRange.start.net.amount - value.node.variants[0].weightedShipping)}</span>}
																	</>
																</div> :
																<div className="price-box">
																	<span style={{ color: "black", fontSize: "1.8rem" }}>From </span>
																	{value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity) === totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity)) ? <span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span> : <>   <strike class="product-pricee"> ₹{totalValue(value.node.variants.reduce((total, item) => (total > item.originalPrice ? total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping : total = total), Infinity))}</strike>
																		<span class="product-price"> ₹{totalValue(value.node.variants.reduce((totall, item) => (totall > item.originalPrice ? totall = item.weightedShipping !== null ? value.node.pricing.priceRange.start.net.amount - item.weightedShipping : value.node.pricing.priceRange.start.net.amount : totall = totall), Infinity))}</span></>}
																</div>
															}
															{/* <!-- End .price-box --> */}
														</div>
														{/* <!-- End .product-details --> */}
													</div>
												</div> : null}
										</>
										: null}
								</>
							))}



						</div>
					</div>
					<div className="mb-4"></div>
					{/* <!-- margin --> */}

				</main>
				<Footer />
			</div>

		</div>
	)
}

export default BrandSearchScreen
