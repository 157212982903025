import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";

import { RiLockPasswordLine } from 'react-icons/ri';
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { SAVED_ADDRESSES } from "../GraphQL/Queries";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_ADDRESS_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_ADDRESS_DELETE } from "../GraphQL/Mutation";
import { SAVED_ORDERS } from "../GraphQL/Queries";
import { PASSWORD_CHANGE_MUTATION } from "../GraphQL/Mutation";
import { ACCOUNT_UPDATE_MUTATION, CREATE_CHECKOUT_MUTATION, CHECKOUT_ADD_PRODUCT_LINE_MUTATION } from "../GraphQL/Mutation";
import "./Dashboard.css";
import axios from "axios";
import { Navigate } from 'react-router-dom'

import { BsTrash} from 'react-icons/bs'
const SetPasswordScreen = () => {
  const [changePasswordSuccessMessage,setChangePasswordSuccessMessage]= useState(false)
  const [changePasswordErrorMessage,setChangePasswordErrorMessage]= useState(false)
  const [addAddressLoader, setAddAddressLoader]=useState("SAVE ADDRESS")
  const [currentPassword, setCurrentPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [Address, setAddress] = useState([]);
  const [addressDeleteId, setAddressDeleteId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [changedFirstName, setChangedFirstName] = useState();
  const [changedLastName, setChangedLastName] = useState();
  const [companyName, setCompanyName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [cityArea, setCityArea] = useState();
  const [cityName, setCityName] = useState();
  const [stateName, setStateName] = useState();
  const [pincode, setPincode] = useState();
  const [phone, setPhone] = useState();
  const [order, setOrders] = useState([]);
  const [errorMe, errorMessage] = useState([]);
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
	const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
const [userFirstName, setUserFirstName]= useState();
const [userLastName, setUserLastName]= useState();
  const username = localStorage.getItem("userName");
const [showPasswordChangeInterface, setShowPasswordChangeInterface]= useState(false)
  const email = localStorage.getItem("user");
  const [changePasswordLoader, setChangePasswordLoader]= useState("save changes")
  const [matchPasswordErrorMessage,setMatchPasswordErrorMessage]= useState(false)

  const logoutHandeler = () => {
     //localStorage.clear();
     localStorage.removeItem('dummyemail')
     localStorage.removeItem('token')
     localStorage.removeItem('refreshToken')
     localStorage.removeItem('userName')
     localStorage.removeItem('user')
     localStorage.removeItem('email')
     localStorage.removeItem('userId')
     localStorage.removeItem('recentData')
     localStorage.removeItem('rzp_checkout_anon_id')
     localStorage.removeItem('rzp_device_id')
  };

 
  useEffect(() => {
  const loggedInUserByOtp = localStorage.getItem("loginByOtp");
  if(loggedInUserByOtp){
    setShowPasswordChangeInterface(true)
  }
  const tempEmail = localStorage.getItem("tempEmail");
  // if(tempEmail){
  //   setShowPasswordChangeInterface(false)
  //   errorMessage('To set your password, complete your profile first')
  // }
}, []);

 
  

  const passwordSetHandler = (e) => {
    e.preventDefault();
    setChangePasswordLoader(<div className='loaderr'></div>)
    if(rePassword !== currentPassword){
      setMatchPasswordErrorMessage(true)
      setChangePasswordLoader("SAVE CHANGES")
      return false
    }
    setTimeout(() => {
      setChangePasswordLoader("SAVE CHANGES")
    }, 1000);
  
    var options = {
			method: 'post',
			url: 'https://wsi.rocketinpocket.com/hegma/v1/hegma/setpassword',
			data: JSON.stringify({
			  'token': localStorage.getItem('token'),
        'new_password': currentPassword
			}),
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			}
		  };
		  axios(options)
			  .then((response) => {
				//console.log(response);
				if(response.data !== ''){ setChangePasswordErrorMessage(false)
					setChangePasswordSuccessMessage(true)
          setChangePasswordErrorMessage(false)	
          localStorage.removeItem("loginByOtp")
				} else { setChangePasswordErrorMessage(false)
          setChangePasswordErrorMessage(true)
          setChangePasswordSuccessMessage(false)
        }
			})
			.catch((error) => {
			//	console.log(error)
				setChangePasswordErrorMessage(true)
        setChangePasswordSuccessMessage(false)
		  })
    
  };

  if(localStorage.getItem('loginByOtp') === null) {
    return (<Navigate to="/Dashboard" />);
  }



  return (
    <>
      {modalOpen && (
        <div className="ModalBackground">
          <div className="ModalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="title">
              <h3>Are You Sure You Want to Continue?</h3>
            </div>

            <div className="footer">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
                id="CancelBtn"
              >
                Cancel
              </button>
              <button onClick={addressDeleteHandeler}>Continue</button>
            </div>
          </div>
        </div>
      )}
      <Header />
      <main class="main">
        {/* <div class="page-header">
          <div class="container d-flex flex-column align-items-center">
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a >Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a >Shop</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    My Account
                  </li>
                </ol>
              </div>
            </nav>

            <h1>My Account</h1>
          </div>
        </div> */}

        <div class="container account-container custom-account-container">
        <div >
								<h4 className='errorBoxContent'>{errorMe}</h4>
			</div>
          <div class="row">
            <div class="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
              <h2 class="text-uppercase">My Account</h2>
              <ul class="nav nav-tabs list flex-column mb-0" role="tablist">
                <li class="nav-item">
                  <Link to="/Dashboard"
                    class="nav-link active"
                    id="dashboard-tab"
                    data-toggle="tab"
                    style={{fontWeight:400, color:"grey",cursor:"pointer"}}
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="true"
                  >
                    Dashboard
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/order"
                    class="nav-link"
                    id="order-tab"
                    data-toggle="tab"
                   
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                  >
                    Orders
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/address"
                    class="nav-link"
                    
                    data-toggle="tab"
                   
                    role="tab"
                    aria-controls="address"
                    aria-selected="false"
                  >
                    Addresses
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="/account-detail"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                   
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                   Profile
                  </Link>
                </li>
                {!showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/change-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                  
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Change Password
                  </Link>
                </li>}

                {showPasswordChangeInterface && <li class="nav-item">
                  <Link to="/set-password"
                    class="nav-link"
                    id="edit-tab"
                    data-toggle="tab"
                  
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Set Password
                  </Link>
                </li>}
                
                {/* <li class="nav-item">
                  <a
                    class="nav-link"
                    id="shop-address-tab"
                    data-toggle="tab"
                    href="#shipping"
                    role="tab"
                    aria-controls="edit"
                    aria-selected="false"
                  >
                    Shipping Address
                  </a>
                </li> */}
                <li class="nav-item">
                  <Link to="/wishlist" class="nav-link">
                    Wishlist
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/login" onClick={logoutHandeler} class="nav-link">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            <div class="col-lg-9 order-lg-last order-1 tab-content">
           
              {/* <!-- End .tab-pane --> */}

              <div style={{display:"block", opacity:1}} class="tab-pane fade" id="edit" role="tabpanel">
                <h3 class="account-sub-title d-none d-md-block mt-0 pt-1 ml-1">
                  <RiLockPasswordLine style={{paddingRight:8,marginBottom:10, fontSize:50, color:"#d3d3d4"}}/>Set Password
  
                </h3>
                <div class="account-content">
                  <div>


                   
{showPasswordChangeInterface &&  <form
                      onSubmit={passwordSetHandler}
                      class=""
                      id="accordion1"
                    >
                      <div class="card card-accordion">
     {changePasswordErrorMessage && <p style={{color:"Red"}}> Please enter at least 8 character long password</p>}                  
{changePasswordSuccessMessage && <p style={{color:"green"}}>Your Password has been changed successfully</p>} 
{matchPasswordErrorMessage && <p style={{color:"red"}}> Password  has been not match</p>} 
                        <div class="row changePasswordRow">
                          <div class="col-md-6 nameChange">
                            <div class="form-group">
                              <label for="acc-name">
                                Please enter Password <span class="required">*</span>
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="acc-name"
                                name="acc-name"
                                onChange={(event) => {
                                  setCurrentPassword(event.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>

                          <div class="col-md-6 nameChange">
                            <div class="form-group">
                              <label for="acc-lastname">
                                Re-enter Password <span class="required">*</span>
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="acc-lastname"
                                name="acc-lastname"
                                onChange={(event) => {
                                  setRePassword(event.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-footer  ">
                            <button
                            style={{position:"relative", width:150, height:50}}
                              type="submit"
                              class="btn btn-dark mr-0 nameChangeBtn"
                            >{changePasswordLoader}
                            </button>
                          </div>
                        {/* <div class="form-footer mt-3 mb-0">
                          <button type="submit" class="btn btn-dark mr-0">
                            Save changes
                          </button>
                        </div> */}
                      </div>
                    </form>}
                   
                  </div>
                </div>
              </div>
              {/* <!-- End .tab-pane --> */}

            
              {/* <!-- End .tab-pane --> */}
              {/* <!-- End .tab-pane --> */}
            </div>
            {/* <!-- End .tab-content --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* !-- End .container --> */}

        <div class="mb-5"></div>
        {/* <!-- margin --> */}
      </main>
      <Footer />
    </>
  );
};

export default SetPasswordScreen;
