import React, { useState, useEffect } from "react";
import { totalValue } from "./decimalFunction";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { GET_COLLECTION_LIST, GET_CHECKOUT_BY_TOKEN } from "../GraphQL/Queries";
import { useQuery, useMutation } from "@apollo/client";
import {
  CHECKOUT_ADD_PRODUCT_LINE_MUTATION,
  CREATE_CHECKOUT_MUTATION, CHECKOUT_LINE_UPDATE, UPDATE_WISHLIST_MUTATION
} from "../GraphQL/Mutation";
import "./HomeScreen.css";
import HomeQuickview from "./HomeQuickview";
import { AiFillStar } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation, Thumbs } from "swiper";
import Header from "../components/Header/Header";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { testimonialApi } from "../components/FakeApi.js/FAkeApi";
import InfiniteScroll from 'react-infinite-scroll-component';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeScreen = () => {
  const [bannerproducts, setProductBannerList] = useState([]);
  const [quickviewOpen, setQuickviewOpen] = useState(false);
  const [CreateCheckout] = useMutation(CREATE_CHECKOUT_MUTATION);
  const [updateWishlist] = useMutation(UPDATE_WISHLIST_MUTATION, {
    context: {
      headers: {
        Authorization: "JWT " + localStorage.getItem("token"),
      },
    },
  });
  const [addLineCheckout] = useMutation(CHECKOUT_ADD_PRODUCT_LINE_MUTATION);
  const [ProductList, setProductList] = useState([]);
  const [BannerList, setBannerList] = useState([]);
  const [updateLineCheckout] = useMutation(CHECKOUT_LINE_UPDATE);
  const [removeRedHeart, setRemoveRedHeart] = useState(false)
  const [wishlist, setWishlist] = useState([])
  const [addwishlist, setAddWishlist] = useState([])
  const [change, setChange] = useState(1)
  const [checkWishlist, setCheckWishlist] = useState([])
  const pageTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    if (!(localStorage.getItem("wishlistToken") === null || localStorage.getItem("wishlistToken") === undefined || localStorage.getItem("wishlistToken") === '')) {
      setAddWishlist([])
      let wishToken = localStorage.getItem("wishlistToken").replaceAll("[", "").replaceAll("]", "").replaceAll("'", "").replaceAll(" ", "")
      for (let index = 0; index < wishToken.split(",").length; index++) {
        setAddWishlist((addwishlist) => [...addwishlist, wishToken.split(",")[index]])
      }
    }
    pageTop()
    setChange(5)
  }, [change])
  const { refetch: refetch3 } = useQuery(GET_CHECKOUT_BY_TOKEN, {
    variables: {
      token: localStorage.getItem('wishlistToken')
    },
    onCompleted: data => {
      for (let index = 0; index < data.checkout.lines.length; index++) {
        setCheckWishlist((pre) => [...pre, data.checkout.lines[index].variant.id])
        setWishlist((previous) => [...previous, data.checkout.lines[index].variant.id])
      }
    },
  })
  //console.log("wishlist8888888",wishlist,"check",checkWishlist)
  const removeWishlist = (e) => {
    const { id } = e.target;
    if (wishlist.includes(id)) {
      let unWishlist = wishlist.filter((item) => item !== id);
      setWishlist(unWishlist)
      setAddWishlist(unWishlist)
      localStorage.setItem("wishlistToken", JSON.stringify(unWishlist));
      console.log("REMOVE TO WISHLIST")


      // let getWishListToken = localStorage.getItem("wishlistToken").split(",")
      // let removeWishlistId = JSON.parse(localStorage.getItem('wishlistToken'))?.filter((item)=>item !== id)
      // // let convertString = removeWishlistId.toString()
      // console.log(localStorage.getItem("wishlistToken"),"convertString",getWishListToken,"removeWishlistId",removeWishlistId,"id",id)
      // localStorage.setItem("wishlistToken",   JSON.stringify(removeWishlistId));
    }

  }

  const { } = useQuery(GET_COLLECTION_LIST, {
    variables: {
      first: 20,
      channel: "home-banner",
      sortBy: {
        field: "PRIORITY",
        direction: "ASC"
      }
    },
    onCompleted: (data) => {
      console.log(data.collections.edges[0].node.products.edges);
      setProductBannerList(data.collections.edges[0].node.products.edges);
    },
  });

  const { refetch: refetch2 } = useQuery(GET_COLLECTION_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      first: 10,
      channel: "default-channel",
      sortBy: { field: "PRIORITY", direction: "ASC" },
    },
    onCompleted: (data) => {
      setProductList(data.collections.edges);
    },
  });

  const { refetch: refetch1 } = useQuery(GET_COLLECTION_LIST, {
    fetchPolicy: "no-cache",
    variables: {
      first: 20,
      channel: "home-collection",
      sortBy: { field: "PRIORITY", direction: "ASC" },
    },
    onCompleted: (data) => {
      // console.log("HomeScreen",data)
      setBannerList(data.collections.edges);
    },
  });

  const onAddToWishlist = (e) => {
    const { id } = e.target;
    if (!wishlist.includes(id)) {
      setWishlist([...wishlist, id])
      setAddWishlist([...addwishlist, id])
      if(localStorage.getItem("wishlistToken")===null || localStorage.getItem("wishlistToken")===''){
        localStorage.setItem("wishlistToken", JSON.stringify([id]));
        console.log("null local storage")
      }else{console.log("set JSON STRING")
        if(!localStorage.getItem("wishlistToken").includes(id)){
      localStorage.setItem("wishlistToken",  JSON.stringify([
        ...JSON.parse(localStorage.getItem("wishlistToken")),
        id,
      ]));}}
      updateWishlist({
        variables: {
          input: {
            wishlistToken: [...addwishlist, id],
          },
        },
        onCompleted: (data) => {
          console.log(data);
        },
      });
    }
  };
  window.addEventListener('popstate', (event) => {
    setQuickviewOpen(false)
  });
  return (
    <>
      {quickviewOpen && (
        <HomeQuickview setQuickviewOpenModal={setQuickviewOpen} />
      )}
      <Header />

      <main className="main">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {bannerproducts.map((bProducts) => (
            <>
              {bProducts.node.metadata.length !== 0 ? (
                <>
                  {bProducts.node.metadata.map((meta) => (
                    <>
                      {meta.key === "collection" ? (
                        <SwiperSlide className="slide ">
                          <Link to={{ pathname: `/collection/${meta.value}` }}>
                            <picture>
                              <source
                                media="(max-width:768px)"
                                srcSet={bProducts.node.media[1].url}
                              />
                              <img src={bProducts.node.media[0].url}></img>
                            </picture>
                          </Link>
                        </SwiperSlide>
                      ) : null}
                      {meta.key === "product" ? (
                        <SwiperSlide className="slide ">
                          <Link
                            to={{ pathname: `/product/${meta.value}` }}
                          >
                            <picture>
                              <source
                                media="(max-width:768px)"
                                srcSet={bProducts.node.media[1].url}
                              />
                              <img src={bProducts.node.media[0].url}></img>
                            </picture>
                          </Link>
                        </SwiperSlide>
                      ) : null}
                    </>
                  ))}
                </>
              ) : (
                <SwiperSlide className="slide ">
                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={bProducts.node.media[1].url}
                    />
                    <img src={bProducts.node.media[0].url}></img>
                  </picture>
                </SwiperSlide>
              )}
            </>
          ))}
        </Swiper>

        {/* Featured Brands */}
        <div style={{ maxWidth: "95%" }} class="container">
          <div class="headding">
            <h4 class="section-title text-transform-none">
              Featured Brands
            </h4>
          </div>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              480: {
                slidesPerView: 3,
              },
              750: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 5,
              },
              1199: {
                slidesPerView: 6,
              },
              1200: {
                slidesPerView: 7,
              },
            }}
            spaceBetween={20}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            navigation={true}
            grabCursor={true}
            modules={[Thumbs, Navigation]}
            className="mySwiper">
            <div className="px-5">
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    className="featuredBrand"
                    src="assets\images\brandsLogo\feature2.jpg"
                    alt="f2"
                  />
                  <span class="brandtitle">DENTSPLY</span>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    className="featuredBrand"
                    src="assets\images\brandsLogo\feature3.jpg"
                    alt="f3"
                  />
                  <span class="brandtitle">CARESTREAM</span>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    src="assets\images\brandsLogo\feature1.jpg"
                    alt="f1"
                    className="featuredBrand"
                  />
                  <span class="brandtitle">DIADENT</span>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    src="assets\images\brandsLogo\feature4.jpg"
                    alt="f4"
                    className="featuredBrand"
                  />
                  <span class="brandtitle">GDC</span>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    src="assets\images\brandsLogo\feature5.jpg"
                    alt="f5"
                    className="featuredBrand"
                  />
                  <span class="brandtitle">IVOCLAR</span>
                </figure></SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    src="assets\images\brandsLogo\feature6.jpg"
                    alt="f6"
                    className="featuredBrand"
                  />
                  <span class="brandtitle">3M-ESPE</span>
                </figure></SwiperSlide>
              <SwiperSlide>
                <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img
                    style={{
                      marginBottom: 5,
                      boxShadow:
                        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      height: 130,
                    }}
                    src="assets\images\brandsLogo\feature7.jpg"
                    alt="f7"
                    className="featuredBrand"
                  />
                  <span class="brandtitle">KODEN</span>
                </figure>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>

        {BannerList.length > ProductList.length
          ? BannerList.map
          : ProductList.map((bal, index) => (
            <><div className="bannerContainer">
              {BannerList[index] && BannerList[index].node.products.edges.length !== 4 ?
                <div className="banner">
                  {/* {console.log("{BannerList[index].node.products.edges.length !== 4?"banner":"bannerFirst"}.length,window.innerWidth)} */}
                  {window.innerWidth > 478 ?
                    BannerList[index]
                      ? BannerList[index].node.products.edges.map(
                        (hproducts2) => (
                          <div className="banner1">
                            {hproducts2.node.metadata.length !== 0 ? (
                              <>
                                {hproducts2.node.metadata.map((meta) => (
                                  <>
                                    {meta.key === "collection" ? (
                                      <Link
                                        to={{
                                          pathname: `/collection/${meta.value}`,
                                        }}
                                      >
                                        <LazyLoadImage
                                          src={hproducts2.node.media[0].url}
                                          effect="blur"
                                        />
                                      </Link>
                                    ) : null}
                                    {meta.key === "product" ? (
                                      <Link
                                        to={{
                                          pathname: `/product/${meta.value}`,
                                        }}
                                      >
                                        <LazyLoadImage
                                          src={hproducts2.node.media[0].url}
                                          effect="blur"
                                        />
                                      </Link>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            ) : (
                              <LazyLoadImage src={hproducts2.node.media[0].url} effect="blur" />
                            )}
                          </div>
                        )
                      )
                      : null
                    :
                    <Swiper
                      breakpoints={{
                        530: {
                          //   width: 368,
                          slidesPerView: 1,
                        },

                      }}
                      spaceBetween={30}
                      centeredSlides={true}
                      loop={true}
                      slidesPerView={1}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <div className="">
                        {BannerList[index]
                          ? BannerList[index].node.products.edges.map(
                            (hproducts2) => (
                              <SwiperSlide>
                                <div className="banner1">
                                  {hproducts2.node.metadata.length !== 0 ? (
                                    <>
                                      {hproducts2.node.metadata.map((meta) => (
                                        <>
                                          {meta.key === "collection" ? (
                                            <Link
                                              to={{
                                                pathname: `/collection/${meta.value}`,
                                              }}
                                            >
                                              <LazyLoadImage
                                                src={hproducts2.node.media[0].url}
                                                effect="blur"
                                              />
                                            </Link>
                                          ) : null}
                                          {meta.key === "product" ? (
                                            <Link
                                              to={{
                                                pathname: `/product/${meta.value}`,
                                              }}
                                            >
                                              <LazyLoadImage
                                                src={hproducts2.node.media[0].url}
                                                effect="blur"
                                              />
                                            </Link>
                                          ) : null}
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <LazyLoadImage src={hproducts2.node.media[0].url} effect="blur" />
                                  )}
                                </div>
                              </SwiperSlide>
                            )
                          )
                          : null}
                      </div>
                    </Swiper>
                  }
                </div>
                :
                <div className={BannerList[index] && BannerList[index].node.products.edges.length !== 4 ? "banner" : "bannerFirst"}>
                  {BannerList[index]
                    ? BannerList[index].node.products.edges.map(
                      (hproducts2) => (
                        <div className="banner1 blob">
                          {hproducts2.node.metadata.length !== 0 ? (
                            <>
                              {hproducts2.node.metadata.map((meta) => (
                                <>
                                  {meta.key === "collection" ? (
                                    <Link
                                      to={{
                                        pathname: `/collection/${meta.value}`,
                                      }}
                                    >
                                      <LazyLoadImage
                                        src={hproducts2.node.media[0].url}
                                        effect="blur"
                                      />
                                    </Link>
                                  ) : null}
                                  {meta.key === "product" ? (
                                    <Link
                                      to={{
                                        pathname: `/product/${meta.value}`,
                                      }}
                                    >
                                      <LazyLoadImage
                                        src={hproducts2.node.media[0].url}
                                        effect="blur"
                                      />
                                    </Link>
                                  ) : null}
                                </>
                              ))}
                            </>
                          ) : (
                            <LazyLoadImage src={hproducts2.node.media[0].url} effect="blur" />
                          )}
                        </div>
                      )
                    )
                    : null}

                </div>
              }
            </div>

              {ProductList[index].node.metadata.length === 0 ?
                <div class="container">
                  <h2
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.08)" }}
                    class="section-title ls-n-10 pb-3 m-b-4"
                  >
                    {ProductList[index].node.products.edges.length !== 0 &&
                      ProductList[index]
                      ? ProductList[index].node.name
                      : null}
                  </h2>

                  <div class="row no-gutters">
                    {ProductList[index]
                      ? ProductList[index].node.products.edges.map(
                        (product) => (
                          <div class="col-6 col-sm-4 col-md-3 col-xl-2">
                            <div class="product-default inner-quickview inner-icon">
                              <figure>
                                <Link
                                  to={{
                                    pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                  }}
                                >
                                  <LazyLoadImage
                                    src={product.node.media?.[0]?.url}
                                    width="217"
                                    height="217"
                                    alt="product"
                                    effect="blur"
                                  />
                                </Link>

                                <div class="btn-icon-group">
                                  <Link
                                    to={{
                                      pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                    }}
                                    class="btn-icon btn-add-cart"
                                  ></Link>
                                </div>
                                {/* <a href="ajax/product-quick-view.html" class="btn-quickview"
                                                        title="Quick View">Quick View</a> */}
                                <Link
                                  to={{
                                    pathname: `/${product.node.slug}`,
                                  }}
                                  onClick={() => {
                                    setQuickviewOpen(true);
                                  }}
                                  className="btn-quickview"
                                  style={{ color: "white" }}
                                >
                                  quick view
                                </Link>
                              </figure>
                              <div class="product-details">
                                <div class="category-wrap">
                                  <div class="category-list">
                                    <Link
                                      to={{
                                        pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                      }}
                                      class="product-category"
                                    >
                                      {product.node.category.name}
                                    </Link>
                                  </div>

                                  <a
                                    onClick={wishlist.includes(product.node.id) ? removeWishlist : onAddToWishlist}
                                    title="Add to Wishlist"
                                    class={wishlist.includes(product.node.id) ? `btn-icon-wishs added-wishlists` : "btn-icon-wishs"}
                                  >
                                    <i
                                      class="icon-heart"
                                      id={product.node.id}
                                    ></i>
                                  </a>
                                </div>
                                <h3 class="product-title">
                                  <Link
                                    to={{
                                      pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                    }}
                                  >
                                    {product.node.name}
                                  </Link>
                                </h3>
                                <div class="ratings-container">
                                  <div class="">
                                    {!(product.node.rating == null || product.node.rating == 0) ?
                                      <span class="">
                                        {product.node.rating.toString().length > 1 ? product.node.rating.toFixed(1) : product.node.rating} <i className='fas fa-star text-warning'></i>
                                      </span> : null}
                                    {/* <!-- End .ratings --> */}
                                  </div>
                                  {/* <!-- End .product-ratings --> */}
                                </div>
                                {/* <!-- End .product-container --> */}
                                {!product.node.productType.hasVariants ? (
                                  <div class="price-box  ">
                                  {product.node.variants[0]
                                      .weightedShipping === null ? (
                                      <span class="product-price">
                                        ₹{totalValue(product.node.pricing.priceRange.start.net.amount)}
                                      </span>
                                    ) : (
                                      <span class="product-price">
                                        ₹{totalValue(product.node.pricing.priceRange.start.net.amount - product.node.variants[0].weightedShipping)}
                                      </span>
                                    )}
                                    {" "}{" "}
                                    {product.node.variants[0]
                                      .weightedShipping === null ? (
                                      <strike style={{ fontSize: "1.5rem" }}>
                                       {product.node.variants[0].originalPrice!="0.00"? `₹${totalValue(product.node.variants[0].originalPrice)}`:null}
                                      </strike>
                                    ) : (
                                      <strike style={{ fontSize: "1.5rem" }}>{product.node.variants[0].originalPrice -product.node.variants[0].weightedShipping!="0.00"?`₹${totalValue(product.node.variants[0].originalPrice -product.node.variants[0].weightedShipping)}`:null}
                                      </strike>
                                    )}
                                    
                                  </div>
                                ) : (
                                  <div class="price-box">
                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: "1.8rem",
                                        paddingRight:5,
                                        paddingBottom:5,
                                        display:"inline-block"
                                      }}
                                    >
                                      From{" "}
                                    </span>
                                    {product.node.variants.reduce(
                                      (total, item) =>
                                        total > item.originalPrice
                                          ? (total =
                                            item.weightedShipping === null
                                              ? item.originalPrice
                                              : item.originalPrice -
                                              item.weightedShipping)
                                          : (total = total),
                                      Infinity
                                    ) ===
                                      totalValue(
                                        product.node.variants.reduce(
                                          (totall, item) =>
                                            totall > item.originalPrice
                                              ? (totall =
                                                item.weightedShipping !== null
                                                  ? product.node.pricing
                                                    .priceRange.start.net
                                                    .amount -
                                                  item.weightedShipping
                                                  : product.node.pricing
                                                    .priceRange.start.net
                                                    .amount)
                                              : (totall = totall),
                                          Infinity
                                        )
                                      ) ? (
                                      <span class="product-price">
                                        {" "}
                                        ₹
                                        {totalValue(
                                          product.node.variants.reduce(
                                            (totall, item) =>
                                              totall > item.originalPrice
                                                ? (totall =
                                                  item.weightedShipping !==
                                                    null
                                                    ? product.node.pricing
                                                      .priceRange.start
                                                      .net.amount -
                                                    item.weightedShipping
                                                    : product.node.pricing
                                                      .priceRange.start
                                                      .net.amount)
                                                : (totall = totall),
                                            Infinity
                                          )
                                        )}
                                      </span>
                                    ) : (
                                      <>
                                        {" "}
                                        <span class="product-price">
                                          {" "}
                                          ₹
                                          {totalValue(
                                            product.node.variants.reduce(
                                              (totall, item) =>
                                                totall > item.originalPrice
                                                  ? (totall =
                                                    item.weightedShipping !==
                                                      null
                                                      ? product.node.pricing
                                                        .priceRange.start
                                                        .net.amount -
                                                      item.weightedShipping
                                                      : product.node.pricing
                                                        .priceRange.start
                                                        .net.amount)
                                                  : (totall = totall),
                                              Infinity
                                            )
                                          )}
                                        </span>
                                        <strike class="product-pricee">
                                          {" "}
                                          ₹
                                          {totalValue(product.node.variants.reduce((total, item) => total > item.originalPrice ? (total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping) : (total = total), Infinity))}
                                        </strike>
                                      
                                      </>
                                    )}
                                  </div>
                                )}
                                {/* <!-- End .price-box --> */}
                              </div>
                              {/* <!-- End .product-details --> */}
                            </div>
                          </div>
                        )
                      )
                      : null}
                  </div>
                </div> :
                <>
                  {ProductList[index].node.metadata.map((meta) => (
                    <>
                      {!(meta.key == "home" && meta.value == 0)?
                        <div class="container">
                          <h2
                            style={{ borderBottom: "1px solid rgba(0,0,0,0.08)" }}
                            class="section-title ls-n-10 pb-3 m-b-4"
                          >
                            {ProductList[index].node.products.edges.length !== 0 &&
                              ProductList[index]
                              ? ProductList[index].node.name
                              : null}
                          </h2>

                          <div class="row no-gutters">
                            {ProductList[index]
                              ? ProductList[index].node.products.edges.map(
                                (product) => (
                                  <div class="col-6 col-sm-4 col-md-3 col-xl-2">
                                    <div class="product-default inner-quickview inner-icon">
                                      <figure>
                                        <Link
                                          to={{
                                            pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                          }}
                                        >
                                          <LazyLoadImage
                                            src={product.node.media[0].url}
                                            width="217"
                                            height="217"
                                            alt="product"
                                            effect="blur"
                                          />
                                        </Link>

                                        <div class="btn-icon-group">
                                          <Link
                                            to={{
                                              pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                            }}
                                            class="btn-icon btn-add-cart"
                                          ></Link>
                                        </div>
                                        {/* <a href="ajax/product-quick-view.html" class="btn-quickview"
                                                              title="Quick View">Quick View</a> */}
                                        <Link
                                          to={{
                                            pathname: `/${product.node.slug}`,
                                          }}
                                          onClick={() => {
                                            setQuickviewOpen(true);
                                          }}
                                          className="btn-quickview"
                                          style={{ color: "white" }}
                                        >
                                          quick view
                                        </Link>
                                      </figure>
                                      <div class="product-details">
                                        <div class="category-wrap">
                                          <div class="category-list">
                                            <Link
                                              to={{
                                                pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                              }}
                                              class="product-category"
                                            >
                                              {product.node.category.name}
                                            </Link>
                                          </div>

                                          <a
                                            onClick={wishlist.includes(product.node.id) ? removeWishlist : onAddToWishlist}
                                            title="Add to Wishlist"
                                            class={wishlist.includes(product.node.id) ? `btn-icon-wishs added-wishlists` : "btn-icon-wishs"}
                                          >
                                            <i
                                              class="icon-heart"
                                              id={product.node.id}
                                            ></i>
                                          </a>
                                        </div>
                                        <h3 class="product-title">
                                          <Link
                                            to={{
                                              pathname: `/product/${encodeURIComponent(product.node.slug)}`,
                                            }}
                                          >
                                            {product.node.name}
                                          </Link>
                                        </h3>
                                        <div class="ratings-container">
                                          <div class="">
                                            {!(product.node.rating == null || product.node.rating == 0)?
                                              <span class="">
                                                {product.node.rating.toString().length > 1 ? product.node.rating.toFixed(1) : product.node.rating} <i className='fas fa-star text-warning'></i>
                                              </span> : null}
                                            {/* <!-- End .ratings --> */}
                                          </div>
                                          {/* <!-- End .product-ratings --> */}
                                        </div>
                                        {/* <!-- End .product-container --> */}
                                        {!product.node.productType.hasVariants ? (
                                          <div class="price-box">
                                            {product.node.variants[0]
                                              .weightedShipping === null ? (
                                              <strike style={{ fontSize: "1.6rem" }}>
                                                ₹{totalValue(product.node.variants[0].originalPrice)}
                                              </strike>
                                            ) : (
                                              <strike style={{ fontSize: "1.6rem" }}>
                                                ₹
                                                {totalValue(product.node.variants[0]
                                                  .originalPrice -
                                                  product.node.variants[0]
                                                    .weightedShipping)}
                                              </strike>
                                            )}
                                            {product.node.variants[0]
                                              .weightedShipping === null ? (
                                              <span class="product-price pl-1">
                                                ₹{totalValue(product.node.pricing.priceRange.start.net.amount)}
                                              </span>
                                            ) : (
                                              <span class="product-price">
                                                ₹{totalValue(product.node.pricing.priceRange.start.net.amount - product.node.variants[0].weightedShipping)}
                                              </span>
                                            )}
                                          </div>
                                        ) : (
                                          <div class="price-box">
                                            <span
                                              style={{
                                                color: "black",
                                                fontSize: "1.8rem",
                                                paddingRight:5,
                                        paddingBottom:5,
                                        display:"inline-block"
                                              }}
                                            >
                                              From{" "}
                                            </span>
                                            {product.node.variants.reduce(
                                              (total, item) =>
                                                total > item.originalPrice
                                                  ? (total =
                                                    item.weightedShipping === null
                                                      ? item.originalPrice
                                                      : item.originalPrice -
                                                      item.weightedShipping)
                                                  : (total = total),
                                              Infinity
                                            ) ===
                                              totalValue(
                                                product.node.variants.reduce(
                                                  (totall, item) =>
                                                    totall > item.originalPrice
                                                      ? (totall =
                                                        item.weightedShipping !== null
                                                          ? product.node.pricing
                                                            .priceRange.start.net
                                                            .amount -
                                                          item.weightedShipping
                                                          : product.node.pricing
                                                            .priceRange.start.net
                                                            .amount)
                                                      : (totall = totall),
                                                  Infinity
                                                )
                                              ) ? (
                                              <span class="product-price">
                                                {" "}
                                                ₹
                                                {totalValue(
                                                  product.node.variants.reduce(
                                                    (totall, item) =>
                                                      totall > item.originalPrice
                                                        ? (totall =
                                                          item.weightedShipping !==
                                                            null
                                                            ? product.node.pricing
                                                              .priceRange.start
                                                              .net.amount -
                                                            item.weightedShipping
                                                            : product.node.pricing
                                                              .priceRange.start
                                                              .net.amount)
                                                        : (totall = totall),
                                                    Infinity
                                                  )
                                                )}
                                              </span>
                                            ) : (
                                              <>
                                                {" "}
                                                <span class="product-price">
                                                  {" "}
                                                  ₹
                                                  {totalValue(
                                                    product.node.variants.reduce(
                                                      (totall, item) =>
                                                        totall > item.originalPrice
                                                          ? (totall =
                                                            item.weightedShipping !==
                                                              null
                                                              ? product.node.pricing
                                                                .priceRange.start
                                                                .net.amount -
                                                              item.weightedShipping
                                                              : product.node.pricing
                                                                .priceRange.start
                                                                .net.amount)
                                                          : (totall = totall),
                                                      Infinity
                                                    )
                                                  )}
                                                </span>
                                                <strike class="product-pricee">
                                                  {" "}
                                                  ₹
                                                  {totalValue(product.node.variants.reduce((total, item) => total > item.originalPrice ? (total = item.weightedShipping === null ? item.originalPrice : item.originalPrice - item.weightedShipping) : (total = total), Infinity))}
                                                </strike>
                                              </>
                                            )}
                                          </div>
                                        )}
                                        {/* <!-- End .price-box --> */}
                                      </div>
                                      {/* <!-- End .product-details --> */}
                                    </div>
                                  </div>
                                )
                              )
                              : null}
                          </div>
                        </div>
                        : null}
                    </>
                  ))}
                </>
              }
            </>
          ))}

        <div class="feature-boxes-container bg-white">
          <div style={{ maxWidth: "95%" }} class="container">
            <div
              style={{ justifyContent: "space-around" }}
              class="row featureBox"
            >
              {/* <!-- End .col-md-4 --> */}

              <div class="col-md-3">
                <div
                  class="feature-box feature-box-simple text-center mb-2 "
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay="400"
                >
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    class="feature-box-icon"
                  >
                    <img
                      src="assets/images/hospital.png"
                      style={{ color: "#f4631b", height: 70, width: 70 }}
                      class="icon-paper-plane"
                    />
                  </div>

                  <div class="feature-box-content p-0">
                    <h3 class="font1 ls-n-10">50+</h3>
                    <h5 style={{ fontWeight: 900 }} class="font1 m-b-3">
                      New Hospital Setup
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          New Dental Clinic Setup
                        </p>
                      </div>

                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          New MultiSpeciality Hospital Setup
                        </p>
                      </div>

                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          New Aesthetic and Cosmetic Setup
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End .feature-box-content --> */}
                </div>
                {/* <!-- End .feature-box --> */}
              </div>
              {/* <!-- End .col-md-3 --> */}

              <div class="col-md-3">
                <div
                  class="feature-box feature-box-simple text-center mb-2 "
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay="400"
                >
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    class="feature-box-icon"
                  >
                    <img
                      src="assets/images/delivery.png"
                      style={{ color: "#f4631b", width: 70, height: 70 }}
                    ></img>
                  </div>

                  <div class="feature-box-content p-0">
                    <h3 class="font1 ls-n-10">35K +</h3>
                    <h5 style={{ fontWeight: 900 }} class="font1 m-b-3">
                      Product Sold In 2022
                    </h5>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          10000+ Cities
                        </p>
                      </div>

                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          Deliverable All over India
                        </p>
                      </div>

                      <div style={{ display: "flex" }}>
                        <AiFillStar />
                        <p
                          style={{
                            marginTop: -5,
                            paddingLeft: 5,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                          }}
                        >
                          Simple Ordering Process
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End .feature-box-content --> */}
                </div>
                {/* <!-- End .feature-box --> */}
              </div>
              {/* <!-- End .col-md-3 --> */}

              <div class="col-md-3">
                <div
                  class="feature-box feature-box-simple text-center mb-2 "
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay="600"
                >
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    class="feature-box-icon"
                  >
                    <img
                      src="assets/images/suport.png"
                      style={{ color: "#f4631b", height: 70, width: 70 }}
                    ></img>
                  </div>
                  <div class="feature-box-content p-0">
                    <h3 class="font1 ls-n-10">Online Support</h3>
                    <h5 style={{ fontWeight: 900 }} class="font1 m-b-3">
                      Need Assistence?
                    </h5>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <AiFillStar />
                          <p
                            style={{
                              marginTop: -5,
                              paddingLeft: 5,
                              fontWeight: 500,
                              fontSize: "1.3rem",
                            }}
                          >
                            +91 8553666877
                          </p>
                        </div>

                        <div style={{ display: "flex" }}>
                          <AiFillStar />
                          <p
                            style={{
                              marginTop: -5,
                              paddingLeft: 5,
                              fontWeight: 500,
                              fontSize: "1.3rem",
                            }}
                          >
                            +91 8553666977
                          </p>
                        </div>

                        <div style={{ display: "flex" }}>
                          <AiFillStar />
                          <p
                            style={{
                              marginTop: -5,
                              paddingLeft: 5,
                              fontWeight: 500,
                              fontSize: "1.3rem",
                            }}
                          >
                            support@hegmaKart.com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End .feature-box-content --> */}
                </div>
                {/* <!-- End .feature-box --> */}
              </div>
              {/* <!-- End .col-md-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
        </div>

        <div class="testimonials-section">
          <div style={{ maxWidth: "95%" }} class="container">
            <h2 class="subtitle text-center mb-5">HAPPY CLIENTS</h2>

            <Swiper
              breakpoints={{
                70: {
                  //   width: 376,
                  slidesPerView: 1,
                },
                470: {
                  //   width: 376,
                  slidesPerView: 2,
                },
                750: {
                  //   width: 368,
                  slidesPerView: 3,
                },
                1200: {
                  //   width: 368,
                  slidesPerView: 4,
                },
              }}
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              slidesPerView={4}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {testimonialApi.map((testimonial) => (
                <SwiperSlide className="slide testimonial">
                  {/* <div >
    <img style={{ height: 200, width: "100%" }} className='testimonialImage' src='assets/images/std1.jpg'></img>
</div> */}

                  <div
                    className="arrowBoxx"
                    style={{
                      padding: 30,
                      fontSize: "1.4rem",
                      fontWeight: 400,
                      background: "orangered",
                      borderRadius: 10,
                      height: 250,
                    }}
                  >
                    <i class="fas fa-quote-left"></i>
                    <span
                      style={{
                        color: "white",
                        fontStyle: "italic",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {testimonial.review}{" "}
                      <div style={{ marginTop: -60 }}>
                        <i class="fas fa-quote-right"></i>
                      </div>
                    </span>
                  </div>

                  <div
                    style={{
                      marginTop: 40,
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.08)",
                        borderRadius: 5,
                      }}
                    >
                      <img
                        src={testimonial.image}
                        style={{ width: 70, height: 70, borderRadius: 5 }}
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 15,
                        paddingTop: 10,
                      }}
                    >
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        {testimonial.name}
                      </span>
                      {/* <span style={{color:"grey",paddingTop:5}}> sarath Dental Clinic</span> */}
                    </div>
                    <div className="testimonialArrow"></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default HomeScreen;
